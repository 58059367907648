import create, { SetState, GetState } from 'zustand';
import axios, {
  AxiosProgressEvent,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import {
  PostMediaResult,
  Media,
  Post,
  PostType,
  GetPostResult,
} from 'common/interfaces/api';
import { clone, NON_UPLOADING_PROGRESS } from 'common/utils';

export enum ActionType {
  CREATE = 'create',
  UPDATE = 'update',
  SHOW = 'show',
}

export const uploadingStatusText = (status: string): string => {
  switch (status) {
    case UploadStatus.DONE: {
      return '投稿が完了しました';
    }
    case UploadStatus.FILE_PROCESSING: {
      return 'ファイル処理中のため、少しお待ちください';
    }
    case UploadStatus.UPLOADING: {
      return 'アップロード中';
    }
    case UploadStatus.POST_FAILED: {
      return '問題が発生しました';
    }
    case UploadStatus.UPLOAD_FAILED: {
      return 'アップロードが失敗しました';
    }
    case UploadStatus.WAITING: {
      return 'アップロード中';
    }
    case UploadStatus.DISABLED: {
      return '問題が発生しました';
    }
  }
};

export enum UploadStatus {
  DONE = 'done',
  UPLOADING = 'uploading',
  UPLOAD_FAILED = 'upload_failed',
  POST_FAILED = 'post_failed',
  FILE_PROCESSING = 'file_processing',
  WAITING = 'waiting',
  DISABLED = 'disabled',
}

export type PostActionType = {
  actionType: ActionType;
  params: {
    text: string;
    media?: Media[];
    type: PostType;
    published_at: string;
  };
  id: number;
  postId?: number;
  file: File;
  callback: (post?: Post) => void;
  status?: string;
};

type FilesListType = { [id: string]: File };
type PostActionStore = {
  globalFiles: FilesListType;
  postActions: PostActionType[];
  currentPostAction: PostActionType;
  mediaUploadingProgress: number;
  mediaUploadingStatus: string;
  axiosCancelToken: CancelTokenSource;
  resetPostActions: () => void;
  setPostActions: (postActions: PostActionType[]) => void;
  addPostAction: (postAction: PostActionType) => void;
  cancelPostAction: (postAction: PostActionType) => void;
  updatePostActionStatus: ({
    currentAction,
    newStatus,
    isUpdateGlobalStatus,
    isResetCurrentAction,
  }: {
    currentAction: PostActionType;
    newStatus: UploadStatus;
    isUpdateGlobalStatus: boolean;
    isResetCurrentAction: boolean;
  }) => void;
  deletePostAction: (postAction: PostActionType) => void;
  reloadPostAction: (postAction: PostActionType) => void;
  uploadUserMediaGlobal: (postAction: PostActionType) => void;
  setCurrentPostAction: (action: PostActionType) => void;
  setMediaUploadingProgress: (progress: number) => void;
  setMediaUploadingStatus: (status: string) => void;
};

const useStore = create<PostActionStore>(
  (set: SetState<PostActionStore>, get: GetState<PostActionStore>) => ({
    // states
    globalFiles: {} as FilesListType,
    currentPostAction: null,
    mediaUploadingProgress: NON_UPLOADING_PROGRESS,
    mediaUploadingStatus: '',
    axiosCancelToken: axios.CancelToken.source(),
    postActions: [],
    resetPostActions: () => {
      const { axiosCancelToken } = get();
      axiosCancelToken.cancel();
      set({
        postActions: [],
        globalFiles: {},
        mediaUploadingProgress: NON_UPLOADING_PROGRESS,
        mediaUploadingStatus: '',
        axiosCancelToken: axios.CancelToken.source(),
      });
    },
    cancelPostAction: (postAction: PostActionType) => {
      const { deletePostAction } = get();
      deletePostAction(postAction);
      set({
        currentPostAction: null,
        mediaUploadingProgress: NON_UPLOADING_PROGRESS,
        mediaUploadingStatus: '',
        axiosCancelToken: axios.CancelToken.source(),
      });
    },
    setPostActions: (postActions) =>
      set({ postActions: clone(postActions) as PostActionType[] }),
    addPostAction: (newAction: PostActionType) => {
      const { postActions, globalFiles } = get();
      const newActions = clone(postActions) as PostActionType[];
      const index = newActions.findIndex(
        (action) =>
          action.postId &&
          action.postId !== 0 &&
          action.postId === newAction.postId
      );
      if (index >= 0) {
        newActions[index] = newAction;
      } else {
        newActions.push(newAction);
      }
      globalFiles[newAction.id.toString()] = newAction.file;
      set({ postActions: newActions, globalFiles });
    },
    updatePostActionStatus: ({
      currentAction,
      newStatus,
      isUpdateGlobalStatus,
      isResetCurrentAction,
    }: {
      currentAction: PostActionType;
      newStatus: UploadStatus;
      isUpdateGlobalStatus: boolean;
      isResetCurrentAction: boolean;
    }) => {
      const { postActions } = get();
      const newActions = clone(postActions) as PostActionType[];
      const index = newActions.findIndex(
        (action) => action.id === currentAction.id
      );
      if (index >= 0) {
        const newAction = clone(currentAction) as PostActionType;
        newAction.status = newStatus;
        newActions[index] = newAction;
      }
      set({
        postActions: newActions,
        ...(isUpdateGlobalStatus && { mediaUploadingStatus: newStatus }),
        ...(isResetCurrentAction && { currentPostAction: null }),
      });
    },
    deletePostAction: (currentAction: PostActionType) => {
      const { postActions, globalFiles } = get();
      const newActions = clone(postActions) as PostActionType[];
      const index = newActions.findIndex(
        (action) => action.id === currentAction.id
      );
      if (index < 0) return;
      newActions.splice(index, 1);
      delete globalFiles[currentAction.id.toString()];
      set({
        postActions: newActions,
        globalFiles,
      });
    },
    reloadPostAction: (currentAction: PostActionType) => {
      const { postActions, globalFiles } = get();
      const newActions = clone(postActions) as PostActionType[];
      const index = newActions.findIndex(
        (action) => action.id === currentAction.id
      );

      if (index < 0) return;
      newActions.splice(index, 1);
      const newAction = clone(currentAction) as PostActionType;
      newAction.id = new Date().getTime();
      globalFiles[newAction.id.toString()] =
        globalFiles[currentAction.id.toString()];
      delete globalFiles[currentAction.id.toString()];
      newAction.status = UploadStatus.WAITING;
      newActions.push(newAction);
      set({
        postActions: newActions,
        globalFiles,
      });
    },
    // actions
    uploadUserMediaGlobal: async (postAction: PostActionType) => {
      const { globalFiles } = get();
      const formData = new FormData();
      formData.append('file', globalFiles[postAction.id.toString()]);
      const { updatePostActionStatus, axiosCancelToken } = get();
      updatePostActionStatus({
        currentAction: postAction,
        newStatus: UploadStatus.UPLOADING,
        isUpdateGlobalStatus: true,
        isResetCurrentAction: false,
      });
      set({ currentPostAction: clone(postAction) as PostActionType });
      await axios
        .post(`/posts/media`, formData, {
          cancelToken: axiosCancelToken.token,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (!progressEvent) return;
            const newProgress = Math.round(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              (progressEvent.loaded * 100) / progressEvent.total
            );
            set({
              mediaUploadingProgress: newProgress,
            });
          },
        })
        .then((response: AxiosResponse<PostMediaResult>) => {
          const mediaFile = response.data.data;
          if (postAction.params.media[0]?.data?.title) {
            mediaFile.data.title = postAction.params.media[0].data.title;
          }
          if (postAction.params.media[0]?.data?.thumbnail_url) {
            mediaFile.data.thumbnail_url =
              postAction.params.media[0].data.thumbnail_url;
          }
          postAction.params.media = [mediaFile];
          if (postAction.actionType === ActionType.CREATE) {
            void axios
              .post(`/posts/`, postAction.params)
              .then((response: AxiosResponse<GetPostResult>) => {
                const { updatePostActionStatus } = get();
                postAction.postId = response.data.data.id;
                updatePostActionStatus({
                  currentAction: postAction,
                  newStatus: UploadStatus.FILE_PROCESSING,
                  isUpdateGlobalStatus: true,
                  isResetCurrentAction: true,
                });
                postAction.callback && postAction.callback();
              })
              .catch(() => {
                const { updatePostActionStatus } = get();
                updatePostActionStatus({
                  currentAction: postAction,
                  newStatus: UploadStatus.POST_FAILED,
                  isUpdateGlobalStatus: true,
                  isResetCurrentAction: true,
                });
              });
          } else if (postAction.actionType === ActionType.UPDATE) {
            void axios
              .put(`/posts/${postAction.postId}`, postAction.params)
              .then((response: AxiosResponse<GetPostResult>) => {
                const { updatePostActionStatus } = get();
                updatePostActionStatus({
                  currentAction: postAction,
                  newStatus: UploadStatus.FILE_PROCESSING,
                  isUpdateGlobalStatus: true,
                  isResetCurrentAction: true,
                });
                postAction.callback && postAction.callback(response.data.data);
              })
              .catch(() => {
                const { updatePostActionStatus } = get();
                updatePostActionStatus({
                  currentAction: postAction,
                  newStatus: UploadStatus.POST_FAILED,
                  isUpdateGlobalStatus: true,
                  isResetCurrentAction: true,
                });
              });
          }
        })
        .catch(() => {
          const { updatePostActionStatus } = get();
          updatePostActionStatus({
            currentAction: postAction,
            newStatus: UploadStatus.UPLOAD_FAILED,
            isUpdateGlobalStatus: true,
            isResetCurrentAction: true,
          });
        });
    },
    setCurrentPostAction: (action: PostActionType) =>
      set({ currentPostAction: action }),
    setMediaUploadingProgress: (progress: number) =>
      set({ mediaUploadingProgress: progress }),
    setMediaUploadingStatus: (status: string) =>
      set({ mediaUploadingStatus: status }),
  })
);

export default useStore;
