import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import firebaseConfig from 'config/firebase';
import { logEvent } from 'firebase/analytics';

const firebaseApp =
  getApps().length > 0 ? getApps()[0] : initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
firebaseAuth.useDeviceLanguage();

export const logEventAnalytics = (
  eventName: string,
  eventParams?: { [key: string]: any }
) => {
  if (process.env.NODE_ENV == 'development') return;
  const firebaseAnalytics = getAnalytics(firebaseApp);
  logEvent(firebaseAnalytics, eventName, eventParams);
};
export { firebaseAuth };
