import MembershipPRTextSection from 'components/molecules/MembershipPRTextSection';
import styles from './MainRightSideBar.module.scss';
import { useQuery } from 'react-query';
import { getTrendingTopicKeywords } from 'service/post_topic';
import { TrendingTopicPeriod } from 'common/interfaces/api';
import useGlobalStore from 'store/global';
import SideBarHotKeywordsList from 'components/molecules/SideBarHotKeywordsList';
import SideBarRecommendCreatorsList from 'components/molecules/SideBarRecommendCreatorsList';

const MainRightSideBar = () => {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const { data: hotKeywordsData, isFetched: isFetchedHotKeywords } = useQuery(
    'getHotKeywords',
    async () => {
      if (!isAuthInit) return Promise.reject();
      return getTrendingTopicKeywords(TrendingTopicPeriod.MidTerm, 1);
    },
    {
      enabled: isAuthInit,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  return (
    <div className={styles.mainRightSidebar}>
      <MembershipPRTextSection />
      <SideBarHotKeywordsList
        keywords={hotKeywordsData?.data || []}
        isFetched={isFetchedHotKeywords}
      />
      <SideBarRecommendCreatorsList />
    </div>
  );
};

export default MainRightSideBar;
