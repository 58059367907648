import LabelButton from 'components/common/LabelButton';
import UserInfo from 'components/common/UserInfo';
import Icon from 'components/common/Icon';
import RoundIcon from 'components/common/RoundIcon';
import { NotificationType, User } from 'common/interfaces/api';
import styles from './ProfileViewNotification.module.scss';
import { notificationEmotion } from 'common/utils';
import Link from 'next/link';

export const ProfileViewAvatar = ({
  users,
  notificationType,
}: {
  users: User[];
  notificationType: NotificationType;
}) => {
  if (!users || users.length === 0) return <></>;
  if (users.length === 1)
    return (
      <RoundIcon
        user={users[0]}
        enableClick
        width={56}
        height={56}
        hasRing
        emotion={notificationEmotion(notificationType)}
      />
    );
  if (users.length >= 2) {
    return (
      <div className={styles.customAvatar}>
        <div className={styles.avatar1}>
          <RoundIcon
            user={users[0]}
            enableClick
            width={30}
            height={30}
            hasRing
          />
        </div>
        <div className={styles.avatar2}>
          <RoundIcon
            user={users[1]}
            enableClick
            width={30}
            height={30}
            hasRing
          />
        </div>
        <div className={styles.emotion}>
          <Icon name="emotion-profile-view" width={24} height={24} />
        </div>
      </div>
    );
  }
};

export const ProfileViewUsers = ({ users }: { users: User[] }) => {
  if (!users || users.length === 0) return <></>;
  return (
    <div className={styles.profileViewUsers}>
      <div className={styles.usersList}>
        {users.slice(0, 3).map((user, index) => (
          <div className={styles.user} key={`user-view-${index}`}>
            <UserInfo
              user={user}
              hasFollowBtn={false}
              avatarSize={30}
              badgeSize={24}
            />
          </div>
        ))}
        <div className={styles.overlay}></div>
      </div>
      <Link href="/account/profile_viewers">
        <a>
          <LabelButton
            text="もっとみる"
            type="green"
            className={styles.seemore}
          />
        </a>
      </Link>
    </div>
  );
};
