import axios from 'axios';
import { GetPostResult, Media, PrecheckLog } from 'common/interfaces/api';

export enum PolicyLevel {
  MediumSingle = 'medium_single',
  MediumCombination = 'medium_combination',
  High = 'high',
  SuperHigh = 'super_high',
}

export interface PolicyError {
  name: string;
  description: string;
  url: string;
  level: PolicyLevel;
}

export interface PolicyImage {
  url: string;
  precheck_logs: PrecheckLog[];
}

export interface BlackListUrl {
  domain_category_name: string;
  url: string;
}

export const checkPolicyPostText = async (postText: string) => {
  return (await axios.post('/posts/precheck', { text: postText })).data as {
    data?: PolicyError[];
  };
};

export const checkPolicyPostTextV2 = async (postText: string) => {
  return (await axios.post('/posts/precheck/v2', { text: postText })).data as {
    data: {
      words: PolicyError[];
      urls: BlackListUrl[];
    };
  };
};

export const checkPolicyCommentText = async (postText: string) => {
  return (
    await axios.post('/posts/precheck/v2', {
      text: postText,
      check_place: 'comment',
    })
  ).data as {
    data: {
      words: PolicyError[];
      urls: BlackListUrl[];
    };
  };
};

export const getPostDetail = async (
  postId: string | number,
  isPreload?: boolean
) => {
  const preloadParam = isPreload ? `?preload=true` : '';
  return (await axios.get(`/posts/${postId}${preloadParam}`))
    .data as GetPostResult;
};

export const deletePost = async (postId: number) => {
  return await axios.delete(`/posts/${postId}`);
};

export const checkPolicyImage = async (media: Media[]) => {
  return (
    await axios.post(`/posts/precheck_media`, {
      media: media,
    })
  )?.data as { data: PolicyImage[] };
};

export const closeUserPopup = async (popupId: number) => {
  return await axios.delete(`/profiles/system/popups/${popupId}`);
};
