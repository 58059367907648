import NotificationSection from 'components/NotificationSection';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import cn from 'classnames';
import Icon from 'components/common/Icon';
import ConfirmDialog from 'components/common/ConfirmDialog';
import Button from 'components/common/Button';
import { track } from '@amplitude/analytics-browser';
import styles from '../GlobalHeader.module.scss';
import { useRef, useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useMediaQuery, useOutsideClick } from 'common/utils';
import { useRouter } from 'next/router';

const NotificationIcon = () => {
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const hasNewNotification = useGlobalStore(
    (state) => state.hasNewNotification
  );
  const router = useRouter();
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const [showNotification, setShowNotification] = useState(false);
  const [notificationAlert, setNotificationAlert] = useState('');
  const refetchNotifications = useStore((state) => state.refetchNotifications);
  const setHasNewNotification = useGlobalStore(
    (state) => state.setHasNewNotification
  );

  const notificationRef = useRef();

  useOutsideClick(notificationRef, () => {
    if (showNotification) {
      setShowNotification(false);
      void refetchCheckNotification();
    }
  });

  const { refetch: refetchCheckNotification } = useQuery(
    'checkNewNotification',
    async () =>
      (await axios.get('/notifications/check')).data as { data: boolean },
    {
      onSuccess: (responseData) => {
        setHasNewNotification(responseData.data);
      },
      enabled: isAuthInit && !!currentAuth,
      refetchInterval: 60000 * 10,
      refetchOnWindowFocus: false,
    }
  );

  if (isMobileScreen)
    return (
      <div className={styles.notificationWrapper}>
        <div
          className={cn(styles.linkWrapper, {
            [styles.active]: router.pathname === `/notification`,
          })}
          onClick={() => {
            void router.push('/notification');
            refetchNotifications();
          }}
        >
          <Icon
            name="bell"
            hasOn
            isOn={router.pathname === `/notification`}
            width={30}
            height={30}
          />
          {hasNewNotification && (
            <div className={styles.hasNewNotification}></div>
          )}
        </div>
      </div>
    );
  return (
    <div className={styles.notificationWrapper} ref={notificationRef}>
      <div
        className={cn(styles.linkWrapper, {
          [styles.active]: showNotification,
        })}
        onClick={() => {
          track('Click Notification Button');
          setShowNotification(!showNotification);
          refetchNotifications();
        }}
      >
        <Icon
          name="bell"
          hasOn
          isOn={showNotification}
          width={30}
          height={30}
        />
        {hasNewNotification && (
          <div className={styles.hasNewNotification}></div>
        )}
      </div>
      <div
        className={styles.popperWrapperNotification}
        style={{ display: showNotification ? 'block' : 'none' }}
      >
        {showNotification && (
          <NotificationSection
            setNotificationAlert={setNotificationAlert}
            onClose={() => {
              setShowNotification(false);
              setTimeout(() => {
                void refetchCheckNotification();
              }, 500);
            }}
          />
        )}
      </div>
      {!!notificationAlert && (
        <ConfirmDialog
          open={!!notificationAlert}
          actionCloseFnc={() => setNotificationAlert('')}
          title=""
          withTextBox
          textBox={
            <div className={styles.schedulePostAlert}>
              <span>予約投稿通知</span>
              <p>
                この投稿はまだ公開されていません。
                <br />
                {notificationAlert}
                <br />
                公開予定です。
              </p>
              <br />
              <Button
                text="OK"
                rounded
                onClick={() => setNotificationAlert('')}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default NotificationIcon;
