import axios from 'axios';
import {
  Language,
  MediaType,
  NotificationType,
  Post,
  PostActionEmotion,
  PostType,
  PrecheckLog,
  TippedInfoDetail,
  User,
} from 'common/interfaces/api';
import { SITE_BASE_URL } from 'config/env';
import { RefObject, useEffect, useState } from 'react';
import { DEFAULT_TIME_HMS, VALID_PASSWORD_REGEX } from './index.constant';
export * from './index.constant';
import { useMediaQuery as useMediaQueryLib } from '@material-ui/core';
import parser from 'ua-parser-js';
import dayjs from 'lib/dayjs';
// import Pica from 'pica';
import { getPostDetail } from 'service/post';
import { checkIsPaidPost } from './post';

export const isProduction = () => {
  return process.env.CDK_DEPLOYMENT_STAGE === 'prod';
};

export const defaultSymbolId = isProduction() ? 151 : 280;
export const isNumber = (val: any) => {
  return !isNaN(val) && typeof val === 'number';
};
export const PRIME_BENEFIT_SPLITTER = '**.**';
export function timeSince(date: Date, lang = Language.En): string {
  let timeUnits = ['d', 'h', 'm', 'now'];
  if (lang === Language.Ja) {
    timeUnits = ['日前', '時間前', '分前', 'たった今'];
  }
  const now = new Date();
  const seconds = (now.getTime() - date.getTime() - 10) / 1000;

  // days
  let interval = seconds / 86400;
  if (interval > 7) {
    const yearStr =
      date.getFullYear() !== now.getFullYear() ? `${date.getFullYear()}年` : '';
    const monthNum = date.getMonth() + 1;
    const dateNum = date.getDate();
    return `${yearStr}${pad(monthNum.toString())}月${pad(
      dateNum.toString()
    )}日`;
  } else if (interval > 1) {
    return Math.floor(interval).toString() + timeUnits[0];
  }
  // hours
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval).toString() + timeUnits[1];
  }
  // minutes
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval).toString() + timeUnits[2];
  }
  // just now
  return timeUnits[3];
}

// check error of file
export const fileError = (
  file: File,
  user: User,
  fileType: 'video' | 'image' | 'audio'
): string => {
  if (!user) {
    return 'ログインしてください。';
  }
  const userType = user.is_creator ? 'creator' : 'user';
  if (file.size > user.setting[`${userType}_max_${fileType}_file_size`]) {
    return `アップロードは${bytesToString(
      user.setting[`${userType}_max_${fileType}_file_size`]
    )}まで可能です。`;
  }
  return '';
};

export const fileErrorCourse = (
  file: File,
  user: User,
  fileType: 'video' | 'image'
): string => {
  if (!user) {
    return 'ログインしてください。';
  }
  const userType = user.is_creator ? 'creator' : 'user';
  if (fileType === 'video') {
    if (file.size > user.setting.course_max_video_file_size) {
      return `アップロードは${bytesToString(
        user.setting.course_max_video_file_size
      )}まで可能です。`;
    }
    return '';
  }
  if (file.size > user.setting[`${userType}_max_${fileType}_file_size`]) {
    return `アップロードは${bytesToString(
      user.setting[`${userType}_max_${fileType}_file_size`]
    )}まで可能です。`;
  }
  return '';
};

export const bytesToString = (bytes: number): string => {
  if (bytes / Math.pow(1024, 3) >= 1) {
    return `${fixedPositiveNumber(bytes / Math.pow(1024, 3), 1)}GB`;
  } else {
    return `${fixedPositiveNumber(bytes / Math.pow(1024, 2), 1)}MB`;
  }
};

export function timeUntil(date: Date, lang = Language.En): string {
  let timeUnits = ['d'];
  if (lang === Language.Ja) {
    timeUnits = ['日'];
  }
  const now = new Date();
  const seconds = (date.getTime() - now.getTime()) / 1000;

  const interval = seconds > 0 ? seconds / 86400 : 0;
  // days left (include the last day itself so we need to plus 1)
  return Math.floor(interval + 1).toString() + timeUnits[0];
}

export function timeUntilMinutes(date: Date, lang = Language.En): string {
  let timeUnits = ['d', 'h', 'm'];
  if (lang === Language.Ja) {
    timeUnits = ['日', '時間', '分'];
  }
  const now = new Date();
  const seconds = (date.getTime() - now.getTime()) / 1000;

  let interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval).toString() + timeUnits[0];
  }
  // hours
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval).toString() + timeUnits[1];
  }
  // minutes
  interval = seconds / 60;
  return Math.floor(interval + 1).toString() + timeUnits[2];
}

export const secondsToNow = (date: Date) => {
  return new Date().getTime() / 1000 - date.getTime() / 1000;
};

export const secondsFromNow = (date: Date) => {
  return date.getTime() / 1000 - new Date().getTime() / 1000;
};

export function fullTimeStr(date: Date): string {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const hours = pad(date.getHours().toString());
  const minutes = pad(date.getMinutes().toString());
  return `${hours}:${minutes} ${year}年${month}月${day}日`;
}

export function useMediaQuery(query: string): boolean {
  return useMediaQueryLib(query);
  // const [matches, setMatches] = useState(false);
  // useEffect(() => {
  //   const media = window.matchMedia(query);
  //   if (media.matches !== matches) {
  //     setMatches(media.matches);
  //   }
  //   const listener = () => {
  //     setMatches(media.matches);
  //   };
  //   window.addEventListener('change', listener);
  //   return () => window.removeEventListener('change', listener);
  // }, [matches, query]);
  // return matches;
}

export function pad(str: string): string {
  return ('0' + str).slice(-2);
}

export const fixedPositiveNumber = (x: number, d: number): string => {
  if (!d) return x.toString();
  x = Math.floor(x * Math.pow(10, d)) / Math.pow(10, d);
  return x.toString().replace(/\.0+$/, '');
};

// d => the number of digit after .
export const numberToString = (num: number, d?: number): string => {
  d = d ? d : 2;
  if (typeof num !== 'number') return '--';
  if (num <= 999) return num.toString();

  if (num > 999 && num < Math.pow(10, 4)) {
    return fixedPositiveNumber(num / 1000, d) + '千';
  } else if (num >= Math.pow(10, 4) && num < Math.pow(10, 7)) {
    return fixedPositiveNumber(num / Math.pow(10, 4), d) + '万';
  } else if (num >= Math.pow(10, 7) && num < Math.pow(10, 8)) {
    return fixedPositiveNumber(num / Math.pow(10, 7), d) + '千万';
  } else {
    return fixedPositiveNumber(num / Math.pow(10, 8), d) + '億';
  }
};

export const coinNumberToString = (num: number): string => {
  const d = 1;
  if (!num) return '0';
  if (num <= 9999) return num.toString();

  if (num >= Math.pow(10, 4) && num < Math.pow(10, 7)) {
    return fixedPositiveNumber(num / Math.pow(10, 4), d) + '万';
  } else if (num >= Math.pow(10, 7) && num < Math.pow(10, 8)) {
    return fixedPositiveNumber(num / Math.pow(10, 7), d) + '千万';
  } else {
    return fixedPositiveNumber(num / Math.pow(10, 8), d) + '億';
  }
};

export function secondsToHms(seconds: number): string {
  if (seconds < 0.5) return DEFAULT_TIME_HMS;
  let hours = Math.floor(seconds / (60 * 60));

  const divisorForMinutes = seconds % (60 * 60);
  let minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  let ss = Math.ceil(divisorForSeconds);
  if (ss === 60) {
    minutes += 1;
    ss = 0;
  }
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  if (hours) {
    return `${pad(String(hours))}:${pad(String(minutes))}:${pad(String(ss))}`;
  }
  return `${pad(String(minutes))}:${pad(String(ss))}`;
}

export function secondsToHmsJap(seconds: number): string {
  let hours = Math.floor(seconds / (60 * 60));

  const divisorForMinutes = seconds % (60 * 60);
  let minutes = Math.floor(divisorForMinutes / 60);
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  if (hours > 0) {
    return `${hours}時間${minutes > 0 ? `${minutes}分` : ''}`;
  }
  return `${minutes}分`;
}

export const handleRegexFactory = (): RegExp => /@[\w]+/g;
export const hashTagRegexFactory = (): RegExp =>
  /(#|＃)([0-9A-Za-z一-龯ぁ-ゟ゠-ヿ_\\-]+)(?!;)/gi;
export const hashTagRegexFactoryWithSpace = (): RegExp =>
  /(?:^|\s)(#|＃)([0-9A-Za-z一-龯ぁ-ゟ゠-ヿ_\\-]+)(?!;)/gi;

export const linkRegexFactory = (): RegExp =>
  /(http(s)?:\/\/(.)?)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#;?&//=]*)/g;
export const usernameRegex = /^[a-zA-Z0-9_]{5,13}$/;
export const VALID_EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const postUrlRegex = /([a-zA-Z0-9_]{5,13})\/posts\/\w/;
export const validEmail = (email: string): boolean => {
  if (!email) return false;
  email = email.toLowerCase();
  return VALID_EMAIL_REGEX.test(email);
};

export const validPassword = (pw: string): boolean => {
  return pw && pw.length >= 8 && VALID_PASSWORD_REGEX.test(pw);
};

export const validPasswordConfirm = (pw: string, pwcf: string): boolean => {
  return pwcf && validPassword(pwcf) && pwcf === pw;
};

export const useWindowSize = (): {
  browserWidth: number;
  browserHeight: number;
} => {
  const [windowSize, setWindowSize] = useState({
    browserWidth: undefined,
    browserHeight: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        browserWidth: window.innerWidth,
        browserHeight: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

// Retry 2 times in case image load is error.
export const loadImageHandleError = (
  url: string,
  ref: RefObject<HTMLImageElement>,
  callback?: () => void
): void => {
  if (!ref.current) return;
  const retryCountData = ref.current.dataset.retry;
  const currentRetryCount = retryCountData ? Number(retryCountData) : 0;
  if (currentRetryCount >= 2) return;
  ref.current.dataset.retry = (currentRetryCount + 1).toString();
  if (currentRetryCount === 1) {
    callback && callback();
  }
  ref.current.src = url;
};

// deep clone method
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export const clone: (object: any) => any = require('rfdc')({ proto: true });
export const JSONbigNative = require('json-bigint')({ useNativeBigInt: true });
export const parseJSON = (data: string) => {
  if (!data) return null;
  try {
    return JSONbigNative.parse(data);
  } catch (e) {
    return JSON.parse(data);
  }
};

export const stringifyJSON = (data: any) => {
  if (!data) return '';
  try {
    return JSONbigNative.stringify(data);
  } catch (e) {
    return JSON.stringify(data);
  }
};

/* eslint-enable @typescript-eslint/no-var-requires */
/* eslint-enable @typescript-eslint/no-unsafe-call */
/* eslint-enable @typescript-eslint/no-unsafe-assignment */
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-member-access */

export const getPrivateFileUploadInfo = async ({
  contentType,
}: {
  contentType: string;
}): Promise<string> => {
  const res = await axios.post('/payments/upload-url', {
    content_type: contentType,
  });

  const data = (await res.data) as { data: string };
  return data?.data;
};

export const uploadS3 = async ({
  url,
  file,
}: {
  url: string;
  file: File;
}): Promise<void> => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  };
  await fetch(url, requestOptions);
};

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: (e: any) => void
): void => {
  useEffect(() => {
    const listener = (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      callback(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, callback]);
};

export const getPostFromUrl = async (link: string): Promise<Post> => {
  let sharedPost: Post = null;
  if (link.startsWith(SITE_BASE_URL) && postUrlRegex.test(link)) {
    const username = link.replace(`${SITE_BASE_URL}/`, '').split('/')[0];
    const postId = link.split('?')[0].split('/').pop();
    try {
      const postData = await getPostDetail(postId, true);
      if (
        postData.data &&
        username === postData.data.user_info.username &&
        new Date(postData.data.created_at) < new Date()
      )
        sharedPost = postData.data;
    } catch (error) {
      return sharedPost;
    }
  }
  return sharedPost;
};

export type DeviceInfo = {
  isAndroid: boolean;
  isIos: boolean;
  isDesktop: boolean;
  isBraveBrowser: boolean;
};

export const useDevice = (): DeviceInfo => {
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    setFirstLoad(false);
  }, []);

  const ssr = firstLoad || typeof navigator === 'undefined';
  if (ssr || !navigator.userAgent)
    return {
      isAndroid: false,
      isIos: false,
      isDesktop: true,
      isBraveBrowser: false,
    };

  /* eslint-disable @typescript-eslint/no-unsafe-call */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  const ua = parser(navigator.userAgent);
  const isAndroid = ua.os.name === 'Android';
  const isIos = ua.os.name === 'iOS';
  const isBrave =
    ua.browser.name?.includes('Brave') || ua.browser.name?.includes('brave');
  /* eslint-enable @typescript-eslint/no-unsafe-call */
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */

  return {
    isAndroid,
    isIos,
    isDesktop: !isAndroid && !isIos,
    isBraveBrowser: !!isBrave,
  };
};

export const increaseViewCount = (postId: number): void => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    void axios.post(`/posts/${postId}/views`, {}).catch(() => {});
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const japanTime = (date: Date): string => {
  if (date.getFullYear() < 2021) return '-';
  return dayjs(date).utcOffset(540).format('YYYY年MM月DD日');
};

export const truncateStr = (str: string, maxLength: number) => {
  if (!str) return str;
  const arr = Array.from(str);
  if (arr.length <= maxLength) return str;
  return `${arr.slice(0, maxLength).join('')} ...`;
};

export const registerReferralCode = ({
  code,
  successCallback,
  errorCallback,
}: {
  code: string;
  successCallback: () => void;
  errorCallback: () => void;
}): void => {
  try {
    void axios
      .post(`/users/register_referral_code`, { referral_code: code })
      .then(() => {
        successCallback();
      })
      .catch(() => {
        errorCallback();
      });
  } catch (error) {
    errorCallback();
  }
};

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// export const resizeImage = (url: string, quality: number) => {
//   const pica = Pica();
//   return new Promise((resolve) => {
//     const img = new Image();

//     img.onload = () => {
//       const outputCanvas = document.createElement('canvas');
//       outputCanvas.height = img.naturalHeight;
//       outputCanvas.width = img.naturalWidth;
//       resolve(
//         pica
//           .resize(img, outputCanvas, {
//             unsharpAmount: 80,
//             unsharpRadius: 0.6,
//             unsharpThreshold: 2,
//           })
//           .then((result) => pica.toBlob(result, 'image/jpeg', quality))
//       );
//     };

//     img.src = url;
//   });
// };
/* eslint-enable @typescript-eslint/no-unsafe-call */
/* eslint-enable @typescript-eslint/no-unsafe-assignment */
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-member-access */

export const subPlusFromData = (user: User) => {
  switch (user.subscription_platform) {
    case 'credit':
      return { price: user.price, icon: 'macbook-fill', currency: '円' };
    case 'web_coin':
      return {
        price: user.price,
        icon: 'macbook-fill',
        currency: 'PostPrime Coin',
      };
    case 'android_iap':
      return {
        price: user.price,
        icon: 'android-fill',
        currency: '円',
      };
    case 'android_coin':
      return {
        price: user.price,
        icon: 'android-fill',
        currency: 'PostPrime Coin',
      };
    case 'ios_iap':
      return { price: user.price, icon: 'apple-fill', currency: '円' };
    default:
      return { price: 0, icon: 'macbook-fill', currency: '円' };
  }
};

export const subFromData = (user: User) => {
  switch (user.subscription_platform) {
    case 'credit':
      return { price: user.price, icon: 'macbook-fill', currency: '円' };
    case 'web_coin':
      return {
        price: user.price,
        icon: 'macbook-fill',
        currency: 'PostPrime Coin',
      };
    case 'android_iap':
      return {
        price: user.android_price,
        icon: 'android-fill',
        currency: '円',
      };
    case 'android_coin':
      return {
        price: user.android_price,
        icon: 'android-fill',
        currency: 'PostPrime Coin',
      };
    case 'ios_iap':
      return { price: user.ios_price, icon: 'apple-fill', currency: '円' };
    default:
      return { price: 0, icon: 'macbook-fill', currency: '円' };
  }
};

export const tippingPackageData = (tip: TippedInfoDetail) => {
  switch (tip.type) {
    case 'credit':
      return { amount: tip.tipping_package.web_amount, icon: 'macbook-fill' };
    case 'web_coin':
      return { amount: tip.tipping_package.web_amount, icon: 'macbook-fill' };
    case 'android_coin':
      return {
        amount: tip.tipping_package.android_amount,
        icon: 'android-fill',
      };
    case 'android_iap':
      return {
        amount: tip.tipping_package.android_amount,
        icon: 'android-fill',
      };
    case 'ios_iap':
      return { amount: tip.tipping_package.ios_amount, icon: 'apple-fill' };
    case 'ios_coin':
      return { amount: tip.tipping_package.ios_amount, icon: 'apple-fill' };
    default:
      return { amount: 0, icon: 'macbook-fill' };
  }
};

export interface OgpVideoInfo {
  url: string;
  width: number;
  height: number;
  type: string;
}
export const ogpDataOfPost = (post: Post) => {
  if (!post)
    return {
      imageUrl: '',
      description: '',
      videoInfo: null,
      title: '',
      keywords: [],
    };
  const user = post.user_info;
  if (!user)
    return {
      imageUrl: '',
      description: '',
      videoInfo: null,
      title: '',
      keywords: [],
    };
  const description = ogpDescription(post.text);
  const imageUrl = ogpImageUrl(post);
  const videoInfo = ogpVideoInfo(post);
  const title = ogpTitle(post);
  const keywords = ogpKeywords(post);
  return {
    description: jTrim(description),
    imageUrl,
    videoInfo,
    title: jTrim(title),
    keywords,
  };
};

export const ogpKeywords = (post: Post) => {
  if (!post) return [];
  const keywords1 = (post.domains || []).map((domain) => domain.name);
  const keywords2 = (post.symbols || []).map((symbol) => symbol.name);
  let keywords3: string[] = [];
  try {
    const hashTagRegex = hashTagRegexFactory();
    const tags = ((post.text || '').match(hashTagRegex) || [])
      .slice(0, 10)
      .map((tag) => tag.slice(1));
    keywords3 = tags.filter((tag, index) => tags.indexOf(tag) === index);
  } catch (error) {
    keywords3 = [];
  }
  return [...keywords1, ...keywords2, ...keywords3];
};

export const ogpVideoInfo = (post: Post) => {
  if (!post) return null;
  if (post.streaming) return null;
  if (!post.media || post.media.length === 0) return null;
  const media = post.media[0];
  if (media?.type !== MediaType.Video) return null;
  if (post.type === PostType.Free) {
    return {
      url: media.data?.url,
      width: media.data?.width,
      height: media.data?.height,
      type: 'video/m3u8',
    };
  } else if (
    checkIsPaidPost(post.type) &&
    post.free_minutes > 0 &&
    !!media.data?.free_version
  ) {
    return {
      url: media.data?.free_version,
      width: media.data?.width,
      height: media.data?.height,
      type: 'video/m3u8',
    };
  }
  return null;
};

export const firstTwoLines = (text: string, maxLength: number) => {
  if (!text) return '';
  let breakIndex = text.indexOf('\n');
  if (breakIndex > maxLength) return truncateStr(text, maxLength);
  breakIndex = text.indexOf('\n', breakIndex + 1);
  if (breakIndex > 0 && breakIndex < maxLength)
    return truncateStr(text, breakIndex);
  return truncateStr(text, maxLength);
};

export const ogpTitle = (post: Post) => {
  if (!post) return '';
  if (post.media && post.media[0] && post.media[0].type === MediaType.Audio) {
    return post.media[0].data?.title || '';
  }
  return firstTwoLines(post.text, 30)?.replace(/\n/g, ' ') || '';
};

export const ogpDescription = (text: string) => {
  if (!text) return '';
  const maxLength = 156;
  let breakIndex = text.indexOf('\n');
  if (breakIndex > maxLength) return truncateStr(text, maxLength);
  breakIndex = text.indexOf('\n', breakIndex + 1);
  if (breakIndex > maxLength) return truncateStr(text, maxLength);
  breakIndex = text.indexOf('\n', breakIndex + 1);
  if (breakIndex > 0 && breakIndex < maxLength)
    return truncateStr(text, breakIndex);
  return truncateStr(text, maxLength);
};

export const lockTextContent = (text: string) => {
  if (!text) return '';
  const maxLength = 136;
  let breakIndex = text.indexOf('\n');
  if (breakIndex > maxLength) return truncateStr(text, maxLength);
  breakIndex = text.indexOf('\n', breakIndex + 1);
  if (breakIndex > maxLength) return truncateStr(text, maxLength);
  breakIndex = text.indexOf('\n', breakIndex + 1);
  if (breakIndex > 0 && breakIndex < maxLength)
    return truncateStr(text, breakIndex);
  return truncateStr(text, maxLength);
};

export const postItemText = (text: string, maxLineCount: number) => {
  if (!text) return '';
  const maxLength = maxLineCount * 50;
  let breakIndex = -1;
  for (let i = 0; i < maxLineCount; i++) {
    breakIndex = text.indexOf('\n', breakIndex + 1);
    if (breakIndex > maxLength) return truncateStr(text, maxLength);
  }
  if (breakIndex > 0 && breakIndex < maxLength)
    return truncateStr(text, breakIndex);
  return truncateStr(text, maxLength);
};

export const ogpImageUrl = (post: Post) => {
  if (post.streaming && post.streaming?.thumbnail) {
    return post.streaming?.thumbnail;
  }
  if (!post.media || post.media.length === 0) return '';
  const media = post.media[0];
  const isPaidPost = checkIsPaidPost(post.type);
  switch (media.type) {
    case MediaType.Image: {
      if (isPaidPost && media.data.blur) {
        return '';
      }
      return media.data.url;
    }
    case MediaType.Link: {
      return '';
    }
    case MediaType.Video: {
      return media.data.thumbnail_url || '';
    }
    case MediaType.Audio: {
      return media.data.thumbnail_url || '';
    }
  }
};

export const replaceCharacters = (
  text: string,
  search: string,
  replace: string
) => {
  const regex = RegExp('[' + search + ']', 'g');
  const t = text.replace(regex, function (chr) {
    // Get the position of the found character in the search string.
    const ind = search.indexOf(chr);
    // Get the corresponding character from the replace string.
    const r = replace.charAt(ind);
    return r;
  });
  return t;
};

export const notificationEmotion = (
  notificationType: NotificationType
): PostActionEmotion => {
  switch (notificationType) {
    case NotificationType.Share:
      return PostActionEmotion.Share;
    case NotificationType.Like:
      return PostActionEmotion.Like;
    case NotificationType.Comment:
      return PostActionEmotion.Comment;
    case NotificationType.Reply:
      return PostActionEmotion.Comment;
    case NotificationType.Mention:
      return PostActionEmotion.Comment;
    case NotificationType.Follow:
      return PostActionEmotion.Follow;
    case NotificationType.Subscribe:
      return PostActionEmotion.Subscribe;
    case NotificationType.SubscribePlus:
      return PostActionEmotion.SubscribePlus;
    case NotificationType.CreatePost:
      return PostActionEmotion.CreatePost;
    case NotificationType.ReSchedulePost:
      return PostActionEmotion.CreatePost;
    case NotificationType.CancelSchedulePost:
      return PostActionEmotion.CreatePost;
    case NotificationType.SendTip:
      return PostActionEmotion.SendTip;
    case NotificationType.ProfileView:
      return PostActionEmotion.ProfileView;
    case NotificationType.TrendingPost:
      return PostActionEmotion.TrendingPost;
    case NotificationType.InvitationCodeReferred:
      return PostActionEmotion.InvitationCodeReferred;
    case NotificationType.InvitationCodeNewUser:
      return PostActionEmotion.InvitationCodeNewUser;
    case NotificationType.HostGoLive:
      return PostActionEmotion.HostGoLive;
    case NotificationType.PurchasedCourse:
      return PostActionEmotion.PurchasedCourse;
    default:
      break;
  }
};

export const generateCodeContent = (referralCode: string, coin: number) => {
  return `🎁あなたに ${coin?.toLocaleString()} PostPrime Coin を無料でプレゼントします🎁\nPostPrimeをはじめてみませんか？🌟\n下記の招待コードを使うと、${coin?.toLocaleString()} PostPrime Coinが贈られます！ \nPostPrime Coinで有料機能を使用できます❗️\n招待コード：${referralCode}\nまずはアプリをインストール 👇\n${
    process.env.NEXT_PUBLIC_DYNAMIC_LINK_URL
  }`;
};

export const isUploadedMedia = (url: string) => {
  return url && !url.startsWith('blob:');
};

export const precheckPolicyNames = (precheckLogs: PrecheckLog[]) => {
  if (!precheckLogs || !precheckLogs[0]) return '';
  const policies = precheckLogs[0].reasons || [];
  return policies.map((policy) => `「${policy.name}」`).join('、');
};

export const externalUrlRedirectLink = (url: string) => {
  // return url;
  return `/url_redirect?url=${url}`;
};

export const jTrim = (str: string) => {
  if (!str) {
    return '';
  }
  const re =
    /^[\s\xA0\uFEFF\u1680\u180E\u2000-\u200A\u202F\u205F\u3000]+|[\s\xA0\uFEFF\u1680\u180E\u2000-\u200A\u202F\u205F\u3000]+$/g;
  return str.replace(re, '');
};

export const removeEmojis = (text: string) => {
  if (!text) {
    return '';
  }
  return text.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ''
  );
};

export const kanaValid = (name: string) => {
  if (!name) return true;
  // eslint-disable-next-line no-irregular-whitespace
  const regex = /^[ァ-ー　\s]+$/;
  return regex.test(jTrim(name));
};

export const sortString = (a: string, order: 'asc' | 'desc') => {
  if (!a) return '';
  const arr = Array.from(a);
  const orderNum = order === 'asc' ? 1 : -1;
  return arr
    .sort((a, b) => {
      if (a < b) return -1 * orderNum;
      if (a > b) return 1 * orderNum;
      if (a === b) return 0;
    })
    .join('');
};

export const assetsUrl = (path: string) => {
  return `https://assets.postprime.com/${path}`;
};

export const textWithoutEnter = (text: string) => {
  if (!text) return '';
  return text.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ');
};

export const getRandomElementInArray = <T>(arr: T[]): T => {
  if (!arr || !Array.isArray(arr) || arr.length === 0) return null;
  return arr[Math.floor(Math.random() * arr.length)];
};

export const timeString = (dateStr: string) => {
  if (!dateStr) return '';
  if (dayjs(dateStr).format('YYYY/MM/DD') !== dayjs().format('YYYY/MM/DD')) {
    return dayjs(dateStr).format('YYYY/MM/DD HH:mm');
  }
  return dayjs(dateStr).format('HH:mm');
};
