import dynamic from 'next/dynamic';
import { useEffect, useMemo } from 'react';
import * as portals from 'react-reverse-portal';

import useStore, { AudioPlayerInfo } from 'store/global';
import useTimelineStore from 'store/timeline';

import styles from './AppPortal.module.scss';

const AudioPlayer = dynamic(() => import('components/common/AudioPlayer'), {
  ssr: false,
});

export type AudioPlayerPortalProps = {
  audioPlayer: AudioPlayerInfo;
};
export function AudioPlayerPortal({
  audioPlayer,
}: AudioPlayerPortalProps): JSX.Element {
  const portalNode = useMemo(() => portals.createHtmlPortalNode(), []);
  const [currNode, setPortalNode] = useStore((state) => [
    state.portalNodes[audioPlayer.id],
    state.setPortalNode,
  ]);

  // Create a portal node for the audio player
  useEffect(() => {
    if (audioPlayer && !currNode) {
      setPortalNode(audioPlayer.parent + audioPlayer.id.toString(), portalNode);
    }
  }, [audioPlayer, currNode, portalNode, setPortalNode]);

  return (
    <portals.InPortal node={portalNode}>
      <AudioPlayer
        id={audioPlayer.id}
        url={audioPlayer.url}
        trackTitle={audioPlayer.trackTitle}
        postAuthor={audioPlayer.postAuthor}
        parent={audioPlayer.parent}
        spectrum={audioPlayer.spectrum}
        duration={audioPlayer.duration}
        thumbnailUrl={audioPlayer.thumbnailUrl}
        canSeeFreeMinutes={audioPlayer.canSeeFreeMinutes}
        freeMinutes={audioPlayer.freeMinutes}
        freeUrl={audioPlayer.freeUrl}
        isLocked={audioPlayer.isLocked}
        isPostDetail={audioPlayer.isPostDetail}
        postType={audioPlayer.postType}
        postSource={audioPlayer.postSource}
      />
    </portals.InPortal>
  );
}

export default function AppPortal(): JSX.Element {
  const audioPlayers = useStore((state) => state.audioPlayers);
  const activeId = useTimelineStore((state) => state.activeAudioPlayer?.id);
  const portalNode = useStore((state) => state.portalNodes[activeId]);

  // Render all audio players in current page
  const players = Object.keys(audioPlayers).map(function (key) {
    return (
      audioPlayers[key] && (
        <AudioPlayerPortal key={key} audioPlayer={audioPlayers[key]} />
      )
    );
  });

  // Render global player when the active player's parent unmounts
  const globalPlayerRender = useMemo(() => {
    const doShow = audioPlayers[activeId] && audioPlayers[activeId].removed;
    return doShow && <portals.OutPortal node={portalNode} isGlobal />;
  }, [audioPlayers, activeId, portalNode]);

  return (
    <div className={styles.appPortalWrapper}>
      {players}
      {globalPlayerRender}
    </div>
  );
}
