import axios from 'axios';
import { JSONbigNative } from 'common/utils';
import * as utils from 'lib/axios';
const apiConfig = {
  BACKEND_BASE_URL: process.env.NEXT_PUBLIC_BACKEND_BASE_URL,
};

axios.defaults.baseURL = apiConfig.BACKEND_BASE_URL;

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

axios.defaults.transformResponse = [
  (data) => {
    if (typeof data === 'string') {
      try {
        data = JSONbigNative.parse(data);
        // eslint-disable-next-line no-empty
      } catch (e) {
        data = JSON.parse(data);
      }
    }
    return data;
  },
];

const setContentTypeIfUnset = (headers, value) => {
  if (
    !utils.isUndefined(headers) &&
    utils.isUndefined(headers['Content-Type'])
  ) {
    headers['Content-Type'] = value;
  }
};

axios.defaults.transformRequest = [
  (data, headers) => {
    utils.normalizeHeaderName(headers, 'Accept');
    utils.normalizeHeaderName(headers, 'Content-Type');
    if (
      utils.isFormData(data) ||
      utils.isArrayBuffer(data) ||
      utils.isBuffer(data) ||
      utils.isStream(data) ||
      utils.isFile(data) ||
      utils.isBlob(data)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data;
    }
    if (utils.isArrayBufferView(data)) {
      return data.buffer;
    }
    if (utils.isURLSearchParams(data)) {
      setContentTypeIfUnset(
        headers,
        'application/x-www-form-urlencoded;charset=utf-8'
      );
      return data.toString();
    }
    if (utils.isObject(data)) {
      setContentTypeIfUnset(headers, 'application/json;charset=utf-8');
      return JSONbigNative.stringify(data);
    }
    return data;
  },
];
/* eslint-enable @typescript-eslint/no-var-requires */
/* eslint-enable @typescript-eslint/no-unsafe-call */
/* eslint-enable @typescript-eslint/no-unsafe-assignment */
/* eslint-enable @typescript-eslint/no-unsafe-return */

export default apiConfig;
