import { CharacterStageId, doneAnimeIntroduction } from 'service/anime';
import styles from './CharacterAvatar.module.scss';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import { useMemo, useState } from 'react';
import { addAnimeTrackingEvent, characterAvatarUrl } from 'common/utils/anime';
import { EventAction, EventComponent } from 'common/utils/pp_tracking';
import { track } from '@amplitude/analytics-browser';
import Icon from 'components/common/Icon';
const CharacterAvatar = () => {
  const currentUser = useStore((state) => state.currentUser);
  const showAnimeOnboardingDialog = useGlobalStore(
    (state) => state.showAnimeOnboardingDialog
  );
  const setUserClickedAnimeIcon = useGlobalStore(
    (state) => state.setUserClickedAnimeIcon
  );
  const userClickedAnimeIcon = useGlobalStore(
    (state) => state.userClickedAnimeIcon
  );
  const refetchProfile = useStore((state) => state.refetchProfile);
  const [clickedPopup, setClickedPopup] = useState(false);
  const currentCharacterAvatarUrl = useMemo(() => {
    if (
      !currentUser ||
      !currentUser.user_character ||
      currentUser.user_character?.stage?.id === CharacterStageId.Egg
    ) {
      return '/images/anime-characters/egg.png';
    }
    return characterAvatarUrl(
      currentUser.user_character?.character?.name,
      currentUser.user_character?.stage?.id
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser?.user_character?.stage?.id,
    currentUser?.user_character?.character?.name,
  ]);
  const onClosePopup = () => {
    if (!currentUser?.anime_character_introduction) {
      try {
        void doneAnimeIntroduction().then(() => {
          refetchProfile();
        });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    setClickedPopup(true);
  };

  if (!currentUser) return <></>;
  const showingPopup =
    !currentUser?.anime_character_introduction && !clickedPopup;
  return (
    <div className={styles.characterAvatar}>
      <div
        className={styles.avatar}
        onClick={() => {
          onClosePopup();
          track('Click Character Button');
          addAnimeTrackingEvent({
            action: EventAction.Click,
            component: EventComponent.GlobalHeader,
            position: 'header_anime_icon',
          });
          if (
            currentUser?.anime_character_onboarding ||
            !currentUser?.user_character
          ) {
            showAnimeOnboardingDialog(true);
          }
          if (currentUser?.user_character) {
            setUserClickedAnimeIcon(true);
          }
        }}
      >
        <img
          src={currentCharacterAvatarUrl}
          height={40}
          alt="Character Avatar"
          title="AI キャラクター"
        />
      </div>
      {userClickedAnimeIcon && (
        <div className={styles.loading}>
          <Icon name="loading-anim-3" width={24} height={24} />
        </div>
      )}
      {showingPopup && (
        <div className={styles.characterPRPopover}>
          <span>AI キャラクター{'\n'}はこちら</span>
          <button
            onClick={() => {
              onClosePopup();
            }}
          >
            閉じる
          </button>
        </div>
      )}
    </div>
  );
};

export default CharacterAvatar;
