import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';
import useStore from 'store/timeline';
import { User } from 'common/interfaces/api';
import RoundIcon from 'components/common/RoundIcon';
import BadgeIcon from 'components/common/BadgeIcon';
import Icon from 'components/common/Icon';
import { useMediaQuery } from 'common/utils';
import useGlobalStore from 'store/global';

import styles from './UserInfo.module.scss';
import { isIzanaviMember, isMembershipAccount } from 'common/utils/membership';
import { ScreenName } from 'common/utils/pp_tracking';
import MembershipVipIcon from '../MembershipVipIcon';
import Link from 'next/link';

export type Props = {
  user: User;
  avatarSize?: number;
  badgeSize?: number;
  hasFollowBtn?: boolean;
  rightSideFollowBtn?: boolean;
  hasUsername?: boolean;
  showBadge?: boolean;
  isAdsPost?: boolean;
  showLiveFlag?: boolean;
  postedAt?: string;
  showMembershipIcon?: boolean;
  membershipIconSize?: number;
  showEmotion?: boolean;
};

export default function UserInfo({
  user,
  avatarSize = 48,
  badgeSize = 32,
  membershipIconSize = 66,
  hasFollowBtn = false,
  rightSideFollowBtn = false,
  hasUsername = false,
  showBadge = true,
  isAdsPost = false,
  showLiveFlag = false,
  showMembershipIcon = false,
  postedAt,
  showEmotion = true,
}: Props): JSX.Element {
  const currentUser = useStore((state) => state.currentUser);
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  // Follow button
  // use local state to make sure user's action is immediate
  const [isFollowed, setIsFollowed] = useState(user?.is_followed);
  const mediaMax767px = useMediaQuery('(max-width: 767px)');
  const showLogin = useGlobalStore((state) => state.showLogin);
  const refetchTippedListOfPost = useStore(
    (state) => state.refetchTippedListOfPost
  );
  const showDiscountCreatorsDialog = useGlobalStore(
    (state) => state.showDiscountCreatorsDialog
  );
  const showRecommendCreatorDialog = useGlobalStore(
    (state) => state.showRecommendCreatorDialog
  );
  useEffect(() => {
    setIsFollowed(user?.is_followed);
  }, [user?.is_followed]);

  if (!user) return <></>;
  const userProfileUrl = `/${user.username}`;
  const handleFollowClick = async (userId: number, isFollowed: boolean) => {
    if (currentUser) {
      const action = isFollowed ? 'unfollow' : 'follow';
      if (isFollowed) {
        setIsFollowed(false);
      } else {
        setIsFollowed(true);
      }
      await axios.post(`/users/follows`, {
        user_id: userId,
        action: action,
      });
      refetchTippedListOfPost();
    } else {
      showLogin({});
    }
  };
  const gotoUserProfile = () => {
    showRecommendCreatorDialog(null);
    showDiscountCreatorsDialog(false);
  };

  const jobText = () => {
    if (isAdsPost) {
      return 'Sponsored';
    } else if (postedAt) {
      return postedAt;
    } else {
      return user.job || '';
    }
  };
  return (
    <div
      className={styles.userInfoWrapper}
      style={{ width: rightSideFollowBtn ? '100%' : 'auto' }}
    >
      <RoundIcon
        user={user}
        width={avatarSize}
        height={avatarSize}
        hasRing
        enableClick
        emotion={showEmotion ? user?.emotion : null}
        showLiveFlag={showLiveFlag}
        onClick={() => {
          showRecommendCreatorDialog(null);
          showDiscountCreatorsDialog(false);
        }}
      />
      <div className={styles.userInfo}>
        <Link href={userProfileUrl}>
          <a onClick={() => gotoUserProfile()}>
            <div
              className={cn(styles.userNameText, 'userNameText')}
              title={user.name}
            >
              {user.name}
            </div>
          </a>
        </Link>
        <div className={cn(styles.userJobText, 'userJobText')} title={user.job}>
          {jobText()}
        </div>
        {hasUsername && (
          <div className={styles.userJobText} title={user.username}>
            {user.username}
          </div>
        )}
      </div>
      {showBadge && (
        <div className={styles.badge}>
          <BadgeIcon
            badge={isAdsPost ? 'advertiser' : user.badge}
            width={badgeSize}
            height={badgeSize}
          />
        </div>
      )}
      {showMembershipIcon &&
        (isMembershipAccount(user) || isIzanaviMember(user)) && (
          <div className={styles.membershipIcon}>
            <MembershipVipIcon
              packageName={user?.membership?.membership_package?.package_name}
              width={membershipIconSize}
              height={(membershipIconSize / 61) * 32}
              isIzanavi={isIzanaviMember(user)}
              onClick={() => {
                if (!currentUser) {
                  showLogin({});
                  return;
                }
                setShowMembershipPayDialog({
                  open: true,
                  showMembershipStatus: false,
                  screen: ScreenName.AccountSetting,
                });
              }}
            />
          </div>
        )}

      {!isAdsPost &&
        hasFollowBtn &&
        !isFollowed &&
        currentUser?.username !== user.username && (
          <div
            style={{
              marginLeft:
                rightSideFollowBtn && !mediaMax767px ? 'auto' : '10px',
              flexShrink: 0,
            }}
            className={styles.follow}
          >
            <Icon
              name="follow"
              width={32}
              height={32}
              isOn={isFollowed}
              hasOn
              hasHover
              onClick={() => handleFollowClick(user.id, isFollowed)}
            />
          </div>
        )}
    </div>
  );
}

export const UserWithDeletedCheck = ({ user }: { user: User | null }) => {
  return user ? (
    <UserInfo hasFollowBtn user={user} />
  ) : (
    <div className={styles.deletedUser}>
      <RoundIcon user={{} as User} width={48} hasRing height={48} />
      <span>このユーザーは削除されました</span>
    </div>
  );
};
