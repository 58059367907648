import cn from 'classnames';
import { MouseEventHandler } from 'react';

import Icon from 'components/common/Icon';

import styles from './Button.module.scss';

export type Props = {
  text: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  icon?: string;
  alternateColor?: boolean;
  alternateColorFaded?: boolean;
  alternateColorWarning?: boolean;
  hasOn?: boolean;
  fadeColorWhenOn?: boolean;
  isOn?: boolean;
  onText?: string;
  blackAndWhite?: boolean;
  smallIcon?: boolean;
  verticalPadding?: number;
  horizontalPadding?: number;
  isLoading?: boolean;
  rounded?: boolean;
  fullWidth?: boolean;
  fadingStyle?: boolean;
  bigGuyStyle?: boolean;
  allowBreakLine?: boolean;
  colorName?:
    | 'red'
    | 'black'
    | 'lightBlue'
    | 'grayGreen'
    | 'lightBlueHover'
    | 'gray'
    | 'whiteGreen'
    | 'greenWhite'
    | 'whiteGray'
    | 'lightGray'
    | 'linearOrange'
    | 'linearOrangeHover';
};

export default function Button({
  onClick,
  text,
  disabled = false,
  icon,
  alternateColor = false,
  alternateColorFaded = false,
  alternateColorWarning = false,
  hasOn = false,
  fadeColorWhenOn = false,
  isOn,
  onText,
  blackAndWhite = false,
  smallIcon = false,
  verticalPadding = 6,
  horizontalPadding = 18,
  isLoading = false,
  rounded = false,
  fullWidth = false,
  fadingStyle = false,
  bigGuyStyle = false,
  allowBreakLine = false,
  colorName = null,
}: Props): JSX.Element {
  // take into account black and white mode's border
  // so that a button's height is consistent
  if (!blackAndWhite) verticalPadding += 2;
  const iconSize = smallIcon ? 18 : 32;

  let textRender: React.ReactChild;
  if (isLoading) {
    textRender = <Icon name="loading-anim-2" width={24} height={24} />;
  } else {
    textRender = hasOn && isOn ? onText : text;
  }
  return (
    <button
      className={cn(styles.button, {
        [styles.disabled]: disabled,
        [styles.alternateColor]: alternateColor,
        [styles.alternateColorFaded]: alternateColorFaded,
        [styles.alternateColorWarning]: alternateColorWarning,
        [styles.hasIcon]: !!icon,
        [styles.blackAndWhite]: blackAndWhite,
        [styles.faded]: fadeColorWhenOn && isOn,
        [styles.rounded]: rounded,
        [styles.loading]: isLoading,
        [styles.fullWidth]: fullWidth,
        [styles.fadingStyle]: fadingStyle,
        [styles.bigGuyStyle]: bigGuyStyle,
        [styles.allowBreakLine]: allowBreakLine,
        [styles[colorName]]: !!colorName,
      })}
      disabled={disabled || isLoading}
      onClick={onClick}
      style={{
        padding: `${verticalPadding}px ${horizontalPadding}px`,
      }}
    >
      {icon && (
        <div className={styles.icon}>
          <Icon
            name={icon}
            width={iconSize}
            height={iconSize}
            hasOn={hasOn}
            isOn={isOn}
          />
        </div>
      )}
      {textRender}
    </button>
  );
}
