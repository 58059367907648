import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React, { SyntheticEvent, useEffect } from 'react';

import useGlobalStore from 'store/global';

import styles from './SnackBar.module.scss';

export default function SnackBar(): JSX.Element {
  const snackbarMessage = useGlobalStore((state) => state.snackbarMessage);
  const disableAutoHide = snackbarMessage?.disableAutoHide;
  const autoHideDuration = snackbarMessage?.autoHideDuration || 3500;
  const [open, setOpen] = React.useState(false);
  const handleClose = (
    event: SyntheticEvent<Element, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (snackbarMessage) {
      setOpen(true);
    }
  }, [snackbarMessage]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={disableAutoHide ? null : autoHideDuration}
      className={styles.snackbar}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarMessage?.type}
        elevation={6}
        variant="filled"
        className={styles.alert}
      >
        {snackbarMessage?.text}
      </Alert>
    </Snackbar>
  );
}
