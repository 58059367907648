import classNames from 'classnames';
import styles from './LinkButton.module.scss';

type LinkButtonProps = {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  fontSize?: number;
  marginRight?: number;
  marginLeft?: number;
  type?: 'green' | 'normal' | 'red';
  nonUnderline?: boolean;
};

const LinkButton = ({
  text,
  disabled = false,
  onClick,
  fontSize = 12,
  marginRight = 0,
  marginLeft = 0,
  type = 'green',
  nonUnderline = false,
}: LinkButtonProps): JSX.Element => {
  return (
    <button
      disabled={disabled}
      className={classNames(styles.linkBtn, styles[type], {
        [styles.nonUnderline]: nonUnderline,
      })}
      onClick={onClick}
      style={{
        fontSize: `${fontSize}px`,
        marginRight: `${marginRight}px`,
        marginLeft: `${marginLeft}px`,
      }}
    >
      {text}
    </button>
  );
};

export default LinkButton;
