import axios from 'axios';
import {
  AddressResultType,
  CreatorRanking,
  ExpiringCoin,
  GetUserProfileByUsernameResult,
  IntroEvent,
  NewsLettingSettingType,
  NotificationSetting,
  NotificationSettingType,
  Post,
  RoleStatus,
  User,
  UserPopup,
} from 'common/interfaces/api';

export const getUserProfile = async (
  username: string,
  isPreload?: boolean,
  referral?: string
) => {
  const preloadParam = isPreload ? `preload=true` : '';
  const referralParam = referral ? `referral=${referral}` : '';
  const params = [preloadParam, referralParam].filter((pr) => !!pr).join('&');
  const paramsStr = params ? `?${params}` : '';
  return (await axios.get(`/profiles/${username}${paramsStr}`))
    .data as GetUserProfileByUsernameResult;
};

export const getSuggestKeywords = async (keyword?: string) => {
  const kwParam = keyword ? `?keyword=${keyword}` : '';
  return (await axios.get(`/searches/suggest-search${kwParam}`)).data as {
    data: {
      recent_keywords: string[];
      trending_keywords: string[];
      suggest_keywords: string[];
    };
  };
};

export const deleteRecentKeyword = async (keyword: string) => {
  return await axios.post(`/searches/recent-search`, { keyword });
};

export const verifyEmail = async (token: string) => {
  return (await axios.post(`/users/emails/verify`, { token })).data as {
    data: string;
  };
};

export const sendVerifyEmail = async () => {
  return await axios.post('/users/emails/generate', {});
};

export const verifyPhone = async () => {
  return await axios.post('/profiles/phone_number');
};

export const checkPhone = async (phone: string) => {
  return (
    await axios.post('/profiles/phone_number/is_registered', {
      phone_number: phone,
    })
  )?.data as { data: { is_registered: boolean } };
};

export const getEvents = async () => {
  return (await axios.get('/profiles/system/promotions')).data as {
    data: IntroEvent[];
  };
};

export const getCreatorsRanking = async (page: number) => {
  return (await axios.get(`/users/creators_ranking?limit=20&page=${page}`))
    .data as {
    data: CreatorRanking[];
  };
};

export const getRecentPostsUsers = async () => {
  return (await axios.get(`/users/follows?recent_posts=true`)).data as {
    data: { users: User[] };
  };
};

export const getNotificationsSetting = async () => {
  return (await axios.get(`/users/settings`)).data as {
    data: {
      notification: NotificationSetting;
    };
  };
};

export const updateNotificationsSetting = async (
  type: NotificationSettingType,
  checked: boolean
) => {
  return await axios.patch(`/users/settings/notifications`, {
    setting: type,
    value: checked,
  });
};

export const updateNewsletterSetting = async (
  type: NewsLettingSettingType,
  checked: boolean
) => {
  return await axios.patch(`/users/settings/email-reference`, {
    setting: type,
    value: checked,
  });
};

export type ProfileViewer = {
  user: User;
  last_viewed_datetime: string;
};

export const getProfileViewers = async (page: number) => {
  return (await axios.get(`/profiles/profile_viewed/users?page=${page}`))
    .data as {
    data: {
      users: ProfileViewer[];
      view_count: number;
    };
  };
};

export const handleFollowAction = async (
  userId: number,
  action: 'follow' | 'unfollow'
) => {
  return await axios.post(`/users/follows`, {
    user_id: userId,
    action: action,
  });
};

export const handleRegistryNotificationAction = async (
  username: string,
  type: 'post' | ''
) => {
  return await axios.post(`/profiles/followership_notification`, {
    followee_name: username,
    notification_type: type,
  });
};

export enum MissionTaskStatus {
  Ready = 'ready',
  InProgress = 'in_progress',
  Done = 'done',
  NotYet = 'not_yet',
}

export enum MissionDifficulty {
  Hard = 'hard',
  Normal = 'normal',
  Easy = 'easy',
}

export type MissionTask = {
  code: string;
  id: number;
  coin_package: {
    amount: number;
    code: string;
    name: string;
  };
  progress: number;
  name: string;
  status: MissionTaskStatus;
  steps: number;
  web_id: number;
  type: MissionTaskType;
};

export enum MissionTaskType {
  Single = 'single',
  Multi = 'multi',
}

export enum MissionPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum MissionCode {
  UserFollow = 'user_follow',
  LoginFirst2Days = 'login_first_2_days',
  InputProfile = 'input_profile',
  CheckLivePage = 'check_live_page',
  CheckBadge = 'check_badge',
  VoteSymbol = 'vote_symbol',
  LevelUp2 = 'level_up_2',
  LevelUp3 = 'level_up_3',
  LevelUp4 = 'level_up_4',
  DownloadApp = 'download_app',
  InviteFriend = 'invite_friend',
  ContinueLogin = 'continue_login',
  InputCode = 'input_code',
  LevelUp5 = 'level_up_5',
  FreeTrialPrimeSubscription = 'free_trial_prime_subscription',
  IGCreateAccount = 'ig_account_register',
  SBINeoCreateAccount = 'sbi_neo_trade_account_register',
  InvastCreateAccount = 'invast_trade_account_register',
}

export type Mission = {
  difficulty: MissionDifficulty;
  priority: MissionPriority;
  image: string;
  name: string;
  coin: number;
  code: MissionCode;
  is_recommended: boolean;
  current_task: MissionTask;
  all_task: MissionTask[];
  task_type: MissionTaskType;
  link?: string;
  display_order?: number;
};

export const getMissions = async () => {
  return (await axios.get(`/users/missions`)).data as {
    data: {
      missions: Mission[];
    };
  };
};

export const completeMission = async ({
  missionCode,
  taskCode,
  taskId,
  webTaskId,
}: {
  missionCode: string;
  taskCode: string;
  taskId: number;
  webTaskId: number;
}) => {
  return await axios.post(`/users/missions/complete`, {
    mission_code: missionCode,
    task_code: taskCode,
    task_id: taskId,
    web_task_id: webTaskId,
  });
};

export const updateTopics = async (topicIds: number[]) => {
  return await axios.post(`/users/domains`, { domain_ids: topicIds });
};

export const getUserDomains = async () => {
  return (await axios.get(`/users/domains`))?.data as {
    data: TopicType[];
  };
};

export const checkBadgeComplete = async () => {
  return await axios.post(`/profiles/badges`);
};

export type TopicType = {
  id: number;
  name: string;
  parent_domain?: {
    id: number;
    name: string;
  };
  is_use_for_beginner?: boolean;
  is_disabled?: boolean;
};

export type OnboardingChannel = {
  name: string;
  user_id: number;
  username: string;
  order: number;
  avatar: string;
  job: string;
};

export const getOnboardingChannels = async () => {
  return (await axios.get(`/users/on_boarding/channel_selections`)).data as {
    data: OnboardingChannel[];
  };
};

export const setOnboardingChannels = async (userIds: number[]) => {
  return await axios.post(`/users/on_boarding/channel_selections`, {
    user_ids: userIds,
  });
};

export interface CreatorInfo {
  address: {
    address1: string;
    address2: string;
    country: string;
    zipcode: string;
  };
  corporate_name: {
    name: string;
    name_kana: string;
    representative_name: string;
    representative_name_kana: string;
  };
  documents: [
    {
      type: string;
      url: string;
    }
  ];
  individual_name: {
    first_name: string;
    first_name_kana: string;
    last_name: string;
    last_name_kana: string;
  };
  invoice_registration_number: string;
  invoice_registration_number_active: boolean;
  email: string;
  is_individual: boolean;
  is_underage: boolean;
  tax_id: string;
}

export const getCreatorInfo = async () => {
  return (await axios.get(`/payments/creators/detail`)).data as {
    data: CreatorInfo;
  };
};

export const getProfileMasterData = async () => {
  return (await axios.get(`/recruitment/profile/master-data`))?.data as {
    data: {
      education_levels: { id: number; name: string }[];
      employment_types: { id: string; name: string }[];
      industry_types: { id: number; name: string }[];
      language_levels: { id: string; name: string }[];
      languages: {
        id: number;
        name_en: string;
        name_jap: string;
        code: string;
      }[];
      nationalities: { name: string; code: string; id: number }[];
      occupations: { id: number; name: string }[];
      salary_ranges: { id: number; name: string }[];
      time_change_jobs: { id: string; name: string }[];
      education_statuses: { id: string; name: string }[];
    };
  };
};

export const getAddressByZip = async (zip: string) => {
  return (await axios.get(`/payments/addresses?code=${zip}`))
    .data as AddressResultType;
};

export type ProfileEducationType = {
  description: string;
  // education_level: {
  //   id: number;
  //   name: string;
  // };
  education_level_id: number;
  from: string;
  // id: number;
  // is_public: boolean;
  school_name: string;
  status: string;
  to: string;
};

export type ProfileJobDetailType = {
  description: string;
  from: string;
  department: string;
  to: string;
};

export type ProfileJobType = {
  company_name: string;
  details: ProfileJobDetailType[];
  employment_type: string;
  from: string;
  supplementary_info?: string;
  to: string;
};

export type ProfileLanguageType = {
  id?: number;
  language?: {
    code: string;
    id: number;
    name_en: string;
    name_jap: string;
  };
  language_id: number;
  level: string;
  supplementary_info?: string;
};

export type ProfileSkillType = {
  exp_period_month: number;
  exp_period_year: number;
  name: string;
  skill_type?: string;
};

export type UserProfileInfoType = {
  address_building_room: string;
  address_city: string;
  address_prefecture: string;
  address_street: string;
  address_zip1: string;
  address_zip2: string;
  birthday: string;
  email: string;
  industry: {
    id: number;
    name: string;
  };
  is_basic_information_public: boolean;
  is_education_public: boolean;
  is_profile_job_public: boolean;
  is_profile_public: boolean;
  last_name: string;
  last_name_kana: string;
  first_name: string;
  first_name_kana: string;
  occupation: {
    id: number;
    name: string;
  };
  desired_occupation: {
    id: number;
    name: string;
  };
  desired_industry: {
    id: number;
    name: string;
  };
  desired_time_change_job: { id: string; name: string };
  activity: string;
  current_salary_range: {
    id: number;
    name: string;
  };
  desired_salary_range: {
    id: number;
    name: string;
  };
  gender: number;
  id: number;
  interest: string;
  number_of_fields_entered: number;
  total_fields: number;
  nationality: {
    code: string;
    name: string;
    id: number;
  };
  phone_number: string;
  profile_educations: ProfileEducationType[];
  profile_jobs: ProfileJobType[];
  profile_languages: ProfileLanguageType[];
  profile_skills: ProfileSkillType[];
  self_introduction: string;
  user_id: number;
  year_of_birth: string;
  is_info_sharing_approved: boolean;
};
export const getUserProfileInfo = async (userId: number) => {
  return (await axios.get(`/recruitment/users/${userId}/profile`)).data as {
    data: UserProfileInfoType;
  };
};

export const updateUserProfileInfo = async (userId: number, data: any) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    (await axios.put(`/recruitment/users/${userId}/profile`, { param: data }))
      .data as {
      data: UserProfileInfoType;
    }
  );
};

export const getRemindBannerData = async () => {
  return (await axios.get(`/recruitment/profile/notification-management`))
    .data as {
    data: {
      need_show_remind_popup: boolean;
    };
  };
};

export const turnOffRemindBanner = async () => {
  return await axios.put(
    `/recruitment/profile/notification-management/turn-off-remind-popup`,
    {}
  );
};

export const sendRequestChangeLivePermission = async (
  newStatus: RoleStatus.Untreated | RoleStatus.Canceled
) => {
  return await axios.post(`/users/permissions`, {
    permission: 'live',
    status: newStatus,
  });
};

export const saveRegistrationNumber = async (
  newNumber: string,
  active: boolean
) => {
  return await axios.put(`/payments/invoice-registration-number`, {
    registration_number: newNumber,
    active,
  });
};

export const closePopupRegistrationNumber = async () => {
  return await axios.post(
    `/users/turn_off_invoice_registration_number_remind_popup`
  );
};

export const updateUserAgeInfo = async ({
  birthDay,
  isUnderage,
}: {
  birthDay?: string;
  isUnderage: boolean;
}) => {
  return await axios.patch(`/profiles/info`, {
    is_underage: isUnderage,
    ...(isUnderage && birthDay ? { birthday: birthDay } : {}),
  });
};

export const getCreatorsVoices = async () => {
  return (await axios.get(`/users/voices`)).data as {
    data: {
      user: User;
      comment: string;
    }[];
  };
};

export const getCreatorPosts = async (userId: number) => {
  return (await axios.get(`/posts/creator/${userId}/top`)).data as {
    data: Post[];
  };
};

export interface OnboardingAnswer {
  id: number;
  name: string;
  description?: string;
}

export interface OnboardingQuestion {
  id: number;
  question: string;
  answers: OnboardingAnswer[];
  type: 'single' | 'multiple';
}

export const doneOnboarding = async ({
  domainIds,
  investmentLevelId,
}: {
  domainIds?: number[];
  investmentLevelId?: number;
}) => {
  return await axios.post('/users/on_boarding', {
    ...(domainIds ? { domain_ids: domainIds } : {}),
    ...(investmentLevelId ? { investment_level_id: investmentLevelId } : {}),
  });
};
export interface CoinDetails {
  consumable_coin_total: number;
  during_campaign_coin_amount: number;
  expiring_coins: ExpiringCoin[];
  membership_upcoming_coin_item: { name: string; coin_amount: number } | null;
  pending_coin_total: number;
  prime_subscription_upcoming_coin_items:
    | { name: string; coin_amount: number }[]
    | null;
  sum: number;
  upcoming_coin_amount: number;
}

export const getFeatureCreators = async () => {
  return (await axios.get(`/feeds/featured_users`)).data as {
    data: {
      channels: User[];
      creators: User[];
    };
  };
};

export const getCoinDetailsInfo = async () => {
  return (await axios.get('/profiles/upcoming-coins'))?.data as {
    data: CoinDetails;
  };
};

export const getUserPopups = async () => {
  return (await axios.get('/profiles/me/popups'))?.data as {
    data: UserPopup[];
  };
};
