import { TrendingTopicPeriod } from 'common/interfaces/api';

export const stringToPeriod = (
  str: string,
  defaultPeriod: TrendingTopicPeriod
) => {
  if (!str) return defaultPeriod || TrendingTopicPeriod.ShortTerm;
  switch (str) {
    case 'daily':
      return TrendingTopicPeriod.ShortTerm;
    case 'weekly':
      return TrendingTopicPeriod.MidTerm;
    case 'monthly':
      return TrendingTopicPeriod.LongTerm;
  }
  return defaultPeriod || TrendingTopicPeriod.ShortTerm;
};

export const periodToString = (period: TrendingTopicPeriod) => {
  switch (period) {
    case TrendingTopicPeriod.ShortTerm:
      return 'daily';
    case TrendingTopicPeriod.MidTerm:
      return 'weekly';
    case TrendingTopicPeriod.LongTerm:
      return 'monthly';
    default:
      return 'daily';
  }
};

export const periodToJapString = (period: TrendingTopicPeriod) => {
  switch (period) {
    case TrendingTopicPeriod.ShortTerm:
      return '1日';
    case TrendingTopicPeriod.MidTerm:
      return '1週間';
    case TrendingTopicPeriod.LongTerm:
      return '1カ月';
    default:
      return '1日';
  }
};
