import {
  IntroEvent,
  Language,
  MembershipExtendedPackage,
  MembershipPackage,
  MembershipPackageExtendedPackageName,
  MembershipPackageName,
  MembershipPackageRecurring,
  NotificationType,
  TrendingTopicPeriod,
  User,
} from 'common/interfaces/api';
import {
  addEvent,
  EventAction,
  EventCategory,
  EventComponent,
  ScreenName,
} from 'common/utils/pp_tracking';
import { timeUntil } from '.';

export const isMembershipAccount = (user: User) => {
  return !!user?.membership;
};

export const isGreenMember = (user: User) => {
  return (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Green
  );
};

export const isSilverMemberAbove = (user: User) => {
  return [
    MembershipPackageName.Silver,
    MembershipPackageName.Gold,
    MembershipPackageName.Platinum,
  ].includes(user?.membership?.membership_package?.package_name);
};

export const isSilverMember = (user: User) => {
  return (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Silver
  );
};

export const isCurrentGoldMember = (user: User) => {
  return (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Gold
  );
};

// is gold or above
export const isGoldMember = (user: User) => {
  return (
    user?.membership?.membership_package?.package_name ===
      MembershipPackageName.Gold || isPlatinumMember(user)
  );
};

export const isExactlyGoldMember = (user: User) => {
  return (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Gold
  );
};

export const isGoldMemberWithInterval = (user: User) => {
  return (
    user?.membership?.membership_package?.package_name ===
      MembershipPackageName.Gold && user?.membership?.is_interval
  );
};

export const isPlatinumMember = (user: User) => {
  return (
    user?.membership?.membership_package?.package_name ===
      MembershipPackageName.Platinum ||
    user?.membership?.membership_package?.package_name ===
      MembershipPackageName.Izanavi
  );
};

export const getIzanaviExtendPackageStatus = (user: User) => {
  if (
    !user ||
    !user?.expand_membership_statuses ||
    user?.expand_membership_statuses?.length === 0
  )
    return null;
  return user?.expand_membership_statuses?.find(
    (status) =>
      status.expand_membership_package?.package_name ===
      MembershipPackageExtendedPackageName.Izanavi
  );
};

export const checkIsSubbedIzanaviBefore = (user: User) => {
  if (!user) return false;
  if (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Izanavi
  )
    return true;
  const izanaviExtendPackageStatus = getIzanaviExtendPackageStatus(user);
  return !!izanaviExtendPackageStatus?.is_subscribed_before;
};

export const isCancelingIzanavi = (user: User) => {
  if (!user) return false;
  if (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Izanavi
  )
    return !user?.membership?.is_interval;
  const izanaviExtendPackageStatus = getIzanaviExtendPackageStatus(user);
  if (!izanaviExtendPackageStatus) return false;
  return (
    izanaviExtendPackageStatus?.is_subscribing &&
    !izanaviExtendPackageStatus?.is_interval
  );
};

export const getIzanaviStatusText = (user: User) => {
  if (!user) return '';
  if (isCancelingIzanavi(user)) {
    if (
      user?.membership?.membership_package?.package_name ===
      MembershipPackageName.Izanavi
    ) {
      return `残り ${timeUntil(
        new Date(user?.membership?.subscription_end_at),
        Language.Ja
      )}`;
    }
    const izanaviExtendPackageStatus = getIzanaviExtendPackageStatus(user);
    return `残り ${timeUntil(
      new Date(izanaviExtendPackageStatus?.end_at),
      Language.Ja
    )}`;
  }
  if (!isPayingIzanavi(user)) return '';
  if (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Izanavi
  )
    return user?.membership?.is_trialing ? '無料体験中' : '登録中';
  const izanaviExtendPackageStatus = getIzanaviExtendPackageStatus(user);
  return izanaviExtendPackageStatus?.is_trialing ? '無料体験中' : '登録中';
};

// is izanavi check
export const isIzanaviMember = (user: User) => {
  if (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Izanavi
  )
    return true;
  const izanaviExtendPackageStatus = getIzanaviExtendPackageStatus(user);
  return !!izanaviExtendPackageStatus?.is_subscribing;
};

export const isPayingIzanavi = (user: User) => {
  if (
    user?.membership?.membership_package?.package_name ===
    MembershipPackageName.Izanavi
  ) {
    return !!user?.membership?.is_interval;
  }
  const izanaviExtendPackageStatus = getIzanaviExtendPackageStatus(user);
  return !!izanaviExtendPackageStatus?.is_interval;
};

export const isPlatinumMemberWithInterval = (user: User) => {
  return (
    user?.membership?.membership_package?.package_name ===
      MembershipPackageName.Platinum && user?.membership?.is_interval
  );
};

export const canUseOption = (
  user: User,
  canUseLevel: MembershipPackageName
) => {
  if (!isMembershipAccount(user)) return false;
  if (isGreenMember(user)) {
    if (
      [MembershipPackageName.Silver, MembershipPackageName.Gold].includes(
        canUseLevel
      )
    )
      return false;
    return true;
  }
  if (isSilverMember(user)) {
    if ([MembershipPackageName.Gold].includes(canUseLevel)) return false;
    return true;
  }
  if (isGoldMember(user)) return true;
  return false;
};

export const featureContent = (text: string, isHighlight: boolean): string => {
  let start = text.indexOf(`[`);
  let end = text.indexOf(`]`);
  while (start >= 0 && end >= 0) {
    const replaceText = text.substring(start + 1, end);
    text = text.replace(
      `[${replaceText}]`,
      `<span style="color: ${
        isHighlight ? 'yellow' : '#fff'
      }";>${replaceText}</span>`
    );
    start = text.indexOf(`[`);
    end = text.indexOf(`]`);
  }
  return text;
};

export const packageLevel = (packageName: MembershipPackageName) => {
  switch (packageName) {
    case MembershipPackageName.Green:
      return 1;
    case MembershipPackageName.Silver:
      return 2;
    case MembershipPackageName.Gold:
      return 3;
    case MembershipPackageName.Platinum:
      return 4;
    case MembershipPackageName.Izanavi:
      return 5;

    default:
      return 0;
  }
};

export const packageNameByLevel = (level: number) => {
  switch (level) {
    case 1:
      return MembershipPackageName.Green;
    case 2:
      return MembershipPackageName.Silver;
    case 3:
      return MembershipPackageName.Gold;
    case 4:
      return MembershipPackageName.Platinum;
    case 5:
      return MembershipPackageName.Izanavi;
    default:
      return '';
  }
};

export const subButtonTextForCancelPage = (
  currentPackage: MembershipPackageName,
  // nextPackage: MembershipPackageName,
  isSubBefore: boolean,
  isRenew: boolean
) => {
  const currentLevel = packageLevel(currentPackage);
  // const nextLevel = packageLevel(nextPackage);
  if (isRenew) {
    return 'メンバーシップを再開';
  }
  if (currentLevel === 0) {
    return isSubBefore ? 'メンバーシップを再開' : 'メンバーシップを無料体験';
  }
  return 'メンバーシップを変更';
};

export const subButtonText = (
  currentPackage: MembershipPackageName,
  // nextPackage: MembershipPackageName,
  isSubBefore: boolean,
  isRenew: boolean
) => {
  const currentLevel = packageLevel(currentPackage);
  // const nextLevel = packageLevel(nextPackage);
  if (isRenew) {
    return 'メンバーシップを再開';
  }
  if (currentLevel === 0) {
    return isSubBefore ? 'メンバーシップを再開' : 'メンバーシップを無料体験';
  }
  return 'プランを変更する';
};

export const subButtonTextModal = ({
  currentPackage,
  // nextPackage,
  isSubBefore,
  isRenew,
}: {
  currentPackage: MembershipPackage;
  // nextPackage: MembershipPackage;
  isSubBefore: boolean;
  isRenew: boolean;
}) => {
  const currentLevel = packageLevel(currentPackage?.package_name);
  if (isRenew) {
    return 'メンバーシップを再開';
  }
  if (currentLevel === 0) {
    return isSubBefore ? 'メンバーシップを再開' : 'メンバーシップを無料体験';
  }
  return 'ワンクリックで購入する';
};

export const isDownGrade = (
  currentPackage: MembershipPackage,
  nextPackage: MembershipPackage
) => {
  const currentLevel = packageLevel(currentPackage?.package_name);
  if (currentLevel === 0) return false;
  return nextPackage?.web_amount < currentPackage?.web_amount;
};

export const subFromData = (user: User) => {
  if (!user?.membership) return { icon: 'macbook-fill', currency: '円' };
  switch (user.membership?.subscription_payment_type) {
    case 'credit':
      return { icon: 'macbook-fill', currency: '円' };
    case 'web_coin':
      return {
        icon: 'macbook-fill',
        currency: 'PostPrime Coin',
      };
    case 'android_iap':
      return {
        icon: 'android-fill',
        currency: '円',
      };
    case 'android_coin':
      return {
        icon: 'android-fill',
        currency: 'PostPrime Coin',
      };
    case 'ios_iap':
      return { icon: 'apple-fill', currency: '円' };
    default:
      return { icon: 'macbook-fill', currency: '円' };
  }
};

export const packageLabel = (packageName: MembershipPackageName) => {
  switch (packageName) {
    case MembershipPackageName.Green:
      return 'Green';
    case MembershipPackageName.Silver:
      return 'Silver';
    case MembershipPackageName.Gold:
      return 'Gold';
    case MembershipPackageName.Platinum:
      return 'Platinum';
    case MembershipPackageName.Izanavi:
      return 'IZANAVI';
    default:
      return '';
  }
};

export const membershipRecurringLevel = (
  recurring: MembershipPackageRecurring
) => {
  switch (recurring) {
    case MembershipPackageRecurring.Month:
      return 1;
    case MembershipPackageRecurring.HalfYear:
      return 2;
    case MembershipPackageRecurring.Year:
      return 3;
  }
};

export const defaultTopicPeriod = (packageName: MembershipPackageName) => {
  switch (packageName) {
    case MembershipPackageName.Green:
      return TrendingTopicPeriod.MidTerm;
    case MembershipPackageName.Silver:
      return TrendingTopicPeriod.ShortTerm;
    case MembershipPackageName.Gold:
      return TrendingTopicPeriod.ShortTerm;
    default:
      return TrendingTopicPeriod.LongTerm;
  }
};

export const addMembershipClickEvent = ({
  position,
  screenName,
  fromComponent,
}: {
  position:
    | 'green_package'
    | 'silver_package'
    | 'gold_package'
    | 'membership_policy'
    | 'privacy_policy'
    | 'user_terms'
    | 'cancel_membership'
    | 'purchase_with_new_card'
    | 'login_button'
    | 'purchase'
    | MembershipPackageName;
  screenName: ScreenName;
  fromComponent?: EventComponent;
}) => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Membership,
    screen: screenName,
    component: fromComponent || EventComponent.MembershipPaywall,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: position,
        label: 'click_position',
      },
    ],
  });
};

export const getPackageName = (currentUser: User, renewMode: boolean) => {
  if (renewMode)
    return (
      currentUser?.membership?.membership_package?.package_name ||
      MembershipPackageName.Gold
    );
  return MembershipPackageName.Gold;
};

export const isSymbolAlarmNotification = (type: NotificationType) => {
  return [
    NotificationType.VoteDailyHighAlert,
    NotificationType.VoteWeeklyHighAlert,
    NotificationType.VoteMonthlyHighAlert,
    NotificationType.VoteDailyLowAlert,
    NotificationType.VoteWeeklyLowAlert,
    NotificationType.VoteMonthlyLowAlert,
    NotificationType.PostDailyHighAlert,
    NotificationType.PostWeeklyHighAlert,
    NotificationType.PostMonthlyHighAlert,
    NotificationType.PostDailyLowAlert,
    NotificationType.PostWeeklyLowAlert,
    NotificationType.PostMonthlyLowAlert,
    NotificationType.VoteChangeDailyHighAlert,
    NotificationType.VoteChangeDailyLowAlert,
    NotificationType.VoteChangeWeeklyHighAlert,
    NotificationType.VoteChangeWeeklyLowAlert,
    NotificationType.VoteChangeMonthlyHighAlert,
    NotificationType.VoteChangeMonthlyLowAlert,
  ].includes(type);
};

export const membershipPricePackagePeriod = (
  recurring: MembershipPackageRecurring
) => {
  switch (recurring) {
    case MembershipPackageRecurring.Month:
      return '1カ月';
    case MembershipPackageRecurring.HalfYear:
      return '6カ月';
    case MembershipPackageRecurring.Year:
      return '1年';
  }
};

export const freeTrialRangeText = (day: number) => {
  if (!day) return '2週間の無料体験！';
  if (day % 7 === 0) return `${day / 7}週間の無料体験！`;
  return `${day}日間の無料体験${day === 30 ? 'キャンペーン' : '！'}`;
};

export const freeTrialRange = (day: number) => {
  if (!day) return '2週間';
  if (day % 7 === 0) return `${day / 7}週間`;
  return `${day}日間`;
};

export const coinBackByPackage = (
  packageName: MembershipPackageName,
  coinBackEvent: IntroEvent
) => {
  if (!coinBackEvent) return 0;
  switch (packageName) {
    case MembershipPackageName.Green:
      return coinBackEvent.params?.green_membership_coin_back;
    case MembershipPackageName.Silver:
      return coinBackEvent.params?.silver_membership_coin_back;
    case MembershipPackageName.Gold:
      return coinBackEvent.params?.gold_membership_coin_back;
    case MembershipPackageName.Platinum:
      return coinBackEvent.params?.platinum_membership_coin_back;
    default:
      return 0;
  }
};

// check init package of membership paywall
export const isInitMembershipPackage = ({
  packages,
  subbingMembershipPackage,
  initPackageName,
  initRecurring,
}: {
  packages: MembershipPackage[];
  subbingMembershipPackage: MembershipPackage;
  initPackageName: MembershipPackageName;
  initRecurring: MembershipPackageRecurring;
}) => {
  let currentPackageName =
    initPackageName ||
    subbingMembershipPackage?.package_name ||
    MembershipPackageName.Platinum;
  const currentPackageRecurring =
    currentPackageName === MembershipPackageName.Izanavi
      ? MembershipPackageRecurring.Month
      : initRecurring ||
        subbingMembershipPackage?.recurring ||
        MembershipPackageRecurring.Year;
  currentPackageName =
    currentPackageName === MembershipPackageName.Izanavi
      ? MembershipPackageName.Platinum
      : currentPackageName;
  const pack =
    packages.find(
      (p) =>
        p.package_name === currentPackageName &&
        p.recurring === currentPackageRecurring
    ) ||
    packages.find(
      (p) =>
        p.package_name === MembershipPackageName.Platinum &&
        p.recurring === MembershipPackageRecurring.Month
    );
  return pack;
};

// next package is downgrading package
export const isChoosingNextMembershipPackage = ({
  user,
  currentPackage,
}: {
  user: User;
  currentPackage: MembershipPackage;
}) => {
  if (!user || !currentPackage) return false;
  const nextPackage = user?.membership?.next_membership_package;
  if (!nextPackage) return false;
  return nextPackage?.id === currentPackage?.id;
};

export const checkIsChoosingSubbingMembershipPackage = ({
  subbingPackage,
  checkingPackage,
}: {
  subbingPackage: MembershipPackage;
  checkingPackage: MembershipPackage;
}) => {
  if (!subbingPackage || !checkingPackage) return false;
  if (subbingPackage?.id === checkingPackage?.id) return true;
  if (subbingPackage.package_name === MembershipPackageName.Izanavi) {
    return (
      checkingPackage.package_name === MembershipPackageName.Platinum &&
      checkingPackage.recurring === MembershipPackageRecurring.Month
    );
  }
};

export const checkIsChoosingSubbingMembershipPackageWithNameOnly = ({
  subbingPackage,
  checkingPackage,
}: {
  subbingPackage: MembershipPackage;
  checkingPackage: MembershipPackage;
}) => {
  if (!subbingPackage || !checkingPackage) return false;
  if (subbingPackage?.package_name === checkingPackage?.package_name)
    return true;
  if (subbingPackage.package_name === MembershipPackageName.Izanavi) {
    return checkingPackage.package_name === MembershipPackageName.Platinum;
  }
};

export const checkIsChoosingNotSupportPackage = ({
  currentUser,
  choosingPackage,
  izanaviPackage,
  isChoosingIzanaviPackage,
}: {
  currentUser: User;
  choosingPackage: MembershipPackage;
  izanaviPackage: MembershipExtendedPackage;
  isChoosingIzanaviPackage: boolean;
}) => {
  const isIzanaviSubbing = isIzanaviMember(currentUser);
  if (isIzanaviSubbing) {
    return (
      !izanaviPackage ||
      packageLevel(choosingPackage?.package_name) <
        packageLevel(MembershipPackageName.Platinum)
    );
  } else {
    const isDown = isDownGrade(
      currentUser?.membership?.membership_package,
      choosingPackage
    );
    return (
      isChoosingIzanaviPackage &&
      !!izanaviPackage &&
      isDown &&
      packageLevel(currentUser?.membership?.membership_package?.package_name) <
        packageLevel(MembershipPackageName.Platinum)
    );
  }
};

export const checkAbleToShowMembershipStatus = ({
  currentUser,
  choosingPackage,
}: {
  currentUser: User;
  choosingPackage: MembershipPackage;
}) => {
  const isChoosingCurrentPackage = checkIsChoosingSubbingMembershipPackage({
    subbingPackage: currentUser?.membership?.membership_package,
    checkingPackage: choosingPackage,
  });
  if (!isChoosingCurrentPackage) return false;
  const isIzanaviSubbing = isIzanaviMember(currentUser);
  const isSubbingMembership = !!currentUser?.membership?.membership_package;
  return isIzanaviSubbing || isSubbingMembership;
};

export const paymentMembershipPrice = (pack: MembershipPackage) => {
  if (
    pack.discounted_web_amount > 0 &&
    pack.web_amount > pack.discounted_web_amount
  ) {
    return pack.discounted_web_amount;
  }
  return pack.web_amount;
};
