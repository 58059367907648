import Dialog from '@material-ui/core/Dialog';

import styles from './ConfirmDialog.module.scss';

export type Props = {
  open: boolean;
  actionCloseFnc?: () => void;
  actionSelectedFnc?: () => void;
  backgroudColor?: string;
  title: string;
  noText?: string;
  yesText?: string;
  withTextBox?: boolean;
  textBox?: JSX.Element;
  blackYesText?: boolean;
  enableClickOutsideToClose?: boolean;
};

export default function ConfirmDialog({
  open = false,
  actionCloseFnc,
  actionSelectedFnc,
  backgroudColor = 'transparent',
  title,
  noText = 'やめる',
  yesText = '削除する',
  withTextBox = false,
  textBox = undefined,
  blackYesText = false,
  enableClickOutsideToClose = true,
}: Props): JSX.Element {
  return (
    <Dialog
      open={open}
      onClose={enableClickOutsideToClose ? actionCloseFnc : undefined}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: backgroudColor,
          boxShadow: 'none',
        },
      }}
    >
      {withTextBox ? (
        <div className={styles.dialogWrapper}>
          <div className={styles.header}>{title}</div>
          <div className={styles.textBox}>{textBox}</div>
        </div>
      ) : (
        <div className={styles.dialogWrapper}>
          <div
            className={styles.header}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <div className={styles.buttons}>
            <div className={styles.button} onClick={actionCloseFnc}>
              {noText}
            </div>
            <div
              className={styles.button}
              style={{
                color: blackYesText ? '#000' : '#FA553F',
                fontWeight: blackYesText ? 'unset' : 600,
              }}
              onClick={actionSelectedFnc}
            >
              {yesText}
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
}
