import { PaymentMethod } from '@stripe/stripe-js';
import axios from 'axios';
import {
  PriceResponseResult,
  TippedListResponseResult,
  TippingPackagesResponseResult,
  TippedListPageResponseResult,
  PriceListResponseResult,
  GetChargesResult,
  GetCoinChargeResult,
  MembershipPackage,
  GetReasonsListResult,
  MembershipPackageRecurring,
  User,
} from 'common/interfaces/api';
import {
  SetupIntentsResult,
  SubscriptionResult,
} from 'common/interfaces/stripe';
import { PER_PAGE, YEAR_END_2024_EVENT } from 'common/utils';

export const getPrices = async (webPrice: number) => {
  return (
    await axios.get(`/payments/platform-prices?price=${Math.max(webPrice, 0)}`)
  )?.data as PriceResponseResult;
};

export const getPricesList = async () => {
  return (await axios.get(`/payments/price-selections`))
    ?.data as PriceListResponseResult;
};

export const getTippingPackages = async (isStreaming?: boolean) => {
  const typeParam = isStreaming ? '?type=livestream' : '';
  return (await axios.get(`/payments/tips/packages${typeParam}`))
    ?.data as TippingPackagesResponseResult;
};

export enum WebPricePackageRecurring {
  Month = 'month',
  HalfYear = 'half_year',
  Year = 'year',
}

export type WebPricePackage = {
  default_discounted_percentages: number;
  discounted_web_amount: number;
  original_web_amount: number;
  campaign_discounted_web_amount: number;
  recurring: WebPricePackageRecurring;
  default_discounted_web_amount: number;
  membership_discount_percentages: number;
};

export const pricePackagePeriod = (recurring: WebPricePackageRecurring) => {
  switch (recurring) {
    case WebPricePackageRecurring.Month:
      return '1カ月';
    case WebPricePackageRecurring.HalfYear:
      return '6カ月';
    case WebPricePackageRecurring.Year:
      return '1年';
  }
};

export const payPeriodLabel = (recurring: WebPricePackageRecurring) => {
  switch (recurring) {
    case WebPricePackageRecurring.Month:
      return '月';
    case WebPricePackageRecurring.HalfYear:
      return '6カ月';
    case WebPricePackageRecurring.Year:
      return '年';
  }
};

export interface WebDiscount {
  type: 'percentage';
  discount: number;
  recurring: WebPricePackageRecurring;
  start: string;
  end: string;
}

export const checkResub = async (creatorId: number) => {
  return (
    await axios.post('/payments/check-subscription/v2', {
      user_id: creatorId,
    })
  )?.data as {
    data: {
      is_subscribed: boolean;
      discounts: WebDiscount[];
      original_web_amount?: number;
      web_prices: WebPricePackage[];
      discount_by_membership_web_amount?: number;
      benefits?: {
        prime_benefit: string | null;
        prime_plus_benefit: string | null;
      };
    };
  };
};

export const tipToPost = async ({
  postId,
  amount,
  paymentBy,
  isStreaming = false,
}: {
  postId: number;
  amount: number;
  paymentBy: 'coin' | 'credit';
  isStreaming?: boolean;
}) => {
  const typeParam = isStreaming ? '?type=livestream' : '';
  const url =
    paymentBy === 'credit'
      ? `/payments/tips/web${typeParam}`
      : `/payments/tips/coin${typeParam}`;
  return await axios.post(url, {
    amount: amount,
    post_id: postId,
  });
};

export const getTippedOfPost = async (postId: number, page: number) => {
  return (await axios.get(`/payments/tips/posts/${postId}?page=${page}`))
    ?.data as TippedListResponseResult;
};

export const getTippedListPage = async (
  page: number,
  type: 'send' | 'receive'
) => {
  return (await axios.get(`/payments/tips/histories?type=${type}&page=${page}`))
    ?.data as TippedListPageResponseResult;
};

export const createSubscription = async ({
  paymentBy,
  userId,
  paymentMethodId,
  recurring,
}: {
  paymentBy: 'credit' | 'coin';
  userId: number;
  paymentMethodId?: string;
  recurring?: WebPricePackageRecurring | null;
}) => {
  const url =
    paymentBy === 'credit'
      ? `/payments/create-subscription`
      : `/payments/create-coin-subscription`;
  const body = {
    user_id: userId,
    ...(recurring ? { recurring: recurring } : {}),
    ...(paymentBy === 'credit' && paymentMethodId
      ? { payment_method_id: paymentMethodId }
      : {}),
  };
  return (await axios.post(url, body)).data as SubscriptionResult;
};

export const getChargeHistories = async (lastId?: string) => {
  const lastIdParam = lastId ? `?last_id=${lastId || ''}` : '';
  return (await axios.get(`/users/accounts/charges${lastIdParam}`))
    .data as GetChargesResult;
};

export const getChargeCoinHistories = async (page: number) => {
  return (
    await axios.get(
      `/payments/usage-coin-histories?page=${page.toString()}&limit=${PER_PAGE}`
    )
  ).data as GetCoinChargeResult;
};

export const checkResubMembership = async () => {
  return (await axios.get('/payments/check-membership-subscription')).data as {
    data: boolean;
  };
};

export const createSubscriptionMembership = async ({
  paymentBy,
  packageId,
}: {
  paymentBy: 'credit' | 'coin';
  packageId: number;
}) => {
  const url =
    paymentBy === 'credit'
      ? `/payments/membership/credit`
      : `/payments/membership/coin`;
  return await axios.post(url, { package_id: packageId });
};

export const getMembershipPackages = async () => {
  return (
    await axios.get(
      '/payments/memberships/packages?recurring=month&recurring=half_year&recurring=year'
    )
  ).data as {
    data: MembershipPackage[];
  };
};

export const cancelSubscriptionMembership = async () => {
  return await axios.post('/payments/membership/cancel');
};

export const cancelSubscriptionIzanavi = async () => {
  return await axios.post('/payments/expand-membership/cancel', {});
};

export const getCancelSubMembershipReason = async () => {
  return (await axios.get('/payments/membership/cancel-reasons'))
    ?.data as GetReasonsListResult;
};

export const getCancelPrimeSubReasons = async () => {
  return (await axios.get('/payments/cancel-reasons'))
    ?.data as GetReasonsListResult;
};

export const handleSubmitLongSub = async () => {
  return await axios.post('/users/long-subscription-setting');
};

export const handleSubmitDiscountCampaign = async (attend: boolean) => {
  return await axios.post('/users/campaigns', {
    campaign: YEAR_END_2024_EVENT,
    attend: attend,
  });
};

export const getRefundInfo = async (creatorId: number) => {
  return (await axios.get(`/payments/refund-payment?creator_id=${creatorId}`))
    ?.data as {
    data: {
      amount: number;
      charged_date: string;
      manual_payback_require: number;
    };
  };
};

export const callRefund = async (creatorId: number) => {
  return await axios.post(`/payments/refund-payment`, {
    creator_id: creatorId,
  });
};

export const checkPaymentHistoryTeenage = async (data: {
  membership_package_id?: number;
  creator_user_id?: number;
  price_amount: number;
  recurring?: MembershipPackageRecurring | WebPricePackageRecurring;
  price_amount_plus?: number;
  expand_membership_amount?: number;
}) => {
  const keys = Object.keys(data) || [];
  let paramsStr = '';
  if (keys.length > 0) {
    paramsStr =
      '?' +
      Object.keys(data)
        .map((key) => `${key}=${String(data[key])}`)
        .join('&');
  }
  return (await axios.get(`/payments/payment-history-teenage-user${paramsStr}`))
    ?.data as {
    data: {
      is_purchasable: boolean;
      reason: number;
    };
  };
};

export const subExpandPackageByCredit = async ({
  packageId,
  paymentBy,
}: {
  packageId: number;
  paymentBy: 'credit' | 'coin';
}) => {
  if (paymentBy === 'coin') return Promise.resolve();
  return await axios.post(`/payments/expand-membership/credit`, {
    package_id: packageId,
  });
};

export const sendMembershipCancelReasons = async (reasons: number[]) => {
  return await axios.post(`/payments/membership/cancel-reasons`, {
    reasons: reasons,
  });
};

export const sendIzanaviCancelReasons = async (reasons: number[]) => {
  return await axios.post(`/payments/membership/cancel-reasons`, {
    reasons: reasons,
    is_expand: true,
  });
};

export const sendPrimeSubCancelReasons = async (
  reasons: number[],
  userId: number
) => {
  return await axios.post(`/payments/cancel-reasons`, {
    reasons: reasons,
    creator_id: userId,
  });
};

export const cancelPrimeSubscription = async (userId: number) => {
  return await axios.post(`/payments/cancel-subscription`, {
    user_id: userId,
  });
};

export const cancelPrimePlusSubscription = async (userId: number) => {
  return await axios.post(`/payments/cancel-option-subscription`, {
    user_id: userId,
  });
};

export const sendPrimePlusSubCancelReasons = async (
  reasons: number[],
  userId: number
) => {
  return await axios.post(`/payments/cancel-plus-reasons`, {
    reasons: reasons,
    creator_id: userId,
  });
};

export enum OptionalPackageName {
  Plus = 'plus',
}

export interface OptionalPackage {
  option_type: OptionalPackageName;
  unit_amount: number;
}

export const subPrimePlus = async (userId: number) => {
  return await axios.post(`/payments/create-option-subscriptions`, {
    user_id: userId,
    option_type: 'plus',
  });
};

export const getPlusPackage = (creator: User) => {
  return creator?.option_subscription_prices?.find(
    (price) => price.option_type === OptionalPackageName.Plus
  );
};

export interface OptionalPackageStatus {
  ended_at: string;
  is_subscribed_before: boolean;
  optional_type: OptionalPackageName;
  platform:
    | 'credit'
    | 'ios_iap'
    | 'android_iap'
    | 'web_coin'
    | 'android_coin'
    | 'ios_coin';
  status: 'active' | 'trialing' | 'canceled';
}

export const updatePrimePlusPrice = async (amount: number) => {
  return axios.post(`/payments/enable-plus-subscription-setting`, {
    amount: amount,
  });
};

export const getInitCreditIntent = async () => {
  return (await axios.post('/payments/setup-intents', {}))
    ?.data as SetupIntentsResult;
};

export const setPaymentMethod = async (
  paymentMethodId: string | PaymentMethod
) => {
  await axios.post('/payments/payment-methods', {
    payment_method_id: paymentMethodId,
  });
};
