import React, { Component, ErrorInfo, ReactNode } from 'react';
import Icon from 'components/common/Icon';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import styles from './ErrorBoundary.module.scss';
import getConfig from 'next/config';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class CustomErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles.errorWrapper}>
          <div className={styles.icon}>
            <Icon name="empty" width={72} height={72} />
          </div>
          <p className={styles.text}>エラーが発生しました。</p>
        </div>
      );
    }

    return this.props.children;
  }
}

let ErrorBoundary;
try {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { publicRuntimeConfig } = getConfig();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!Bugsnag._client) {
    Bugsnag.start({
      apiKey: process.env.NEXT_PUBLIC_BUG_SNAG_KEY,
      plugins: [new BugsnagPluginReact()],
      enabledReleaseStages: ['production', 'staging'],
      releaseStage: process.env.NEXT_PUBLIC_ENVIRONMENT,
      // eslint-disable-next-line
      appVersion: publicRuntimeConfig.version,
    });
  }

  if (typeof Bugsnag.getPlugin('react') === 'undefined') {
    ErrorBoundary = CustomErrorBoundary;
  } else {
    ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  }
} catch (error) {
  ErrorBoundary = CustomErrorBoundary;
}

export default ErrorBoundary;
