import Button from 'components/common/Button';
import styles from './MembershipPRTextSection.module.scss';
import useGlobalStore from 'store/global';
import { ScreenName } from 'common/utils/pp_tracking';
import { MembershipPackageName } from 'common/interfaces/api';
import { isPlatinumMember } from 'common/utils/membership';
import useStore from 'store/timeline';
import { track } from '@amplitude/analytics-browser';

const MembershipPRTextSection = () => {
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const currentUser = useStore((state) => state.currentUser);
  if (!currentUser || isPlatinumMember(currentUser)) return null;
  return (
    <div className={styles.membershipPRTextSection}>
      <div className={styles.title}>メンバーシップについて</div>
      <div className={styles.description}>
        メンバーシップに登録すると、価格の動きを予測して、通知で良いチャンスを教えてくれる投票率の機能を使うことができます。
      </div>
      <div className={styles.actionBtn}>
        <Button
          text="メンバーシップを確認する"
          rounded
          onClick={() => {
            track('Click Membership PR Button');
            setShowMembershipPayDialog({
              open: true,
              screen: ScreenName.SideBarRight,
              initPackage: MembershipPackageName.Platinum,
            });
          }}
        />
      </div>
    </div>
  );
};

export default MembershipPRTextSection;
