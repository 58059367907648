import { FormEventHandler, useMemo } from 'react';
import { useRef, useState } from 'react';
import { useOutsideClick } from 'common/utils';
import styles from './GlobalSearchInput.module.scss';
import Icon from 'components/common/Icon';
import { useQuery, useMutation } from 'react-query';
import { useRouter } from 'next/router';
import useGlobalStore from 'store/global';
import { track } from '@amplitude/analytics-browser';
import { deleteRecentKeyword, getSuggestKeywords } from 'service/user';
import debounce from 'common/utils/debounce';
const GlobalSearchInput = () => {
  const [keyword, setKeyword] = useState('');
  const [show, setShow] = useState(false);
  const router = useRouter();
  const [recentKeywords, setRecentKeywords] = useState<string[]>([]);
  const [trendingKeywords, setTrendingKeywords] = useState<string[]>([]);
  const [suggestKeywords, setSuggestKeywords] = useState<string[]>([]);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const dropdownRef = useRef<HTMLInputElement>(null);
  useOutsideClick(dropdownRef, () => {
    if (show) setShow(false);
  });

  const { refetch } = useQuery(
    ['getSuggestKeywords', currentAuth?.uid, show],
    async () => {
      if (!currentAuth || !show) return Promise.reject();
      return getSuggestKeywords();
    },
    {
      onSuccess: (data) => {
        const rckeywords = data?.data?.recent_keywords || [];
        setRecentKeywords(rckeywords);
        const tdKeywords = data?.data?.trending_keywords || [];
        setTrendingKeywords(tdKeywords);
      },
      enabled: !!currentAuth && show,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: checkSuggestKw } = useMutation(
    (kw: string) => {
      return getSuggestKeywords(kw);
    },
    {
      onSuccess: (data) => {
        const suggestKeywords = data?.data?.suggest_keywords || [];
        setSuggestKeywords(suggestKeywords);
      },
    }
  );

  const { mutate: deleteKeyword } = useMutation(
    async (kw: string) => {
      return await deleteRecentKeyword(kw);
    },
    {
      onSuccess: () => {
        void refetch();
      },
    }
  );

  const showingRecentKeywords = useMemo(() => {
    if (!keyword) return recentKeywords;
    return recentKeywords.filter(
      (kw) =>
        kw.toLowerCase().includes(keyword.toLowerCase()) || kw.includes(keyword)
    );
  }, [keyword, recentKeywords]);

  const showingTrendingKeywords = useMemo(() => {
    if (!keyword) return trendingKeywords;
    return trendingKeywords.filter(
      (kw) =>
        kw.toLowerCase().includes(keyword.toLowerCase()) || kw.includes(keyword)
    );
  }, [keyword, trendingKeywords]);

  const searchWithKeyword = async (keyword: string) => {
    if (!keyword || !keyword.trim()) return;
    if (keyword) {
      track('Complete Search', { search_keyword: keyword });
    }
    await router.push(`/search?keyword=${encodeURIComponent(keyword.trim())}`);
    setShow(false);
    setTimeout(() => {
      void refetch();
    }, 1000);
  };

  const handleSearch: FormEventHandler<HTMLFormElement> = (event) => {
    // if (e.key !== 'Enter') return;
    event.preventDefault();
    if (!currentAuth) {
      showLogin({});
      return;
    }
    void searchWithKeyword(keyword);
  };

  const debouncedCheckSuggestKw = useMemo(
    () => debounce(checkSuggestKw, 500),
    [checkSuggestKw]
  );

  const isShowAll =
    show &&
    (showingRecentKeywords.length > 0 ||
      showingTrendingKeywords.length > 0 ||
      suggestKeywords.length > 0);
  return (
    <div className={styles.searchInputWrapper} ref={dropdownRef}>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          className={styles.input}
          placeholder="検索する"
          value={keyword}
          onFocus={() => {
            track('Start Search');
          }}
          onClick={() => setShow(true)}
          onChange={(e) => {
            setShow(true);
            setKeyword(e.target.value);
            if (e.target.value) {
              debouncedCheckSuggestKw(e.target.value);
            }
          }}
        />
      </form>
      {/* {keyword && keyword.length > 0 && (
        <div
          className={styles.clearBtn}
          onClick={() => {
            setKeyword('');
            setShow(false);
          }}
        >
          <Icon name="clear-search-bar" width={16} height={16} />
        </div>
      )} */}
      <div
        className={styles.contentWrapper}
        style={{
          display: isShowAll ? 'block' : 'none',
        }}
      >
        {!keyword && showingRecentKeywords && (
          <span className={styles.label}>最近の検索</span>
        )}
        <ul className={styles.menuWrapper}>
          {showingRecentKeywords.slice(0, 5).map((kw, index) => {
            return (
              <li key={`choose-recent-keyword-${index}`}>
                <span className={styles.labelIcon}>
                  <Icon name="search-recent" width={24} height={24} />
                </span>
                <span
                  className={styles.name}
                  onClick={() => {
                    setKeyword(kw);
                    void searchWithKeyword(kw);
                  }}
                >
                  {kw}
                </span>
                <span
                  className={styles.delete}
                  onClick={() => {
                    deleteKeyword(kw);
                  }}
                >
                  <Icon name="close-black" width={9} height={9} />
                </span>
              </li>
            );
          })}
        </ul>
        {keyword && (
          <ul className={styles.menuWrapper}>
            {suggestKeywords.map((kw, index) => {
              return (
                <li key={`choose-suggest-keyword-${index}`}>
                  <span className={styles.labelIcon}>
                    <Icon name="search" width={20} height={20} />
                  </span>
                  <span
                    className={styles.name}
                    onClick={() => {
                      setKeyword(kw);
                      void searchWithKeyword(kw);
                    }}
                  >
                    {kw}
                  </span>
                </li>
              );
            })}
          </ul>
        )}
        {!keyword && showingTrendingKeywords.length > 0 && (
          <span className={styles.label}>急上昇ワード</span>
        )}
        <ul className={styles.menuWrapper}>
          {showingTrendingKeywords.map((kw, index) => {
            return (
              <li key={`choose-trending-keyword-${index}`}>
                <span className={styles.labelIcon}>
                  <Icon name="search-trend" width={24} height={24} />
                </span>
                <span
                  className={styles.name}
                  onClick={() => {
                    setKeyword(kw);
                    void searchWithKeyword(kw);
                  }}
                >
                  {kw}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default GlobalSearchInput;
