import Bugsnag from '@bugsnag/js';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import usePostReactionStore from 'store/post_reaction';

export type PreviousPathInfo = {
  scrollPosition: number;
  expires: number;
};

const unSupportPathNames = [
  '/[username]/posts/[postId]',
  '/courses/[courseId]',
  '/chart',
];

const havingTopBannersPathNames = [
  '/',
  '/post_topics/LIVE',
  '/post_topics/[topic]',
  '/prime_posts',
  '/topics/[topic]',
  '/chart',
];

const useScrollRestoration = () => {
  const router = useRouter();
  const previousPathsRef = useRef<{ [key: string]: PreviousPathInfo }>({});
  const lastTenPathNamesRef = useRef<string[]>([]);
  const deleteExpiredReactionInfo = usePostReactionStore(
    (state) => state.deleteExpiredReactionInfo
  );
  // Save the scroll position before the route changes
  useEffect(() => {
    const handleRouteChangeStart = () => {
      try {
        let asPath = router.asPath;
        if (unSupportPathNames.includes(router.pathname)) {
          return;
        }
        if (router.pathname === '/') {
          asPath = '/';
        }
        let minusTopScroll = 0;
        if (havingTopBannersPathNames.includes(router.pathname)) {
          minusTopScroll = 90;
        }
        previousPathsRef.current[asPath] = {
          scrollPosition: Math.max(window.scrollY - minusTopScroll, 0),
          expires: Date.now() + 1000 * 60 * 15, // 15 minutes
        };
        if (lastTenPathNamesRef.current.length >= 10) {
          lastTenPathNamesRef.current = lastTenPathNamesRef.current
            .slice(lastTenPathNamesRef.current.length - 9)
            .concat(asPath);
        }
      } catch (error) {
        Bugsnag.notify(error);
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router]);

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        for (const key in previousPathsRef.current) {
          if (
            !lastTenPathNamesRef.current.includes(key) ||
            previousPathsRef.current[key].expires < Date.now()
          ) {
            delete previousPathsRef.current[key];
          }
        }
        deleteExpiredReactionInfo();
      } catch (error) {
        Bugsnag.notify(error);
      }
    }, 1000 * 60);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Restore the scroll position after navigating back
  useEffect(() => {
    let interval;
    if (previousPathsRef.current) {
      try {
        let asPath = router.asPath;
        if (router.pathname === '/') {
          asPath = '/';
        }
        const { scrollPosition, expires } =
          previousPathsRef.current[asPath] || {};
        if (!expires || expires < Date.now()) {
          return;
        }
        document.documentElement.style.scrollBehavior = 'auto';
        if (scrollPosition > 0) {
          window.scrollTo(0, Number(scrollPosition || 0));
          // delete all scroll positions not in last 10 paths
          interval = setTimeout(() => {
            document.documentElement.style.scrollBehavior = '';
          }, 0); // Set a short timeout to restore the original behavior
        }
      } catch (error) {
        Bugsnag.notify(error);
      }
    }
    return () => {
      interval && clearTimeout(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);
};

export default useScrollRestoration;
