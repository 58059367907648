import axios from 'axios';
import { SymbolChart } from 'common/interfaces/api';

export enum AnimeSettingScreen {
  Main = 'Main',
  AlarmsList = 'AlarmList',
  AlarmEdit = 'AlarmEdit',
  AlarmCreate = 'AlarmCreate',
  Character = 'Character',
}

export interface SuggestedQuestionType {
  id: number;
  symbol_id: number;
  text: string;
}

export interface AlarmSymbolType extends SymbolChart {
  min_membership_level: number;
  default: boolean;
  trading_economics_symbol_code: string | null;
  trading_economics_symbol_name: string | null;
}

export interface AlarmType {
  id: number;
  loops: string;
  snooze_times: number;
  sound: number;
  symbol: AlarmSymbolType;
  time: string;
  active: boolean;
}
export interface MotionType {
  end: number;
  motion: string;
  start: number;
}

export interface ExpressionType {
  end: number;
  start: number;
  text: string;
  value: string;
}

export interface SpeecheType {
  audio: string;
  expressions: ExpressionType[];
  id: number;
  text: string;
}

export enum CharacterGender {
  Female = 'female',
  Male = 'male',
}

export interface UserCharacter {
  gender: CharacterGender;
  id: number;
  name: string;
}

export enum CharacterStageId {
  Egg = 1,
  Baby = 2,
  Teen = 3,
  Adult = 4,
}
export interface AlarmDetailsType {
  movement: {
    audio_total_time: number;
    end_audio_at: number;
    motions: MotionType[];
    start_audio_at: number;
    total_time: number;
  };
  speeches: SpeecheType[];
  user_character: {
    character: UserCharacter;
    stage: {
      id: CharacterStageId;
      name: string;
    };
  };
}

export const getAlarmInfo = async (alarmId: number) => {
  return (await axios.get(`/anime/alarms/${alarmId}`)).data as {
    data: AlarmDetailsType;
  };
};

export const getAllAlarms = async () => {
  return (await axios.get(`/anime/alarms`)).data as {
    data: {
      alarms: AlarmType[];
    };
  };
};

export const doneOnboardingAnime = async () => {
  return await axios.post(`/users/on_boarding`, {
    flags: {
      anime_character_onboarding: true,
    },
  });
};

export const doneAnimeIntroduction = async () => {
  return await axios.post(`/users/on_boarding`, {
    flags: {
      anime_character_introduction: true,
    },
  });
};

export const getAnimeSymbols = async () => {
  return (await axios.get(`/anime/symbols`))?.data as {
    data: { symbols: AlarmSymbolType[] };
  };
};

// export const getCharacterInfo = async () => {
//   return await axios.get(`/anime/characters`);
// };

export const updateCharacterGender = async (
  gender: 'male' | 'female' | 'random'
) => {
  return await axios.post(`/anime/characters`, {
    gender: gender,
  });
};

export const deleteAnimeAlarm = async (id: number) => {
  return await axios.delete(`/anime/alarms/${id}`);
};

export const createAnimeAlarm = async ({
  time,
  snooze_times,
  loops,
  sound,
  symbol_id,
}: {
  time: string;
  snooze_times: number;
  loops: string | null;
  sound: number;
  symbol_id: number;
}) => {
  return await axios.post(`/anime/alarms`, {
    time,
    snooze_times,
    loops,
    sound,
    symbol_id,
  });
};

export const updateAnimeAlarm = async ({
  id,
  time,
  snooze_times,
  loops,
  sound,
  symbol_id,
  active,
}: {
  id: number;
  time: string;
  snooze_times: number;
  loops: string | null;
  sound: number;
  symbol_id: number;
  active: boolean;
}) => {
  return await axios.put(`/anime/alarms/${id}`, {
    time,
    snooze_times,
    loops,
    sound,
    symbol_id,
    active,
  });
};

export const getChatBotSuggestions = async () => {
  return (await axios.get(`/anime/asks/suggestions`))?.data as {
    data: { suggestions: SuggestedQuestionType[] };
  };
};

export interface AskBotParamsType {
  content: string;
  session_id?: string;
  defined_question?: {
    id: number;
    symbol_id: number;
  };
  disabledAddMessage?: boolean;
}

export enum RedirectScreen {
  MarketPage = 'market_page',
  SymbolDetailPage = 'symbol_detail_page',
  ScoreLevelPage = 'score_level_page',
  VoteRankingPage = 'voting_ranking_page',
}

export const askChatBot = async ({
  content,
  defined_question = null,
  session_id,
}: AskBotParamsType) => {
  return (
    await axios.post(`/anime/asks`, {
      content: content || '',
      ...(defined_question && { defined_question }),
      ...(session_id && { session_id }),
    })
  )?.data as {
    data: {
      audios: string | null;
      id: number;
      predefined_audio: string;
      redirect_screen: RedirectScreen | null;
      suggested_questions: SuggestedQuestionType[];
      symbol: SymbolChart;
      text: string;
      session_id: string;
      promotion_banner: ChatBotMessagePromotionBannerType | null;
      mood: Mood;
    };
  };
};

export enum PromotionBannerType {
  IntroduceIzanavi = 'introduce_izanavi',
}
export interface ChatBotMessagePromotionBannerType {
  banner_type: PromotionBannerType;
  banner_url: string;
}

export interface ChatBotMessageType {
  text: string;
  type: 'bot' | 'user';
  redirect_screen?: RedirectScreen | null;
  symbol?: SymbolChart | null;
  created_at: string;
  promotion_banner?: ChatBotMessagePromotionBannerType | null;
}

export enum Mood {
  Excited = 'excited',
  Happy = 'happy',
  Chill = 'chill',
  Sad = 'sad',
  Sleepy = 'sleepy',
  Normal = 'normal',
}

export const getCharacterMood = async () => {
  return (await axios.get(`/anime/mood`))?.data as {
    data: {
      mood: Mood;
      text: string;
      answer: string | null;
      suggested_questions: SuggestedQuestionType[];
      session_id: string;
    };
  };
};
