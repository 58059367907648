import { HtmlPortalNode } from 'react-reverse-portal';
import create, { SetState } from 'zustand';
import { User as FirebaseUser } from 'firebase/auth';
import {
  User,
  Post,
  MembershipPackageName,
  PostType,
  MembershipPackageRecurring,
  PostSource,
  UserPopup,
} from 'common/interfaces/api';
import { EventComponent, ScreenName } from 'common/utils/pp_tracking';
import { WebPricePackageRecurring } from 'service/payment';
import { ChartSymbolAlertData } from 'service/chart';
import { AnimeSettingScreen } from 'service/anime';
import { CoinDetails } from 'service/user';

export type AudioPlayerInfo = {
  id: number;
  url: string;
  trackTitle: string;
  postAuthor: User;
  removed: boolean;
  parent: string;
  spectrum: number[];
  duration: number;
  thumbnailUrl: string;
  canSeeFreeMinutes: boolean;
  freeMinutes: number;
  freeUrl: string;
  isLocked: boolean;
  isPostDetail: boolean;
  postType: PostType;
  postSource: PostSource;
};

export type SnackbarMessage = {
  text: string;
  type: 'error' | 'warning' | 'info' | 'success';
  disableAutoHide?: boolean;
  autoHideDuration?: number;
};

export type ShowLoginInput = {
  authorName?: string;
  redirectUrl?: string;
  showingTitle?: 'login' | 'signup';
};

export type ShowPaymentDialogInput = {
  creator: User;
  initPackageRecurring?: WebPricePackageRecurring;
  fromComponent?: EventComponent;
};

export type ShowCreatePostDialogInput = {
  post?: Post;
  setLocalPost?: (post: Post) => void;
  editMode?: boolean;
  open: boolean;
};

export type ShowAddCreditCardDialogInput = {
  refetchFunc: () => void;
};

export type ShowTippingDialogInput = {
  post: Post;
  isStreaming?: boolean;
};

export type ShowRecommendCreatorInput = {
  open: boolean;
  limit?: boolean;
  fromMission?: boolean;
  fromCancelPage?: boolean;
};

export type ShowMembershipPayDialogInput = {
  open: boolean;
  showMembershipStatus?: boolean;
  initPackage?: MembershipPackageName;
  initRecurring?: MembershipPackageRecurring;
  screen: ScreenName;
};

export type ShowSymbolAlertSettingDialogInput = {
  open: boolean;
  symbolId?: number;
  isGlobal?: boolean;
};

export type ShowAnimeSettingDialogInput = {
  open: boolean;
  defaultScreen?: AnimeSettingScreen;
};

type GlobalStore = {
  isAuthInit: boolean;
  firebaseUser: FirebaseUser;
  currentAuth: FirebaseUser;
  redirectUrl: string;
  requestToken: string;
  audioPlayers: {
    [key: string]: AudioPlayerInfo;
  };
  portalNodes: {
    [key: string]: HtmlPortalNode;
  };
  globalPlayer: AudioPlayerInfo;
  snackbarMessage: SnackbarMessage;
  showLoginInput: ShowLoginInput;
  isShowingRemainingCoinDialog: boolean;
  showCreatePostDialogInput: ShowCreatePostDialogInput;
  showCreatePostDialogInputMobile: ShowCreatePostDialogInput;
  showPaymentDialogInput: ShowPaymentDialogInput;
  showTippingDialogInput: ShowTippingDialogInput;
  userClickedAnimeIcon: boolean;
  showSymbolAlertSettingDialogInput: ShowSymbolAlertSettingDialogInput;
  showingCoinSummaryDialog: boolean;
  showingInputProfileDialog: boolean;
  showingDiscountCreatorsDialog: boolean;
  showingMembershipStatusDialog: boolean;
  showRecommendCreatorInput: ShowRecommendCreatorInput;
  showMembershipPayDialogInput: ShowMembershipPayDialogInput;
  showingIzanaviPRDialog: boolean;
  showInputProfileDialog: (show: boolean) => void;
  showCoinSummaryDialog: (show: boolean) => void;
  showInviteFriendDialog: (show: boolean) => void;
  showProfileHelpDialog: (show: boolean) => void;
  showDiscountCreatorsDialog: (show: boolean) => void;
  showMembershipStatusDialog: (show: boolean) => void;
  showIzanaviPRDialog: (show: boolean) => void;
  showingProfileHelpDialog: boolean;
  showingInputReferralCodeDialog: boolean;
  showingAnimeOnboardingDialog: boolean;
  showingAnimeSettingDialogInput: ShowAnimeSettingDialogInput;
  showInputReferralCodeDialog: (show: boolean) => void;
  showAddCreditCardDialogInput: ShowAddCreditCardDialogInput;
  showMissionDialog: (show: boolean) => void;
  showAnimeOnboardingDialog: (show: boolean) => void;
  showAnimeSettingDialog: (input: ShowAnimeSettingDialogInput) => void;
  showingInviteFriendDialog: boolean;
  showingMissionDialog: boolean;
  hasNewNotification: boolean;
  isShowingAnimeCharacter: boolean;
  allSymbolAlertSettings: ChartSymbolAlertData[];
  shouldRefetchAlertSettings: number;
  isShowingContactEmailInputDialog: boolean;
  refetchAlertSettings: () => void;
  setFirebaseUser: (user: FirebaseUser) => void;
  setRequestToken: (token: string) => void;
  setCurrentAuth: (user: FirebaseUser) => void;
  setAudioPlayer: (key: string, ref: Partial<AudioPlayerInfo>) => void;
  setPortalNode: (key: string, ref: HtmlPortalNode) => void;
  setGlobalPlayer: (info: AudioPlayerInfo) => void;
  setAuthInit: (bool: boolean) => void;
  setRedirectUrl: (url: string) => void;
  setSnackbarMessage: (message: SnackbarMessage) => void;
  showLogin: (input: ShowLoginInput) => void;
  showRemainingCoinDialog: (open: boolean) => void;
  showPaymentDialog: (input: ShowPaymentDialogInput) => void;
  setShowMembershipPayDialog: (input: ShowMembershipPayDialogInput) => void;
  showAddCreditCardDialog: (input: ShowAddCreditCardDialogInput) => void;
  showTippingDialog: (input: ShowTippingDialogInput) => void;
  showRecommendCreatorDialog: (input: ShowRecommendCreatorInput) => void;
  showCreatePostDialog: (input: ShowCreatePostDialogInput) => void;
  showCreatePostDialogMobile: (input: ShowCreatePostDialogInput) => void;
  setHasNewNotification: (has: boolean) => void;
  setAllSymbolAlertSettings: (settings: ChartSymbolAlertData[]) => void;
  showSymbolAlertSettingDialog: (
    input: ShowSymbolAlertSettingDialogInput
  ) => void;
  showContactEmailInputDialog: (show: boolean) => void;
  setUserClickedAnimeIcon: (show: boolean) => void;
  setShowingAnimeCharacter: (show: boolean) => void;
  postToShareByPopup: Post | null;
  setPostToShareByPopup: (post: Post | null) => void;
  userCoinDetails: CoinDetails | null;
  setUserCoinDetails: (details: CoinDetails) => void;
  allUserPopups: UserPopup[];
  setAllUserPopups: (popups: UserPopup[]) => void;
};

const useGlobalStore = create<GlobalStore>((set: SetState<GlobalStore>) => ({
  // states
  redirectUrl: null,
  isAuthInit: false,
  audioPlayers: {},
  showingMissionDialog: false,
  portalNodes: {},
  globalPlayer: null,
  requestToken: null,
  snackbarMessage: null,
  showLoginInput: null,
  showPaymentDialogInput: null,
  showTippingDialogInput: null,
  showAddCreditCardDialogInput: null,
  currentAuth: null,
  showRecommendCreatorInput: null,
  showingInputReferralCodeDialog: false,
  showingProfileHelpDialog: false,
  firebaseUser: null,
  showCreatePostDialogInput: null,
  showingAnimeOnboardingDialog: false,
  showingAnimeSettingDialogInput: null,
  showingDiscountCreatorsDialog: false,
  showCreatePostDialogInputMobile: null,
  showSymbolAlertSettingDialogInput: null,
  hasNewNotification: false,
  isShowingRemainingCoinDialog: false,
  showingInputProfileDialog: false,
  showingIzanaviPRDialog: false,
  showMembershipPayDialogInput: null,
  isShowingContactEmailInputDialog: false,
  shouldRefetchAlertSettings: 0,
  allSymbolAlertSettings: [],
  showingCoinSummaryDialog: false,
  showingInviteFriendDialog: false,
  showingMembershipStatusDialog: false,
  userClickedAnimeIcon: false,
  isShowingAnimeCharacter: false,
  postToShareByPopup: null,
  setFirebaseUser: (user: FirebaseUser) => set({ firebaseUser: user }),
  setRequestToken: (token: string) => set({ requestToken: token }),
  setCurrentAuth: (user: FirebaseUser) => set({ currentAuth: user }),
  setAudioPlayer: (key: string, info: Partial<AudioPlayerInfo>) =>
    set((state) => ({
      audioPlayers: {
        ...state.audioPlayers,
        [key]: { ...state.audioPlayers[key], ...info },
      },
    })),
  setPortalNode: (key: string, node: HtmlPortalNode) =>
    set((state) => ({
      portalNodes: {
        ...state.portalNodes,
        [key]: node,
      },
    })),
  setGlobalPlayer: (info: AudioPlayerInfo) => set({ globalPlayer: info }),
  setAuthInit: (isAuthInit: boolean) => set({ isAuthInit }),
  setRedirectUrl: (url: string) => set({ redirectUrl: url }),
  setSnackbarMessage: (message: SnackbarMessage) =>
    set({ snackbarMessage: message }),
  showLogin: (input: ShowLoginInput) => {
    set({
      showLoginInput: input,
      ...(input?.redirectUrl && { redirectUrl: input?.redirectUrl }),
    });
  },
  showMissionDialog: (show: boolean) => {
    set({ showingMissionDialog: show });
  },
  showInviteFriendDialog: (show: boolean) => {
    set({ showingInviteFriendDialog: show });
  },
  showRecommendCreatorDialog: (input: ShowRecommendCreatorInput) => {
    set({ showRecommendCreatorInput: input });
  },
  showRemainingCoinDialog: (open: boolean) => {
    set({ isShowingRemainingCoinDialog: open });
  },
  showCoinSummaryDialog: (open: boolean) => {
    set({ showingCoinSummaryDialog: open });
  },
  setShowMembershipPayDialog: (input: ShowMembershipPayDialogInput) => {
    set({
      showMembershipPayDialogInput: input,
      showPaymentDialogInput: null,
      showTippingDialogInput: null,
      showAddCreditCardDialogInput: null,
    });
  },
  showInputReferralCodeDialog: (show: boolean) => {
    set({ showingInputReferralCodeDialog: show });
  },
  showInputProfileDialog: (show: boolean) => {
    set({ showingInputProfileDialog: show });
  },
  showProfileHelpDialog: (show: boolean) =>
    set({ showingProfileHelpDialog: show }),
  showPaymentDialog: (input: ShowPaymentDialogInput) =>
    set({
      showPaymentDialogInput: input,
      showMembershipPayDialogInput: null,
      showTippingDialogInput: null,
      showAddCreditCardDialogInput: null,
    }),
  showAddCreditCardDialog: (input: ShowAddCreditCardDialogInput) =>
    set({
      showAddCreditCardDialogInput: input,
      showMembershipPayDialogInput: null,
      showPaymentDialogInput: null,
      showTippingDialogInput: null,
    }),
  showCreatePostDialog: (input: ShowCreatePostDialogInput) =>
    set({ showCreatePostDialogInput: input }),
  showCreatePostDialogMobile: (input: ShowCreatePostDialogInput) =>
    set({ showCreatePostDialogInputMobile: input }),
  setHasNewNotification: (has: boolean) => set({ hasNewNotification: has }),
  showTippingDialog: (input: ShowTippingDialogInput) =>
    set({
      showTippingDialogInput: input,
      showMembershipPayDialogInput: null,
      showPaymentDialogInput: null,
      showAddCreditCardDialogInput: null,
    }),
  showSymbolAlertSettingDialog: (input: ShowSymbolAlertSettingDialogInput) =>
    set({ showSymbolAlertSettingDialogInput: input }),
  setAllSymbolAlertSettings: (settings: ChartSymbolAlertData[]) =>
    set({ allSymbolAlertSettings: settings }),
  refetchAlertSettings: () =>
    set((state) => ({
      shouldRefetchAlertSettings: state.shouldRefetchAlertSettings + 1,
    })),
  setPostToShareByPopup: (post: Post | null) =>
    set({ postToShareByPopup: post }),
  showContactEmailInputDialog: (show: boolean) =>
    set({ isShowingContactEmailInputDialog: show }),
  showAnimeOnboardingDialog: (show: boolean) =>
    set({ showingAnimeOnboardingDialog: show }),
  showAnimeSettingDialog: (input: ShowAnimeSettingDialogInput) =>
    set({ showingAnimeSettingDialogInput: input }),
  showDiscountCreatorsDialog: (show: boolean) =>
    set({ showingDiscountCreatorsDialog: show }),
  showMembershipStatusDialog: (show: boolean) => {
    set({ showingMembershipStatusDialog: show });
  },
  showIzanaviPRDialog: (show: boolean) => {
    set({ showingIzanaviPRDialog: show });
  },
  setUserClickedAnimeIcon: (show: boolean) =>
    set({ userClickedAnimeIcon: show }),
  setShowingAnimeCharacter: (show: boolean) =>
    set({ isShowingAnimeCharacter: show }),
  userCoinDetails: null,
  setUserCoinDetails: (details: CoinDetails) =>
    set({ userCoinDetails: details }),
  allUserPopups: [],
  setAllUserPopups: (popups: UserPopup[]) => set({ allUserPopups: popups }),
}));

export default useGlobalStore;
