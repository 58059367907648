import axios from 'axios';
import {
  GetPostsResult,
  Post,
  TrendingTopicPeriod,
} from 'common/interfaces/api';
import { TopicType } from './user';

export const getPostTopics = async () => {
  return (await axios.get(`/feeds/domains`)).data as {
    data: TopicType[];
  };
};

export type TrendingTopicKeyword = {
  topic: string;
  post_count: number;
  topic_type: string;
  posts: Post[];
};

export const getTrendingTopicKeywords = async (
  period: TrendingTopicPeriod,
  page: number
) => {
  return (await axios.get(`/posts/topics/trending?type=${period}&page=${page}`))
    ?.data as {
    data: TrendingTopicKeyword[];
  };
};

export const getTrendingTopicPosts = async ({
  topic,
  page,
  period,
}: {
  topic: string;
  page: number;
  period: TrendingTopicPeriod;
}) => {
  return (
    await axios.get(
      `/posts/topics/posts?type=${period}&topic=${encodeURIComponent(
        topic
      )}&page=${page}`
    )
  )?.data as GetPostsResult;
};

// export const getLivePosts = async ({ page }: { page: number }) => {
//   return (await axios.get(`/feeds/lives?page=${page}`))?.data as GetPostsResult;
// };
