import { TrendingTopicKeyword } from 'service/post_topic';
import styles from './SideBarHotKeywordsList.module.scss';
import LinkButton from 'components/common/LinkButton';
import { SideBarKeywordLoader } from 'components/common/PreloadContentLoader';
import classNames from 'classnames';
import Link from 'next/link';
import { periodToString } from 'common/utils/trending_topic';
import { TrendingTopicPeriod } from 'common/interfaces/api';
import { track } from '@amplitude/analytics-browser';

const SideBarHotKeywordsList = ({
  keywords,
  isFetched,
}: {
  keywords: TrendingTopicKeyword[];
  isFetched: boolean;
}) => {
  if (isFetched && keywords?.length === 0) return null;
  return (
    <div className={styles.sideBarHotKeywordsList}>
      <div className={styles.title}>今の話題</div>
      <div className={styles.keywords}>
        {isFetched ? (
          (keywords || []).map((keywordObject, index) => {
            return (
              <KeywordItem
                keywordObject={keywordObject}
                key={index}
                isHiding={false}
              />
            );
          })
        ) : (
          <Preloader />
        )}
        <Link href="/trending_topics?period=weekly">
          <a
            className={styles.readMoreBtn}
            onClick={() => {
              track('Click More Trending Word');
            }}
          >
            <LinkButton text="もっと見る" nonUnderline fontSize={14} />
          </a>
        </Link>
      </div>
    </div>
  );
};

const Preloader = () => {
  return (
    <div className={styles.preloader}>
      {Array.from(Array(5).keys()).map((index) => {
        return (
          <div
            className={classNames(styles.keywordItem, styles.preloading)}
            key={index}
          >
            <SideBarKeywordLoader />
          </div>
        );
      })}
    </div>
  );
};

const KeywordItem = ({
  keywordObject,
  isHiding,
}: {
  keywordObject: TrendingTopicKeyword;
  isHiding: boolean;
}) => {
  return (
    <Link
      href={`/trending_topics?period=${periodToString(
        TrendingTopicPeriod.MidTerm
      )}&keyword=${keywordObject.topic}`}
    >
      <a
        className={styles.keywordItem}
        style={isHiding ? { display: 'none' } : {}}
        onClick={() => {
          track('Click Trending Word', {
            word: keywordObject.topic,
          });
        }}
      >
        <div className={styles.topicName}>{keywordObject.topic}</div>
        <div className={styles.postsCount}>{keywordObject.post_count}投稿</div>
      </a>
    </Link>
  );
};
export default SideBarHotKeywordsList;
