/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AppProps } from 'next/app';
// import { useEffect } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import Head from 'next/head';
import React, { useEffect, useMemo, useRef } from 'react';
import AuthProvider from 'context/Auth';
import 'config/api';
import AppLayout from 'components/AppLayout';
import { analytics } from 'context/Analyst';
import ErrorBoundary from 'common/utils/ErrorBoundary';
import * as amplitude from '@amplitude/analytics-browser';
import 'styles/sanitize.css';
import 'styles/global.css';
import ErrorFallback from 'components/common/ErrorFallback';
import { SITE_BASE_URL } from 'config/env';
import useStore from 'store/timeline';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from 'config/stripe';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }
  const stripePromise = useMemo(() => loadStripe(STRIPE_PUBLISHABLE_KEY), []);
  const currentUser = useStore((state) => state.currentUser);
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      analytics();
      amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY);
      try {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        console.warn = () => {};
      } catch (error) {
        /* empty */
      }
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (!currentUser?.id) return;
      try {
        amplitude.setUserId(currentUser?.id?.toString());
      } catch (error) {
        /* empty */
      }
    }
  }, [currentUser?.id]);

  return (
    <>
      <Head>
        <title>
          PostPrime | 高橋ダン企画-資産形成が学べる投資・株式予想・情報サイト
        </title>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link rel="prefetch" href="/images/icons/loading-anim-4.svg" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        <meta name="robots" content="index,follow" />
        <meta charSet="utf-8" />
        <meta name="description" content="ログインして、SNSでお金を学ぼう!" />
        <meta
          name="keywords"
          content="PostPrime,高橋ダン,トレード,dan,高橋ダニエル圭,経済,株,株式,日本株,暗号資産,米国株,FX,CFD,コモディティ,IPO,金融,投資,トレーダー,お金を増やす,お金を学ぶ,新しいSNS,どの形の投稿を誰でも,世界の最新ニュース,AIキャラクターが速報情報を話す,投資タイミングの通知ツール,ライブ配信,無料でも使える"
        />
        <meta
          content={`${SITE_BASE_URL}/images/postprime.png`}
          property="og:image"
          key="ogimage"
        />
        <meta
          content={`${SITE_BASE_URL}/images/postprime.png`}
          name="twitter:image"
          key="twimage"
        />
        <meta property="og:locale" content="ja_JP" />
        <meta property="og:site_name" content="postprime" key="ogsitename" />
        <meta property="twitter:domain" content={SITE_BASE_URL} />
        <link rel="icon" type="image/ico" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link href="/favicon-32x32.png" rel="icon" sizes="32x32" />
        <link href="/favicon-16x16.png" rel="icon" sizes="16x16" />
        <link href="/favicon-57x57.png" rel="icon" sizes="57x57" />
        <link href="/favicon-76x76.png" rel="icon" sizes="76x76" />
        <link href="/favicon-96x96.png" rel="icon" sizes="96x96" />
        <link href="/favicon-128x128.png" rel="icon" sizes="128x128" />
        <link href="/favicon-192x192.png" rel="icon" sizes="192x192" />
        <link href="/favicon-228x228.png" rel="icon" sizes="228x228" />
        <meta content="#FFFFFF" name="msapplication-TileColor" />
        <meta content="/favicon-144.png" name="msapplication-TileImage" />
        <meta content="/browserconfig.xml" name="msapplication-config" />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <AuthProvider>
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={pageProps.dehydratedState}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Elements stripe={stripePromise}>
                <AppLayout>
                  <Component {...pageProps} />
                </AppLayout>
              </Elements>
            </ErrorBoundary>
          </Hydrate>
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}
