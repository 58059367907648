import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import firebaseConfig from 'config/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const analytics = firebase.analytics;

export { analytics };
