import { FC, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import useGlobalStore from 'store/global';
import useStore from 'store/timeline';
import {
  onIdTokenChanged,
  getIdToken,
  User as FirebaseUser,
  ParsedToken,
} from 'firebase/auth';
import { firebaseAuth } from 'lib/firebase';
import getConfig from 'next/config';
axios.interceptors.response.use(
  function (response) {
    // Do nothing with response data
    return response;
  },
  function (error: AxiosError) {
    // Handle response error 401
    if (error.response?.status === 401) {
      console.log('Status 401 detected, trying to refresh the ID token...');
      void firebaseAuth.currentUser?.getIdToken(true);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  config.params = config.params || {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  config.params['client'] = 'web';
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { publicRuntimeConfig } = getConfig();
    // eslint-disable-next-line
    config.params['app_version'] = publicRuntimeConfig.version;
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return config;
});

const clearLocalUserInfo = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  axios.defaults.headers.common['Authorization'] = null;
};

const AuthProvider: FC = ({ children }) => {
  const setCurrentAuth = useGlobalStore((state) => state.setCurrentAuth);
  const setFirebaseUser = useGlobalStore((state) => state.setFirebaseUser);
  const setAuthInit = useGlobalStore((state) => state.setAuthInit);
  const setRequestToken = useGlobalStore((state) => state.setRequestToken);
  const setCurrentUser = useStore((state) => state.setCurrentUser);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onIdTokenChanged(firebaseAuth, async (user: FirebaseUser) => {
      // store ID token in localstorage so mobile app can fetch it
      // firebase auth already stores current user info in IndexedDB
      if (user) {
        const token = await getIdToken(user);
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims as ParsedToken & {
          yp_user_id: number;
        };
        const isNewUser = !claims.yp_user_id;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        setRequestToken(token);
        setFirebaseUser(user);
        if (!isNewUser) {
          setCurrentAuth(user);
        } else {
          setCurrentAuth(null);
          setCurrentUser(null);
        }
      } else {
        setCurrentAuth(null);
        setCurrentUser(null);
        clearLocalUserInfo();
      }
      setAuthInit(true);
    });
  }, [
    setAuthInit,
    setCurrentAuth,
    setFirebaseUser,
    setRequestToken,
    setCurrentUser,
  ]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(() => {
      const user = firebaseAuth.currentUser;
      if (user) void user.getIdToken(true);
    }, 10 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  return <>{children}</>;
};

export default AuthProvider;
