import Icon from 'components/common/Icon';

import styles from './SplashScreen.module.scss';

export default function SplashScreen(): JSX.Element {
  return (
    <div className={styles.splashScreenWrapper}>
      <div className={styles.loading}>
        <Icon name="loading-anim-4" width={80} height={80} />
      </div>
    </div>
  );
}
