import { MembershipPackageName } from 'common/interfaces/api';
import Image from 'next/image';
import Icon from '../Icon';

const MembershipVipIcon = ({
  width,
  height,
  onClick,
  packageName,
  isIzanavi,
}: {
  width: number;
  height: number;
  onClick?: () => void;
  packageName: MembershipPackageName;
  isIzanavi?: boolean;
}) => {
  if (isIzanavi) {
    return (
      <Image
        src={`/images/izanavi/izanavi-vip-icon.png`}
        width={width}
        height={height}
        style={onClick ? { cursor: 'pointer' } : {}}
        onClick={onClick ? onClick : undefined}
      />
    );
  }
  if (!Object.values(MembershipPackageName).includes(packageName)) return <></>;
  if (packageName === MembershipPackageName.Platinum) {
    if (isIzanavi) {
      return (
        <Image
          src={`/images/izanavi/izanavi-vip-icon.png`}
          width={width}
          height={height}
          style={onClick ? { cursor: 'pointer' } : {}}
          onClick={onClick ? onClick : undefined}
        />
      );
    }
    return (
      <Image
        src={`/images/platinum-vip-icon.png`}
        width={width}
        height={height}
        style={onClick ? { cursor: 'pointer' } : {}}
        onClick={onClick ? onClick : undefined}
      />
    );
  }
  return (
    <Icon
      name={`${packageName}-icon-1`}
      width={width}
      height={height}
      onClick={onClick ? onClick : undefined}
    />
  );
};

export default MembershipVipIcon;
