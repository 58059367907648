import DashboardSidebar from 'components/DashboardSidebar';
import styles from './DashboardTemplate.module.scss';
import useGlobalStore from 'store/global';

const DashboardTemplate = ({ children }) => {
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  return (
    <div className={styles.container}>
      <div className={styles.dashboardPageWrapper}>
        <div className={styles.sidebar}>
          <DashboardSidebar />
        </div>
        <div className={styles.content}>{currentAuth && children}</div>
      </div>
    </div>
  );
};

export default DashboardTemplate;
