import Icon from 'components/common/Icon';
import styles from './ErrorFallback.module.scss';

const ErrorFallback = () => {
  return (
    <div className={styles.errorWrapper}>
      <div className={styles.icon}>
        <Icon name="empty" width={72} height={72} />
      </div>
      <p className={styles.text}>エラーが発生しました。</p>
    </div>
  );
};

export default ErrorFallback;
