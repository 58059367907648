import { useCallback, useEffect, useRef } from 'react';
import Icon from '../Icon';

import styles from './InfiniteScroll.module.scss';

export type Props = {
  children: React.ReactChild | React.ReactChild[];
  fetchNextPage: () => void;
  disabled?: boolean;
  isLoading: boolean;
};
export default function InfiniteScroll({
  children,
  fetchNextPage,
  disabled = false,
  isLoading = false,
}: Props): JSX.Element {
  // Inifinite scroll
  // Create ref to attach to the loader component
  // First loader to load new posts at 90% screen mark
  // Second loader to load new posts at 100% screen (end of screen) mark
  // this is to account for the case user forcefully scrolls past first loader
  const loader = useRef<HTMLDivElement>(null);
  const loader2 = useRef<HTMLDivElement>(null);
  const loadMore = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target && target.isIntersecting && !isLoading) {
        void fetchNextPage();
      }
    },
    [fetchNextPage, isLoading]
  );
  useEffect(() => {
    if (disabled) return;

    const ref1 = loader.current;
    const ref2 = loader2.current;

    const options = {
      root: null, // window by default
      rootMargin: '0px',
      threshold: 0,
    };

    // Create observer
    /** wait 1second for data fetching */
    const observer = new IntersectionObserver(loadMore, options);
    const scrollCheck = setTimeout(function () {
      // observe the first loader
      if (ref1) {
        observer.observe(ref1);
      }
      // observe the second loader
      if (ref2) {
        observer.observe(ref2);
      }
    }, 1000);

    // clean up on willUnMount
    return () => {
      if (ref1) {
        observer.unobserve(ref1);
      }
      if (ref2) {
        observer.unobserve(ref2);
      }
      scrollCheck && clearTimeout(scrollCheck);
    };
  }, [loadMore, disabled]);

  return (
    <div className={styles.infiniteScrollWrapper}>
      {children}
      {isLoading && (
        <div className={styles.loading}>
          <Icon name="loading-anim-3" width={24} height={24} />
        </div>
      )}
      <div ref={loader} className={styles.loader}></div>
      <div ref={loader2} className={styles.loader2}></div>
    </div>
  );
}
