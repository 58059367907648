import { MouseEventHandler } from 'react';
import styles from './Icon.module.scss';
import Image from 'next/image';
import classNames from 'classnames';

export type Props = {
  name: string;
  isOn?: boolean;
  width: number;
  height: number;
  onClick?: MouseEventHandler;
  hasOn?: boolean;
  hasHover?: boolean;
};

export default function Icon({
  name,
  width,
  height,
  isOn = false,
  onClick,
  hasOn = false,
  hasHover = false,
}: Props): JSX.Element {
  let imgSrc = `/images/icons/${name}.svg`;
  if (hasOn) {
    imgSrc = isOn
      ? `/images/icons/${name}-on.svg`
      : `/images/icons/${name}.svg`;
  }
  const hoverSrc = isOn
    ? `/images/icons/${name}-on-hover.svg`
    : `/images/icons/${name}-hover.svg`;
  let onMouseOver: MouseEventHandler<HTMLImageElement>;
  let onMouseOut: MouseEventHandler<HTMLImageElement>;
  if (hasHover) {
    onMouseOver = (e) => (e.currentTarget.src = hoverSrc);
    onMouseOut = (e) => (e.currentTarget.src = imgSrc);
  }

  return (
    <div
      className={styles.iconWrapper}
      style={{ cursor: onClick ? 'pointer' : 'unset' }}
      tabIndex={onClick ? 0 : -1}
    >
      <Image
        src={imgSrc}
        width={width}
        height={height}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
        alt={name}
      />
    </div>
  );
}

export const DoneIcon = ({
  width = 18,
  height = 18,
}: {
  width: number;
  height: number;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <circle cx="12" cy="12" r="12" fill="#0ABC71" />
      <path
        d="M6 13.4356L9.61592 17.0516L17.9995 8.66797"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SortCaretIcon = ({
  width = 18,
  height = 18,
  color = 'black',
}: {
  width: number;
  height: number;
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071Z"
        fill={color}
      />
    </svg>
  );
};

export const ForwardIcon = ({
  width = 18,
  height = 18,
  color = '#3983F7',
}: {
  width: number;
  height: number;
  color?: string;
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6023 16.8977C10.3826 16.6781 10.3826 16.3219 10.6023 16.1023L14.7045 12L10.6023 7.89775C10.3826 7.67808 10.3826 7.32192 10.6023 7.10225C10.8219 6.88258 11.1781 6.88258 11.3977 7.10225L15.8977 11.6023C16.1174 11.8219 16.1174 12.1781 15.8977 12.3978L11.3977 16.8977C11.1781 17.1174 10.8219 17.1174 10.6023 16.8977Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LiveIcon = ({
  width = 18,
  height = 18,
  color = '#FF2D55',
  stokeWidth = 2,
}: {
  width: number;
  height: number;
  color?: string;
  stokeWidth?: number;
}) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <path
        d="M3.63608 14.364C2.80034 13.5283 2.13739 12.5361 1.68509 11.4442C1.2328 10.3522 1 9.18189 1 7.99999C1 6.81808 1.2328 5.64775 1.68509 4.55581C2.13739 3.46387 2.80034 2.47172 3.63608 1.63599M16.3641 1.63599C17.1998 2.47172 17.8628 3.46387 18.3151 4.55581C18.7674 5.64775 19.0002 6.81808 19.0002 7.99999C19.0002 9.18189 18.7674 10.3522 18.3151 11.4442C17.8628 12.5361 17.1998 13.5283 16.3641 14.364M6.46408 11.535C5.52672 10.5973 5.00015 9.32581 5.00015 7.99999C5.00015 6.67417 5.52672 5.40262 6.46408 4.46499M13.5361 4.46499C14.4734 5.40262 15 6.67417 15 7.99999C15 9.32581 14.4734 10.5973 13.5361 11.535M11.0001 7.99999C11.0001 8.2652 10.8947 8.51956 10.7072 8.70709C10.5196 8.89463 10.2653 8.99999 10.0001 8.99999C9.73486 8.99999 9.48051 8.89463 9.29297 8.70709C9.10544 8.51956 9.00008 8.2652 9.00008 7.99999C9.00008 7.73477 9.10544 7.48042 9.29297 7.29288C9.48051 7.10534 9.73486 6.99999 10.0001 6.99999C10.2653 6.99999 10.5196 7.10534 10.7072 7.29288C10.8947 7.48042 11.0001 7.73477 11.0001 7.99999Z"
        stroke={color}
        strokeWidth={stokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ExpandIcon = ({
  expand,
  setExpand,
  width = 25,
  height = 25,
}: {
  expand: boolean;
  setExpand?: (ex: boolean) => void;
  width: number;
  height: number;
}) => {
  return (
    <div
      className={classNames(styles.expand, {
        [styles.closing]: !expand,
      })}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <Icon
        name="sm-sort-caret"
        width={25}
        height={25}
        onClick={() => {
          setExpand && setExpand(!expand);
        }}
      />
    </div>
  );
};
