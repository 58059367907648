import axios from 'axios';
import {
  GetSymbolRelatedPostsResult,
  Period,
  PostAnalytic,
  SymbolChart,
  SymbolListResult,
  User,
  VoteSymbol,
} from 'common/interfaces/api';

export const getAllSymbols = async () => {
  return (await axios.get('/news/charts/symbols'))?.data as SymbolListResult;
};

export const getAllSymbolTypes = async () => {
  return (await axios.get('/news/charts/symbols/types'))?.data as {
    data: { name: string; code: string }[];
  };
};

export const getTrendingSymbols = async () => {
  return (await axios.get('/news/charts/symbols/trending'))
    ?.data as SymbolListResult;
};

export const searchSymbol = async (keyword: string) => {
  return (await axios.get(`/news/charts/symbols/search?keyword=${keyword}`))
    ?.data as SymbolListResult;
};

export const getSymbolsByType = async (type: string) => {
  const TRENDING_TYPE = 'trending';
  if (type === TRENDING_TYPE) {
    return await getTrendingSymbols();
  }
  return (await axios.get(`/news/charts/symbols/types/${decodeURI(type)}`))
    ?.data as SymbolListResult;
};

export const getSymbolRelatedPosts = async ({
  page,
  symbolIds,
  tabName,
}: {
  page: number;
  symbolIds: number[];
  tabName: string;
}) => {
  let symbolsParams = '';
  symbolIds.forEach((id) => {
    symbolsParams = `${symbolsParams}&symbols=${id}`;
  });
  const tabNameParam = tabName ? `&tab_name=${tabName}` : '';
  return (
    await axios.get(
      `/posts/charts/symbols/related_posts?page=${page}${symbolsParams}${tabNameParam}`
    )
  )?.data as GetSymbolRelatedPostsResult;
};

export const voteToSymbol = async (type: 'up' | 'down', symbolId: number) => {
  return await axios.post(`/news/charts/symbols/votes`, {
    symbol_id: symbolId,
    vote: type,
  });
};

export type Prediction = {
  id: number;
  charts_symbols_id: number;
  date: string;
  week_price: number;
  month_price: number;
  comment: {
    id: number;
    date: string;
    text: string;
    like_count: number;
    user?: User;
    created_at: string;
    updated_at: string;
  };
};

export type PredictionChartPoint = {
  date: string;
  week_price: number;
  month_price: number;
  week_count: number;
  month_count: number;
};
export type SymbolPrediction = {
  name: string;
  charts_symbols_id: number;
  code: string;
  currency: string;
  type: string;
  prediction: Prediction;
  daily_statistics: {
    type: 'week' | 'month';
    count: number;
    median_price: number;
  }[];
  daily_analytics: PredictionChartPoint[];
};

export type ChartSymbol = {
  symbol: SymbolChart;
  votes: VoteSymbol[];
  post_analytics: PostAnalytic[];
  is_pinned?: boolean;
  post_analytic: PostAnalytic;
  vote: VoteSymbol;
  alarm?: ChartSymbolAlertData;
  prediction?: SymbolPrediction;
  tab_show_highlight: string[];
  position_in_ranking_tab: number;
};

export const favoritesCharts = async (page: number) => {
  return (await axios.get(`/news/charts/symbols/favorites?page=${page}`))
    ?.data as {
    data: ChartSymbol[];
    total: number;
  };
};

export const pinChart = async (id: number, type: 'pin' | 'unpin') => {
  return (
    await axios.post(`/news/charts/symbols/pin`, { chart_symbol_id: id, type })
  )?.data as {
    data: ChartSymbol[];
  };
};

export enum GraphPeriod {
  ThreeMonths = '3_months',
  SixMonths = '6_months',
  OneYear = '1_year',
}

export const getSymbolAnalytics = async ({
  id,
  period,
}: {
  id: number;
  period: GraphPeriod;
}) => {
  const param = `?symbol_id=${id}&graph_period=${period}`;
  return (await axios.get(`/posts/charts/symbols/analytics${param}`))?.data as {
    data: ChartSymbol;
  };
};

export type ChartSymbolAlertData = {
  charts_symbols_id: number;
  created_at: string;
  id: number;
  symbol: SymbolChart;
  updated_at: string;
  user_id: number;
  vote_daily_exp_at: string;
  vote_daily_high: number;
  vote_daily_low: number;
  vote_monthly_exp_at: string;
  vote_monthly_high: number;
  vote_monthly_low: number;
  vote_weekly_exp_at: string;
  vote_weekly_high: number;
  vote_weekly_low: number;
  vote_change_daily_exp_at: string;
  vote_change_daily_high: number;
  vote_change_daily_low: number;
  vote_change_monthly_exp_at: string;
  vote_change_monthly_high: number;
  vote_change_monthly_low: number;
  vote_change_weekly_exp_at: string;
  vote_change_weekly_high: number;
  vote_change_weekly_low: number;
  prediction_week_gt_month: boolean;
  prediction_month_gt_week: boolean;
  izanavi_signal: boolean;
};

export const getAlertSetting = async (id: number) => {
  return (await axios.get(`/news/charts/symbols/alarms/${id}`))?.data as {
    data: ChartSymbolAlertData;
  };
};

export const getAlertSettingV2 = async (id: number) => {
  return (await axios.get(`/news/charts/symbols/alarms/v2/${id}`))?.data as {
    data: {
      my_alarm: ChartSymbolAlertData;
      shared_alarms: ChartSymbolAlertData[];
      shared_alarms_total: number;
    };
  };
};

export const getAllAlertSettings = async () => {
  return (await axios.get(`/news/charts/symbols/alarms`))?.data as {
    data: ChartSymbolAlertData[];
  };
};

export const updateSymbolAlarm = async ({
  data,
  showingKeys,
  applyFavorite,
}: {
  data: ChartSymbolAlertData;
  showingKeys: string[];
  applyFavorite: boolean;
}) => {
  const body = {
    apply_to_favorite_symbols: applyFavorite,
    charts_symbols_id: data.charts_symbols_id,
    vote_daily_high: showingKeys.includes('vote_daily')
      ? data.vote_daily_high || null
      : null,
    vote_daily_low: showingKeys.includes('vote_daily')
      ? data.vote_daily_low || null
      : null,
    vote_monthly_high: showingKeys.includes('vote_monthly')
      ? data.vote_monthly_high || null
      : null,
    vote_monthly_low: showingKeys.includes('vote_monthly')
      ? data.vote_monthly_low || null
      : null,
    vote_weekly_high: showingKeys.includes('vote_weekly')
      ? data.vote_weekly_high || null
      : null,
    vote_weekly_low: showingKeys.includes('vote_weekly')
      ? data.vote_weekly_low || null
      : null,
    prediction_month_gt_week: data.prediction_month_gt_week,
    prediction_week_gt_month: data.prediction_week_gt_month,
    izanavi_signal: data.izanavi_signal,
  };
  return await axios.post(`/news/charts/symbols/alarms`, body);
};

export const deleteAlertSetting = async (id: number) => {
  return await axios.delete(`/news/charts/symbols/alarms/${id}`);
};

export const deleteAllAlertSettings = async () => {
  return await axios.delete(`/news/charts/symbols/alarms`);
};

export const sendSymbolPrediction = async ({
  id,
  weekPrice,
  monthPrice,
  comment,
}: {
  id: number;
  weekPrice: number;
  monthPrice: number;
  comment?: string;
}) => {
  return (
    await axios.post(`/news/charts/symbols/${id}/predictions`, {
      week_price: weekPrice,
      month_price: monthPrice,
      comment,
    })
  )?.data as {
    data: SymbolPrediction;
  };
};

export const likePrediction = async ({
  symbolId,
  predictionId,
  action,
}: {
  symbolId: number;
  predictionId: number;
  action: 'like' | 'unlike';
}) => {
  return await axios.post(
    `/news/charts/symbols/${symbolId}/predictions/${predictionId}/reaction`,
    { action }
  );
};

export interface PredictionItemType {
  id: number;
  charts_symbols_id: number;
  created_at: string;
  date: string;
  like_count: number;
  is_liked: boolean;
  month_price: number;
  week_price: number;
  comment: {
    id: number;
    date: string;
    text: string;
    created_at: string;
    updated_at: string;
  };
  updated_at: string;
  user: User;
  is_followed: boolean;
  symbol: {
    id: number;
    code: string;
    name: string;
    type: string;
    currency: string;
  };
}

export enum PredictionItemSortByType {
  CreatedAt = 'created_at',
  WeekPrice = 'week_price',
  MonthPrice = 'month_price',
  LikeCount = 'like_count',
}
export const getPredictionItems = async ({
  symbolId,
  lastKey,
  sortParam,
  showOnlyComments,
}: {
  symbolId: number;
  lastKey: string;
  sortParam: string;
  showOnlyComments: boolean;
}) => {
  return (
    await axios.get(
      `/news/charts/symbols/${symbolId}/predictions?all=${String(
        showOnlyComments ? false : true
      )}&last_key=${lastKey}${sortParam}`
    )
  )?.data as {
    data: {
      predictions: PredictionItemType[];
      total: number;
      last_key: string;
    };
  };
};

export const followUserPrediction = async ({
  symbolId,
  userId,
  action,
}: {
  symbolId: number;
  userId: number;
  action: 'on' | 'off';
}) => {
  return await axios.post(
    `/news/charts/symbols/${symbolId}/predictions/alarms`,
    {
      target_user_id: userId,
      type: action,
    }
  );
};

export interface SymbolFollowers {
  symbol: SymbolChart;
  users: User[];
}
export const getPredictionsSymbolFollowers = async () => {
  return (await axios.get(`/news/charts/symbols/predictions/alarms`))?.data as {
    data: SymbolFollowers[];
  };
};

export const getVotesRanking = async (period: Period, symbolType: string) => {
  return (
    await axios.get(
      `/news/charts/symbols/ranking?data_type=${period}&symbol_type=${symbolType}`
    )
  )?.data as {
    data: ChartSymbol[];
  };
};

export type ScoringPricePointType = {
  // signal
  s: string;
  // date
  d: string;
  // output length
  ol: number;
  // price
  p: number;
};

export type ScoringModelSimulationType = {
  year: number;
  simulated_profit: number;
  hold_profit: number;
};

export type ScoringModelResultType = {
  current_price: number;
  current_signal: string;
  scoring_model_simulation: ScoringModelSimulationType[];
  scoring_price_charts: ScoringPricePointType[];
  symbol_name: string;
  current_patterns: string[];
  signal_jp_title: string;
};

export const getScoringModelResult = async ({
  id,
  yearTo,
}: {
  id: number;
  yearTo: number;
}) => {
  const param = `?symbol_id=${id}&chart_from_date=2019-01-01&simulate_year_from=2019&simulate_year_to=${yearTo}`;
  return (await axios.get(`/news/charts/symbols/scoring_model_result${param}`))
    ?.data as {
    data: ScoringModelResultType;
  };
};
