import {
  MediaType,
  Post,
  PostSource,
  PostType,
  PrecheckLog,
} from 'common/interfaces/api';
import { PolicyLevel, PolicyError } from 'service/post';
import {
  addEvent,
  EventAction,
  EventCategory,
  EventComponent,
  ScreenName,
} from 'common/utils/pp_tracking';
import { track } from '@amplitude/analytics-browser';
import { SITE_BASE_URL } from 'config/env';

export const getPostInternalUrl = (post: Post) => {
  if (!post || !post.user_info) return '';
  return `/${post.user_info?.username}/posts/${post.id}`;
};

export const getPostUrlToShare = (post: Post) => {
  if (!post || !post.user_info) return '';
  return `${SITE_BASE_URL}/${post.user_info?.username}/posts/${post.id}`;
};

export const hasAIPrecheckError = (
  policies: PolicyError[],
  isFreePost: boolean
) => {
  if (!policies || policies.length === 0) return false;
  const hasSuperHighRisk = policies.some(
    (policy) => policy.level === PolicyLevel.SuperHigh
  );
  const hasHighRisk = policies.some(
    (policy) => policy.level === PolicyLevel.High
  );
  return isFreePost ? hasSuperHighRisk : hasHighRisk || hasSuperHighRisk;
};

export const hasAIPrecheckImageError = (
  precheckLogs: PrecheckLog[],
  isFreePost: boolean
) => {
  const hasSuperHighRisk = precheckLogs.some((log) =>
    log.reasons?.some((reason) => reason.level === PolicyLevel.SuperHigh)
  );
  const hasHighRisk = precheckLogs.some((log) =>
    log.reasons?.some((reason) => reason.level === PolicyLevel.High)
  );
  return isFreePost ? hasSuperHighRisk : hasHighRisk || hasSuperHighRisk;
};

export const addImpressionPostEvent = ({
  postId,
  screen,
  postSource,
}: {
  postId: number;
  screen: ScreenName;
  postSource: PostSource;
}) => {
  addEvent({
    action: EventAction.PageView,
    category: EventCategory.AiRecommend,
    screen: screen,
    component: EventComponent.PostItem,
    timestamp: new Date().toISOString(),
    post_properties: {
      post_id: postId,
      source_type: postSource?.type || '',
      source_name: postSource?.name || '',
      source_version: postSource?.version || '',
    },
    tracking_params: [
      {
        label: 'impression',
        value: postId.toString(),
      },
    ],
  });
};

export const addClickPostEvent = ({
  postId,
  screen,
  postSource,
  clickPosition,
  postType,
  mediaType,
}: {
  postId: number;
  screen: ScreenName;
  postSource: PostSource;
  clickPosition: 'media_view' | 'post_view' | 'comment_button';
  postType: PostType;
  mediaType: MediaType;
}) => {
  track('Click Post', {
    post_type: postType || '',
    media_type: mediaType || '',
    page: screen,
  });
  addEvent({
    action: EventAction.Click,
    category: EventCategory.AiRecommend,
    screen: screen,
    component: EventComponent.PostItem,
    timestamp: new Date().toISOString(),
    post_properties: {
      post_id: postId,
      source_type: postSource?.type || '',
      source_name: postSource?.name || '',
      source_version: postSource?.version || '',
    },
    tracking_params: [
      {
        label: 'click_position',
        value: clickPosition,
      },
    ],
  });
};

// only tracking post detail
export const addWatchTimeEvent = ({
  postId,
  watchTime,
}: {
  postId: number;
  watchTime: number;
}) => {
  addEvent({
    action: EventAction.PageView,
    category: EventCategory.AiRecommend,
    screen: ScreenName.PostDetail,
    component: EventComponent.VideoPlayer,
    timestamp: new Date().toISOString(),
    post_properties: {
      post_id: postId,
    },
    tracking_params: [
      {
        label: 'watch_time',
        value: watchTime.toString(),
      },
    ],
  });
};

export const altImageOfPost = (text: string) => {
  if (!text) return '';
  const maxLength = 120;
  let breakIndex = text.indexOf('\n');
  if (breakIndex > maxLength) return text.slice(0, maxLength);
  breakIndex = text.indexOf('\n', breakIndex + 1);
  if (breakIndex > maxLength) return text.slice(0, maxLength);
  breakIndex = text.indexOf('\n', breakIndex + 1);
  if (breakIndex > 0 && breakIndex < maxLength)
    return text.slice(0, breakIndex);
  return text.slice(0, maxLength);
};

export const checkIsFreePost = (postType: PostType, isAdsPost: boolean) => {
  return postType === PostType.Free || isAdsPost;
};

export const checkIsPaidPost = (postType: PostType) => {
  return [PostType.Paid, PostType.Plus, PostType.Gold].includes(postType);
};

export const checkIsCreatorPaidPost = (postType: PostType) => {
  return [PostType.Paid, PostType.Plus].includes(postType);
};

export const checkIsContentLocked = (post: Post) => {
  if (post.is_my_post) return false;
  // const isLockedWithPlusPost =
  //   post.type === PostType.Plus && !post.content_access?.is_authorized;
  // const isLockedWithPrimePost =
  //   post.type === PostType.Paid && !post.content_access?.is_authorized;

  return !post.content_access?.is_authorized;
};

export const commentLockMessage = (post: Post, isContentLocked: boolean) => {
  const isScheduledPost = new Date(post.created_at) > new Date();
  if (isScheduledPost) {
    return '公開した投稿のみにコメント可能です';
  }
  if (!isContentLocked) return '';
  if (post.is_blocked) {
    return 'このユーザーにはコメントが許可されていません';
  }
  if (post.type === PostType.Gold) {
    return 'Goldのメンバーシップ登録した方のみコメント可能です';
  }
  if (post.type === PostType.Plus) {
    return 'プライムPlusを登録した方のみコメント可能です';
  }
  if (post.type === PostType.Paid) {
    return 'プライム登録した方のみコメント可能です';
  }
  return '';
};

export const exCreatorPostLockMessage = (postType: PostType) => {
  if (postType === PostType.Plus) {
    return 'プライムクリエイターの機能の一部を制限しているため、プライムPlusを登録できません。';
  }
  if (postType === PostType.Paid) {
    return 'プライムクリエイターの機能の一部を制限しているため、プライム登録できません。';
  }
  return '';
};
