import { POLICY_URL, SECURITY_POLICY_URL, USER_TERM_URL } from 'common/utils';
import styles from './CopyrightInSidebar.module.scss';

const CopyrightInSidebar = () => {
  return (
    <div className={styles.copyrightWrapper}>
      <a href={USER_TERM_URL} target="_blank">
        利用規約
      </a>
      <a href={POLICY_URL} target="_blank">
        プライバシーポリシー
      </a>
      <a href={SECURITY_POLICY_URL} target="_blank">
        情報セキュリティ基本方針
      </a>
      <a href="https://corp.postprime.com/company" target="_blank">
        運営会社
      </a>
      <div className={styles.copyright}>© 2022 PostPrime株式会社</div>
    </div>
  );
};

export default CopyrightInSidebar;
