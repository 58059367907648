import { User } from 'common/interfaces/api';
import styles from './SideBarUserItem.module.scss';
import RoundIcon from 'components/common/RoundIcon';
import Link from 'next/link';

const SideBarUserItem = ({
  user,
  isHiding = false,
}: {
  user: User;
  isHiding?: boolean;
}) => {
  if (!user) return <></>;
  const userProfileUrl = `/${user.username}`;
  return (
    <div
      className={styles.userItem}
      style={isHiding ? { display: 'none' } : {}}
    >
      <RoundIcon user={user} width={27} height={27} enableClick hasRing />
      <Link href={userProfileUrl}>
        <a>
          <div className={styles.nameText}>{user.name}</div>
        </a>
      </Link>
    </div>
  );
};

export default SideBarUserItem;
