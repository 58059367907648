import classNames from 'classnames';
import { useMediaQuery } from 'common/utils';
import CopyrightInSidebar from 'components/CopyrightInSidebar';
import GlobalHeader from 'components/GlobalHeader';
import styles from './MainTemplate.module.scss';
import MainRightSideBar from 'components/organisms/MainRightSideBar';
import useGlobalStore from 'store/global';
import { useQuery } from 'react-query';
import axios from 'axios';
import { GetUserSpotlightsResult, User } from 'common/interfaces/api';
import { createContext, useMemo } from 'react';
import TimelineNav from 'components/TimelineNav';

export type Props = {
  children: React.ReactChild | React.ReactChild[];
  hasHeader?: boolean;
  hasRightSidebar?: boolean;
};
type MainTemplateContextType = {
  isFetchedSpotlightUsers: boolean;
  spotlightUsers: User[];
};
export const MainTemplateContext = createContext<MainTemplateContextType>(null);
export default function MainTemplate({
  children,
  hasRightSidebar = true,
  hasHeader = true,
}: Props): JSX.Element {
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isSmallScreen = useMediaQuery('(max-width: 1100px)');

  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const { data: userSpotlightData, isFetched: isFetchedUserSpotlights } =
    useQuery(
      'getUserSpotlights',
      async () => {
        if (!isAuthInit || !hasRightSidebar) return Promise.reject();
        return (await axios.get('/feeds/creator-posts'))
          .data as GetUserSpotlightsResult;
      },
      {
        enabled: isAuthInit && hasRightSidebar,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        cacheTime: 15 * 60 * 1000,
      }
    );

  const spotlightUsers = useMemo(() => {
    return (userSpotlightData?.data?.creator_posts || []).map((spotlight) => {
      return spotlight?.user_info;
    });
  }, [userSpotlightData]);
  return (
    <MainTemplateContext.Provider
      value={{
        isFetchedSpotlightUsers: isFetchedUserSpotlights,
        spotlightUsers: spotlightUsers || [],
      }}
    >
      <div
        className={classNames(styles.mainTemplateWrapper, {
          [styles.notRightSidebar]: !hasRightSidebar,
        })}
      >
        {hasHeader && <GlobalHeader />}
        <div className={styles.pageContent}>
          {!isMobileScreen && (
            <div className={styles.leftColumn}>
              <TimelineNav />
              <CopyrightInSidebar />
            </div>
          )}
          <div className={styles.mainSection}>
            <div className={styles.content}>{children}</div>
          </div>
          {!isSmallScreen && hasRightSidebar && (
            <div className={styles.rightColumn}>
              <MainRightSideBar />
            </div>
          )}
        </div>
      </div>
    </MainTemplateContext.Provider>
  );
}
