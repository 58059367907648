import {
  Language,
  MediaType,
  Notification,
  NotificationType,
} from 'common/interfaces/api';
import { notificationEmotion, timeSince, truncateStr } from 'common/utils';
import { useMemo } from 'react';
import styles from './NotificationContent.module.scss';
import dayjs from 'lib/dayjs';
import Image from 'next/image';
import Icon from 'components/common/Icon';
import {
  ProfileViewAvatar,
  ProfileViewUsers,
} from '../ProfileViewNotification';
import { isSymbolAlarmNotification } from 'common/utils/membership';
import RoundIcon from 'components/common/RoundIcon';
import classNames from 'classnames';
import { checkIsCreatorPaidPost } from 'common/utils/post';

const MAX_TEXT_LENGHT = 80;
type NotificationContentProps = {
  notification: Notification;
  clicked: boolean;
};
export const NotificationContent = ({
  notification,
  clicked,
}: NotificationContentProps): JSX.Element => {
  const user = notification.user_info;
  const post = notification.post;
  const course = notification.course;
  const timeSinceText = timeSince(
    new Date(notification.created_at),
    Language.Ja
  );

  let headerText = '';
  let text = '';
  const time = dayjs(post?.published_at).format('MM月DD日 HH時mm分');
  switch (notification.type) {
    case NotificationType.Comment:
      headerText = `<strong>【新しいコメント】${user?.name}さん</strong>があなたの投稿にコメントしました。`;
      text = truncateStr(post?.text, MAX_TEXT_LENGHT);
      break;
    case NotificationType.Like:
      headerText = `<strong>【いいね】${user?.name}さん</strong>があなたの投稿にいいねしました。`;
      text = truncateStr(post?.text, MAX_TEXT_LENGHT);
      break;
    case NotificationType.Share:
      headerText = `<strong>【シェア】${user?.name}さん</strong>があなたの投稿をシェアしました。`;
      text = truncateStr(post?.text, MAX_TEXT_LENGHT);
      break;
    case NotificationType.Follow:
      headerText = `<strong>【フォロー】${user?.name}さん</strong>があなたをフォローしました。`;
      break;
    case NotificationType.Subscribe:
      headerText = `<strong>【プライム登録】${user?.name}さん</strong>があなたをプライム登録しました。`;
      break;
    case NotificationType.SubscribePlus:
      headerText = `<strong>【プライムPlus】${user?.name}さん</strong>があなたのプライムPlusを登録しました。`;
      break;
    case NotificationType.Reply:
      headerText = `<strong>【コメントに返信】${user?.name}さん</strong>があなたのコメントに返事しました。`;
      text = truncateStr(post?.text, MAX_TEXT_LENGHT);
      break;
    case NotificationType.Mention:
      headerText = `<strong>【メンション】${user?.name}さん</strong>がコメントであなたをメンションしました。`;
      text = truncateStr(post?.text, MAX_TEXT_LENGHT);
      break;
    case NotificationType.CreatePost:
      if (new Date(post?.published_at) <= new Date()) {
        text = truncateStr(post?.text, MAX_TEXT_LENGHT);
        headerText = `<strong>【新しい投稿】${user?.name}さん</strong>が新しい投稿を作成しました。`;
      } else {
        headerText += `<strong>【公開予定】${user?.name}さん</strong>の新しい投稿が${time}から公開予定です。`;
      }
      break;
    case NotificationType.ReSchedulePost:
      headerText = `<strong>【公開予定変更】${user?.name}さん</strong>`;
      if (new Date(post?.published_at) <= new Date()) {
        headerText += `の${time}に公開予定であった新しい投稿が、閲覧可能になりました。`;
      } else {
        headerText += `の公開予定であった新しい投稿は、${time}から閲覧できます。`;
      }
      break;
    case NotificationType.CancelSchedulePost:
      headerText = `<strong>【公開予定変更】${user?.name}さん</strong>の${time}に公開予定であった新しい投稿はキャンセルされました。`;
      break;
    case NotificationType.SendTip:
      headerText = `<strong>【ありがとう】${user?.name}さん</strong>があなたの投稿で「ありがとう」を購入しました。`;
      break;
    case NotificationType.ProfileView:
      headerText = `<strong>${notification.profile_view?.count}人があなたのプロフィールを閲覧しました。</strong>`;
      break;
    case NotificationType.TrendingPost:
      headerText = `<strong>【注目の投稿】${user?.name}さん</strong>`;
      text = truncateStr(post?.text, MAX_TEXT_LENGHT);
      break;
    case NotificationType.InvitationCodeReferred:
      headerText = `<strong>【新規登録】${
        user?.name
      }さん</strong>があなたの紹介で新規登録し、${notification.coin_amount?.toString()} PostPrime Coin獲得しました。`;
      break;
    case NotificationType.InvitationCodeNewUser:
      headerText = `<strong>【ようこそ！】${
        user?.name
      }さん</strong>の紹介で新規登録し、${notification.coin_amount?.toString()} PostPrime Coin獲得しました。`;
      break;
    case NotificationType.HostGoLive:
      headerText = `<strong>【LIVE】${user?.name}さん</strong>がLIVE配信を開始しました。`;
      break;
    // vote alert
    case NotificationType.PostDailyHighAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【関連投稿の増減率アラート 24時間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.PostDailyLowAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【関連投稿の増減率アラート 24時間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    case NotificationType.PostWeeklyLowAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【関連投稿の増減率アラート 7日間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    case NotificationType.PostWeeklyHighAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【関連投稿の増減率アラート 7日間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.PostMonthlyHighAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【関連投稿の増減率アラート 30日間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.PostMonthlyLowAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【関連投稿の増減率アラート 30日間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    case NotificationType.VoteDailyHighAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【投票率の通知 24時間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.VoteDailyLowAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【投票率の通知 24時間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    case NotificationType.VoteWeeklyHighAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【投票率の通知 7日間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.VoteWeeklyLowAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【投票率の通知 7日間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    case NotificationType.VoteMonthlyHighAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【投票率の通知 30日間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.VoteMonthlyLowAlert:
      if (!!notification.title && !!notification.body) {
        headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      } else
        headerText = `<strong>【投票率の通知 30日間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    // deleted
    case NotificationType.VoteChangeDailyHighAlert:
      headerText = `<strong>【投票数の増減率アラート 24時間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.VoteChangeDailyLowAlert:
      headerText = `<strong>【投票数の増減率アラート 24時間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    case NotificationType.VoteChangeWeeklyHighAlert:
      headerText = `<strong>【投票数の増減率アラート 7日間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    case NotificationType.VoteChangeWeeklyLowAlert:
      headerText = `<strong>【投票数の増減率アラート 7日間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.VoteChangeMonthlyHighAlert:
      headerText = `<strong>【投票数の増減率アラート 30日間】</strong>${notification?.symbol?.name}の上限を上回りました。`;
      break;
    case NotificationType.VoteChangeMonthlyLowAlert:
      headerText = `<strong>【投票数の増減率アラート 30日間】</strong>${notification?.symbol?.name}の下限を下回りました。`;
      break;
    case NotificationType.PredictionUserAlarm:
    case NotificationType.PredictionWeekGtMonth:
    case NotificationType.PredictionMonthGtWeek:
      headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      break;
    case NotificationType.PurchasedCourse:
      headerText = `<strong>${user?.name}さん</strong>があなたのコースを購入しました。`;
      break;
    case NotificationType.NoticeCampaignToUser:
      headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      break;
    case NotificationType.IzanaviBuy:
    case NotificationType.IzanaviSell:
      headerText = `<strong>${notification.title}</strong><br/>${notification.body}`;
      break;
    default:
      break;
  }

  const previewImage = useMemo(() => {
    if (!post && !course) return <></>;
    if (course && course.thumbnail_image_media?.url) {
      return (
        <div className={styles.mediaThumb}>
          <Image
            src={course.thumbnail_image_media?.url}
            layout="fill"
            objectFit="cover"
          />
        </div>
      );
    }
    if (new Date(post.published_at) > new Date()) return <></>;
    if (post.streaming && post.streaming?.thumbnail) {
      return (
        <div className={styles.mediaThumb}>
          <Image
            src={post.streaming?.thumbnail}
            layout="fill"
            objectFit="cover"
          />
          <Icon name="play" width={22} height={26} />
        </div>
      );
    }
    if (!post.media || post.media.length === 0) return <></>;
    const isOwnPost = post.is_my_post;
    const mediaFile = post.media[0];
    if (
      mediaFile.type === MediaType.Image &&
      checkIsCreatorPaidPost(post.type) &&
      mediaFile.data.blur &&
      !isOwnPost
    )
      return <></>;
    if (mediaFile.type === MediaType.Audio) {
      return (
        <div className={styles.mediaAudio}>
          {mediaFile.data.thumbnail_url && (
            <Image
              src={mediaFile.data.thumbnail_url}
              layout="fill"
              objectFit="cover"
            />
          )}
          <Icon name="audio-icon" width={22} height={26} />
        </div>
      );
    }
    if (mediaFile.type === MediaType.Image) {
      return (
        <div className={styles.mediaThumb}>
          <Image src={mediaFile.data.url} layout="fill" objectFit="cover" />
        </div>
      );
    }
    if (
      (mediaFile.type === MediaType.Video ||
        mediaFile.type === MediaType.Link) &&
      mediaFile.data.thumbnail_url
    ) {
      return (
        <div className={styles.mediaThumb}>
          <Image
            src={mediaFile.data.thumbnail_url}
            layout="fill"
            objectFit="cover"
          />
          {mediaFile.type === MediaType.Video && (
            <Icon name="play" width={22} height={26} />
          )}
        </div>
      );
    }
    return <></>;
  }, [post, course]);

  return (
    <div className={styles.notificationContent}>
      <div className={styles.leftSide}>
        <div className={styles.headerGroup}>
          <div
            className={styles.header}
            dangerouslySetInnerHTML={{ __html: headerText }}
          ></div>
        </div>
        <div className={styles.text}>{text}</div>
        {notification.type === NotificationType.ProfileView && (
          <ProfileViewUsers
            users={notification.profile_view?.profile_viewers}
          />
        )}
        <div className={styles.timeSinceWrapper}>
          {!notification.is_read && !clicked && (
            <div className={styles.nonReadFlag}></div>
          )}
          <div className={styles.timeSince}>{timeSinceText}</div>
        </div>
      </div>
      {previewImage}
    </div>
  );
};

export const NotificationRoundIcon = ({
  notification,
}: {
  notification: Notification;
}) => {
  if (
    [NotificationType.IzanaviBuy, NotificationType.IzanaviSell].includes(
      notification.type
    )
  ) {
    // Buy多い => red
    // Sell少ない => green
    return (
      <div className={styles.svgIcon}>
        <Image
          src={
            notification.type === NotificationType.IzanaviBuy
              ? '/images/izanavi/izanavi-big-notification-icon.png'
              : '/images/izanavi/izanavi-small-notification-icon.png'
          }
          width={56}
          height={56}
        />
      </div>
    );
  }
  if (notification.type === NotificationType.ProfileView) {
    return (
      <ProfileViewAvatar
        users={notification.profile_view?.profile_viewers}
        notificationType={notification.type}
      />
    );
  }
  if (isSymbolAlarmNotification(notification.type)) {
    return (
      <div className={styles.symbolAlertIcon}>
        <Image
          src={`/images/notifications/vote-notification-${
            notification.type.includes('high') ? 'up' : 'down'
          }.svg`}
          width={56}
          height={56}
        />
      </div>
    );
  }
  if (notification.type === NotificationType.PredictionWeekGtMonth) {
    return (
      <div className={styles.svgIcon}>
        <Icon name="month-gt-week" width={56} height={56} />
      </div>
    );
  }
  if (notification.type === NotificationType.PredictionMonthGtWeek) {
    return (
      <div className={styles.svgIcon}>
        <Icon name="week-gt-month" width={56} height={56} />
      </div>
    );
  }
  if (notification.type === NotificationType.NoticeCampaignToUser) {
    return (
      <div className={classNames(styles.svgIcon, styles.round)}>
        <Image src="/apple-touch-icon.png" width={56} height={56} />
      </div>
    );
  }
  return (
    <RoundIcon
      user={notification.user_info}
      enableClick
      width={56}
      height={56}
      hasRing
      emotion={notificationEmotion(notification.type)}
    />
  );
};
