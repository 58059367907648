import cn from 'classnames';
import axios from 'axios';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { useMemo, useState, useEffect, useRef } from 'react';
import useGlobalStore from 'store/global';
import useStore from 'store/timeline';
import {
  Notification,
  GetNotificationResult,
  NotificationType,
  PostState,
  MembershipPackageName,
} from 'common/interfaces/api';
import { YEAR_END_2024_EVENT, useOutsideClick } from 'common/utils';
import Icon from 'components/common/Icon';
import InfiniteScroll from 'components/common/InfiniteScroll';

import styles from './NotificationSection.module.scss';
import dayjs from 'lib/dayjs';
import { readAllNotifications } from 'service/notification';
import {
  NotificationContent,
  NotificationRoundIcon,
} from './NotificationContent';
import LabelButton from 'components/common/LabelButton';
import { isGoldMember } from 'common/utils/membership';
import { ScreenName } from 'common/utils/pp_tracking';
import { SymbolTabsName } from 'common/utils/chart';
import { track } from '@amplitude/analytics-browser';
import { getUserYearEndEvent } from 'common/utils/user';

enum FilterType {
  All = 'all',
  UnRead = 'unread',
  ChartAlert = 'chart_alert',
}
export default function NotificationSection({
  onClose,
  setNotificationAlert,
  isNotificationPage = false,
}: {
  onClose?: () => void;
  setNotificationAlert: (alert: string) => void;
  isNotificationPage?: boolean;
}): JSX.Element {
  const router = useRouter();
  const currentUser = useStore((state) => state.currentUser);
  const shouldRefetchNotifications = useStore(
    (state) => state.shouldRefetchNotifications
  );
  const [filter, setFilter] = useState<FilterType>(FilterType.All);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropDownRef = useRef();
  useOutsideClick(dropDownRef, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const showSymbolAlertSettingDialog = useGlobalStore(
    (state) => state.showSymbolAlertSettingDialog
  );
  const setHasNewNotification = useGlobalStore(
    (state) => state.setHasNewNotification
  );
  const showRemainingCoinDialog = useGlobalStore(
    (state) => state.showRemainingCoinDialog
  );
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const showDiscountCreatorsDialog = useGlobalStore(
    (state) => state.showDiscountCreatorsDialog
  );
  const [clickedNotificationIds, setClickedNotificationIds] = useState<
    number[]
  >([]);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const { isLoading, data, fetchNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery(
      ['getNotifcations', filter],
      async ({ pageParam = 0 }) =>
        (
          await axios.get(
            `/notifications/?filter=${filter}&last_id=${pageParam as number}`
          )
        ).data as GetNotificationResult,
      {
        enabled: isAuthInit && !!currentUser,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
          const notis = lastPage?.data;
          if (notis && notis.length > 0) {
            const lastNoti = notis[notis.length - 1];
            return lastNoti.id;
          }
        },
      }
    );
  const { mutate: markReadAllNotifications } = useMutation(
    async () => {
      return await readAllNotifications();
    },
    {
      onSuccess: () => {
        void refetch();
        setHasNewNotification(false);
      },
    }
  );

  useEffect(() => {
    if (shouldRefetchNotifications) {
      void refetch();
    }
  }, [refetch, shouldRefetchNotifications]);

  const pages = data?.pages;
  const notifications: Notification[] = useMemo(() => {
    const notiList = pages
      ? pages.reduce<Notification[]>((curr, page) => {
          const noties = page.data;
          return curr.concat(noties);
        }, [])
      : [];
    return notiList;
  }, [pages]);

  const notificationLink = (notification: Notification) => {
    switch (notification.type) {
      case NotificationType.Share:
        return `/${currentUser?.username}/posts/${notification.target_id}`;
      case NotificationType.Like:
        return `/${currentUser?.username}/posts/${notification.target_id}`;
      case NotificationType.Comment:
        return `/${notification.post?.user_info?.username}/posts/${notification.target_id}`;
      case NotificationType.Reply:
        return `/${notification.post?.user_info?.username}/posts/${notification.target_id}`;
      case NotificationType.Mention:
        return `/${notification.post?.user_info?.username}/posts/${notification.target_id}`;
      case NotificationType.Follow:
        return `/${notification.user_info?.username}`;
      case NotificationType.Subscribe:
        return `/${notification.user_info?.username}`;
      case NotificationType.SubscribePlus:
        return `/${notification.user_info?.username}`;

      case NotificationType.CreatePost:
      case NotificationType.ReSchedulePost:
        return `/${notification.user_info.username}/posts/${notification.target_id}`;
      case NotificationType.SendTip:
        return `/${currentUser?.username}/posts/${notification.target_id}`;
      case NotificationType.TrendingPost:
        return `/${notification.user_info.username}/posts/${notification.target_id}`;
      case NotificationType.HostGoLive:
        return `/${notification?.user_info?.username}/posts/${notification.target_id}`;
      case NotificationType.PostDailyHighAlert:
      case NotificationType.PostDailyLowAlert:
      case NotificationType.PostWeeklyLowAlert:
      case NotificationType.PostWeeklyHighAlert:
      case NotificationType.PostMonthlyHighAlert:
      case NotificationType.PostMonthlyLowAlert:
      case NotificationType.VoteChangeDailyHighAlert:
      case NotificationType.VoteChangeDailyLowAlert:
      case NotificationType.VoteChangeWeeklyHighAlert:
      case NotificationType.VoteChangeWeeklyLowAlert:
      case NotificationType.VoteChangeMonthlyHighAlert:
      case NotificationType.VoteChangeMonthlyLowAlert:
      case NotificationType.VoteDailyHighAlert:
      case NotificationType.VoteDailyLowAlert:
      case NotificationType.VoteWeeklyHighAlert:
      case NotificationType.VoteWeeklyLowAlert:
      case NotificationType.VoteMonthlyHighAlert:
      case NotificationType.VoteMonthlyLowAlert:
      case NotificationType.PredictionUserAlarm:
      case NotificationType.PredictionMonthGtWeek:
      case NotificationType.PredictionWeekGtMonth:
        return `/chart?id=${notification?.symbol?.id}&tab=chart`;
      case NotificationType.PurchasedCourse:
        return `/courses/${notification.course?.id}`;
      case NotificationType.IzanaviBuy:
      case NotificationType.IzanaviSell:
        return `/chart?id=${notification?.symbol?.id}&tab=chart&defaultTab=${SymbolTabsName.ScoringModel}`;

      default:
        return '';
    }
  };

  const handleReadNotification = async (notification: Notification) => {
    try {
      if (!clickedNotificationIds.includes(notification.id)) {
        const newIds = [...clickedNotificationIds];
        newIds.push(notification.id);
        setClickedNotificationIds(newIds);
        track('Click Notification', {
          notification_type: notification.type,
          ...(notification.promotion_type && {
            promotion_type: notification.promotion_type,
          }),
        });
      }
      if (notification.is_read) return;
      await axios.post(`/notifications/read`, {
        notification_id: notification.id,
      });
    } catch (e) {
      console.error('Error marking notifications as read!');
      console.error(e);
    }
  };

  const handleClickNotification = async (notification: Notification) => {
    if (!notification) return;
    switch (notification.type) {
      case NotificationType.CreatePost:
      case NotificationType.ReSchedulePost: {
        if (new Date(notification.post?.published_at) > new Date()) {
          setNotificationAlert(
            `${notification.user_info.name}さんの新しい投稿は${dayjs(
              notification.post?.published_at
            ).format('MM月DD日 HH時mm分')}から`
          );
        } else {
          if (notificationLink(notification)) {
            await router.push(notificationLink(notification));
          }
          onClose && onClose();
        }
        await handleReadNotification(notification);
        return;
      }
      case NotificationType.CancelSchedulePost: {
        await handleReadNotification(notification);
        return;
      }
      case NotificationType.ProfileView: {
        await handleReadNotification(notification);
        return;
      }
      case NotificationType.InvitationCodeNewUser:
      case NotificationType.InvitationCodeReferred: {
        showRemainingCoinDialog(true);
        await handleReadNotification(notification);
        onClose && onClose();
        break;
      }
      case NotificationType.NoticeCampaignToUser: {
        if (
          notification.promotion_type === YEAR_END_2024_EVENT &&
          getUserYearEndEvent(currentUser)
        ) {
          showDiscountCreatorsDialog(true);
        }
        await handleReadNotification(notification);
        break;
      }
      default: {
        await handleReadNotification(notification);
        if (notificationLink(notification)) {
          await router.push(notificationLink(notification));
        }
        onClose && onClose();
        return;
      }
    }
  };

  let emptyPlaceholder;
  if (!isLoading && notifications.length === 0) {
    emptyPlaceholder = (
      <div className={styles.empty}>
        <Icon name="empty" width={96} height={96} />
        <div>通知はありません</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.title}>
        <div className={styles.titleLeftSide}>
          <span className={styles.text}>通知</span>
        </div>
        <div className={styles.dropDownWrapper} ref={dropDownRef}>
          <div
            className={styles.arrowWrapper}
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          >
            <Icon name="dropdown" width={25} height={25} />
          </div>
          <div
            className={cn(styles.popperWrapper, {
              [styles.bigSize]: isGoldMember(currentUser),
            })}
            style={{ display: showDropdown ? 'flex' : 'none' }}
          >
            <a
              className={styles.option}
              onClick={() => {
                markReadAllNotifications();
                setShowDropdown(false);
              }}
            >
              <span className={styles.icon}>
                <Icon name="done-black" width={24} height={24} />
              </span>
              <span className={styles.text}>全て既読にする</span>
            </a>
            <a
              className={styles.option}
              onClick={() => {
                setShowDropdown(false);
                void router.push('/account/notifications_setting');
                onClose && onClose();
              }}
            >
              <span className={styles.icon}>
                <Icon name="settings" width={24} height={24} />
              </span>
              <span className={styles.text}>通知の設定</span>
            </a>
            {isGoldMember(currentUser) && (
              <>
                <a className={styles.option}>
                  <span className={styles.icon}>
                    <Icon name="settings" width={24} height={24} />
                  </span>
                  <span className={styles.text}>チャート通知の管理</span>
                </a>
                <a
                  className={cn(styles.option, styles.subOption)}
                  onClick={() => {
                    setShowDropdown(false);
                    showSymbolAlertSettingDialog({
                      open: true,
                      isGlobal: true,
                    });

                    onClose && onClose();
                  }}
                >
                  <span className={styles.icon}>
                    <Icon name="nav-chart" width={21} height={21} />
                  </span>
                  <span className={styles.text}>投票率チャートの通知</span>
                </a>
                <a
                  className={cn(styles.option, styles.subOption)}
                  onClick={() => {
                    setShowDropdown(false);
                    void router.push('/account/predictions_followers');
                    onClose && onClose();
                  }}
                >
                  <span className={styles.icon}>
                    <Icon name="add-noti" width={22} height={22} />
                  </span>
                  <span className={styles.text}>ユーザーの予測通知</span>
                </a>
                <a
                  className={cn(styles.option, styles.subOption)}
                  onClick={() => {
                    setShowDropdown(false);
                    showSymbolAlertSettingDialog({
                      open: true,
                      isGlobal: true,
                    });
                    onClose && onClose();
                  }}
                >
                  <span className={styles.icon}>
                    <Icon name="up2-1" width={26} height={26} />
                  </span>
                  <span className={styles.text}>中央値チャートの通知</span>
                </a>
              </>
            )}
            {!isNotificationPage && (
              <a
                className={styles.option}
                onClick={() => {
                  setShowDropdown(false);
                  void router.push('/notification');
                  onClose && onClose();
                }}
              >
                <span className={styles.icon}>
                  <Icon name="macbook-fill-black" width={24} height={24} />
                </span>
                <span className={styles.text}>通知ページを開く</span>
              </a>
            )}
          </div>
        </div>
      </div>
      <div className={styles.groupBtn}>
        <LabelButton
          text="すべて"
          type="gray"
          onClick={() => setFilter(FilterType.All)}
          className={cn(styles.filterBtn, {
            [styles.active]: filter === FilterType.All,
          })}
        />
        <LabelButton
          text="未読"
          type="gray"
          onClick={() => setFilter(FilterType.UnRead)}
          className={cn(styles.filterBtn, {
            [styles.active]: filter === FilterType.UnRead,
          })}
        />
        <LabelButton
          text="メンバーシップ通知"
          type="gray"
          onClick={() => {
            if (!isGoldMember(currentUser)) {
              setShowMembershipPayDialog({
                open: true,
                showMembershipStatus: false,
                initPackage: MembershipPackageName.Platinum,
                screen: ScreenName.Notification,
              });
              setSnackbarMessage({
                type: 'warning',
                text: 'Platinumメンバーシップに登録して特別な機能を体験しよう！',
                autoHideDuration: 10000,
              });
              return;
            }
            setFilter(FilterType.ChartAlert);
          }}
          className={cn(styles.filterBtn, {
            [styles.active]: filter === FilterType.ChartAlert,
          })}
        />
      </div>
      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        isLoading={isFetchingNextPage}
      >
        <div className={styles.section}>
          {emptyPlaceholder}
          {notifications.map((notification, index) => {
            if (!Object.values(NotificationType).includes(notification.type))
              return <div key={`notification-${filter}-${index}`} />;
            if (notification.post?.status === PostState.OnProcessing)
              return <div key={`notification-${filter}-${index}`} />;

            return (
              <div
                className={cn(styles.item, {
                  [styles.notReadItem]:
                    !notification.is_read &&
                    !clickedNotificationIds.includes(notification.id),
                  [styles.isProfileViewItem]:
                    notification.type === NotificationType.ProfileView,
                })}
                key={`notification-${filter}-${index}`}
              >
                <div className={styles.user}>
                  <NotificationRoundIcon notification={notification} />
                </div>
                <div
                  className={styles.post}
                  onClick={() => handleClickNotification(notification)}
                >
                  <NotificationContent
                    notification={notification}
                    clicked={clickedNotificationIds.includes(notification.id)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </>
  );
}
