import classNames from 'classnames';
import styles from './LabelButton.module.scss';

type LabelButtonProps = {
  text: string | JSX.Element;
  onClick?: () => void;
  type:
    | 'pink'
    | 'tip1'
    | 'tip2'
    | 'tip3'
    | 'tip4'
    | 'tip5'
    | 'gray'
    | 'white'
    | 'green'
    | 'green-1';
  className?: string;
};

const LabelButton = ({
  text,
  onClick,
  type,
  className,
}: LabelButtonProps): JSX.Element => {
  return (
    <span
      className={classNames(styles.labelButton, styles[type], className)}
      onClick={onClick ? onClick : undefined}
    >
      {text}
    </span>
  );
};

export default LabelButton;
