import { Badge } from 'common/interfaces/api';
import Image from 'next/image';

import styles from './BadgeIcon.module.scss';

export type Props = {
  badge: Badge | 'advertiser';
  width: number;
  height: number;
};

export default function BadgeIcon({
  badge,
  width,
  height,
}: Props): JSX.Element {
  return (
    <div className={styles.badgeIconWrapper}>
      {badge && (
        <Image
          src={`/images/icons/badge-${badge}.svg`}
          width={width}
          height={height}
          alt="badge"
        />
      )}
    </div>
  );
}
