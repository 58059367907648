import {
  AlarmDetailsType,
  AlarmType,
  CharacterStageId,
  Mood,
  RedirectScreen,
} from 'service/anime';
import { SymbolChart } from 'common/interfaces/api';
import { assetsUrl, getRandomElementInArray, sortString } from '.';
import {
  addEvent,
  EventCategory,
  EventComponent,
  ScreenName,
  EventAction,
} from './pp_tracking';
import { SITE_BASE_URL } from 'config/env';
import { SymbolTabsName } from './chart';

export enum RepeatValue {
  Sunday = '1',
  Monday = '2',
  Tuesday = '3',
  Wednesday = '4',
  Thursday = '5',
  Friday = '6',
  Saturday = '7',
}
export const REPEAT_VALUES = [
  { label: '月', value: RepeatValue.Monday },
  { label: '火', value: RepeatValue.Tuesday },
  { label: '水', value: RepeatValue.Wednesday },
  { label: '木', value: RepeatValue.Thursday },
  { label: '金', value: RepeatValue.Friday },
  { label: '土', value: RepeatValue.Saturday },
  { label: '日', value: RepeatValue.Sunday },
];

// value = "1234567"
export const alarmDateText = (value: string) => {
  value = sortString(value || '', 'asc');
  switch (value) {
    case '23456': {
      return '平日';
    }
    case '17': {
      return '週末';
    }
    case '1234567': {
      return '毎日';
    }
    case '1': {
      return '毎週日曜日';
    }
    case '2': {
      return '毎週月曜日';
    }
    case '3': {
      return '毎週火曜日';
    }
    case '4': {
      return '毎週水曜日';
    }
    case '5': {
      return '毎週木曜日';
    }
    case '6': {
      return '毎週金曜日';
    }
    case '7': {
      return '毎週土曜日';
    }
    default: {
      if (!value) return '';
      const values = value.split('');
      return values
        .map((v) => REPEAT_VALUES.find((r) => r.value === v)?.label)
        .join(' ');
    }
  }
};

export const stageName = (stageId: CharacterStageId) => {
  switch (stageId) {
    case CharacterStageId.Egg:
      return '';
    case CharacterStageId.Baby:
      return '_baby';
    case CharacterStageId.Teen:
      return '_teenager';
    case CharacterStageId.Adult:
      return '_adult';
    default:
      return '';
  }
};

export const characterName = (name: string, stageId: CharacterStageId) => {
  const nameByStage =
    stageId === CharacterStageId.Egg ? 'egg' : name?.toLocaleLowerCase();
  return `${nameByStage}${stageName(stageId)}`;
};

export const characterAvatarUrl = (name: string, stageId: CharacterStageId) => {
  const currentName = characterName(name, stageId);
  return assetsUrl(`anime_character/${currentName}.png`);
};

// return next time alarm will ring
export const nextAlarmTime = (alarm: AlarmType): Date => {
  if (!alarm) return null;
  const { loops, time } = alarm;
  if (!time) return null;
  const alarmTime = new Date(time);
  const todayAlarmTime = new Date().setHours(
    alarmTime.getHours(),
    alarmTime.getMinutes(),
    0,
    0
  );
  const now = new Date();
  const nowTime = now.getTime() - 5 * 1000;
  if (!loops) {
    if (todayAlarmTime >= nowTime) {
      return new Date(todayAlarmTime);
    }
    return null;
  }
  const currentDay = now.getDay() + 1; // from 1 to 7
  const loopsValues = Array.from(loops || '').map((v) => Number(v));
  if (loopsValues.includes(currentDay) && todayAlarmTime >= nowTime) {
    return new Date(todayAlarmTime);
  }
  const nextDay = currentDay === 7 ? 0 : currentDay + 1;
  if (loopsValues.includes(nextDay)) {
    const nextDayAlarmTime = todayAlarmTime + 24 * 60 * 60 * 1000;
    return new Date(nextDayAlarmTime);
  }
  return null;
};

export interface NextAlarm {
  id: number;
  nextAlarmTime: Date;
  symbolName: string;
}

export interface ShowingAlarmType extends NextAlarm {
  data: AlarmDetailsType;
}

export const nextAlarm = (alarms: AlarmType[]): NextAlarm | null => {
  const nextAlarms: NextAlarm[] = alarms
    .filter((alarm) => {
      return alarm.active;
    })
    .map((alarm) => {
      return {
        id: alarm.id,
        nextAlarmTime: nextAlarmTime(alarm),
        symbolName:
          alarm?.symbol?.trading_economics_symbol_name ||
          alarm.symbol?.name ||
          '',
      };
    })
    .filter((nextAl) => !!nextAl.nextAlarmTime);
  if (nextAlarms.length === 0) return null;
  if (nextAlarms.length === 1) return nextAlarms[0];
  return nextAlarms.reduce((prev, curr) => {
    return prev.nextAlarmTime < curr.nextAlarmTime ? prev : curr;
  }, nextAlarms[0]);
};

export const expressName = (index: number) => {
  switch (index) {
    case 0:
      return 'A';
    case 1:
      return 'I';
    case 2:
      return 'U';
    case 3:
      return 'E';
    case 4:
      return 'O';
    case 5:
      return 'N';
    default:
      return 5;
  }
};

export const addAnimeTrackingEvent = ({
  action,
  component,
  position,
}: {
  action: EventAction;
  component: EventComponent;
  position?: string;
}) => {
  addEvent({
    action: action,
    category: EventCategory.AnimeCharacter,
    screen: ScreenName.HomeTimeline,
    component: component,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: action === EventAction.Click ? position : 'view',
        label: action === EventAction.Click ? 'click_position' : 'view',
      },
    ],
  });
};

export const characterNames = [
  'bear',
  'boy',
  'bull',
  'cat',
  'dinosaur',
  'girl',
  'lion',
  'parrot',
];

export const EGG_RATIO = 77 / 83;
export const PIXI_CONTAINER_WIDTH = 330;
export const IMAGE_CONTAINER_WIDTH = 240;
export const CHARACTER_SIZES: {
  [name: string]: { ratio: number; paddingBottom: number };
} = {
  egg: {
    ratio: 1 / 1.05,
    paddingBottom: 99,
  },
  bear_baby: {
    ratio: 1 / 1.1,
    paddingBottom: 77,
  },
  bear_teenager: {
    ratio: 1,
    paddingBottom: 72,
  },
  bear_adult: {
    ratio: 1,
    paddingBottom: 70,
  },
  boy_baby: {
    ratio: 1 / 1.1,
    paddingBottom: 44,
  },
  boy_teenager: {
    ratio: 1 / 1.1,
    paddingBottom: 50,
  },
  boy_adult: {
    ratio: 1 / 0.9,
    paddingBottom: 50,
  },
  bull_baby: {
    ratio: 1,
    paddingBottom: 90,
  },
  bull_teenager: {
    ratio: 1 / 1.05,
    paddingBottom: 77,
  },
  bull_adult: {
    ratio: 1 / 1,
    paddingBottom: 50,
  },
  cat_baby: {
    ratio: 1 / 1.1,
    paddingBottom: 77,
  },
  cat_teenager: {
    ratio: 1 / 1,
    paddingBottom: 33,
  },
  cat_adult: {
    ratio: 1 / 0.95,
    paddingBottom: 20,
  },
  dinosaur_baby: {
    ratio: 1,
    paddingBottom: 88,
  },
  dinosaur_teenager: {
    ratio: 1 / 1.1,
    paddingBottom: 88,
  },
  dinosaur_adult: {
    ratio: 1 / 1.05,
    paddingBottom: 64,
  },
  girl_baby: {
    ratio: 1 / 1.05,
    paddingBottom: 55,
  },
  girl_teenager: {
    ratio: 1 / 0.95,
    paddingBottom: 38,
  },
  girl_adult: {
    ratio: 1 / 1,
    paddingBottom: 38,
  },
  lion_baby: {
    ratio: 1,
    paddingBottom: 77,
  },
  lion_teenager: {
    ratio: 1 / 1.05,
    paddingBottom: 45,
  },
  lion_adult: {
    ratio: 1 / 0.95,
    paddingBottom: 22,
  },
  parrot_baby: {
    ratio: 1 / 1,
    paddingBottom: 50,
  },
  parrot_teenager: {
    ratio: 1 / 1.05,
    paddingBottom: 50,
  },
  parrot_adult: {
    ratio: 1 / 0.95,
    paddingBottom: 35,
  },
};

const MOTION_NAMES = ['Clap', 'Point', 'Mouthcover', 'Wave', 'Wave2hand'];
export const getRandomMotionName = () => {
  const index = Math.floor(Math.random() * 5);
  return MOTION_NAMES[index] || 'Point';
};

export const genAnimeChatRedirectUrl = ({
  redirectScreen,
  symbol,
}: {
  redirectScreen: RedirectScreen;
  symbol: SymbolChart;
}) => {
  switch (redirectScreen) {
    case RedirectScreen.MarketPage:
      return `${SITE_BASE_URL}/chart?tab=chart`;
    case RedirectScreen.SymbolDetailPage:
      if (!symbol) return '';
      return `${SITE_BASE_URL}/chart?id=${symbol.id}&tab=chart`;
    case RedirectScreen.ScoreLevelPage:
      if (!symbol) return '';
      return `${SITE_BASE_URL}/chart?id=${symbol.id}&tab=chart&defaultTab=${SymbolTabsName.ScoringModel}`;
    case RedirectScreen.VoteRankingPage:
      return `${SITE_BASE_URL}/chart?tab=ranking`;
    default:
      return '';
  }
};

export const motionByMood = (mood: Mood) => {
  switch (mood) {
    case Mood.Excited:
      return 'Excited';
    case Mood.Happy:
      return getRandomElementInArray(['Clap', 'Wave2hand']);
    case Mood.Chill:
      return 'Mouthcover';
    case Mood.Sad:
      return 'Sad';
    case Mood.Sleepy:
      return 'Sleepy';
    case Mood.Normal:
      return 'Normal';

    default:
      return '';
  }
};
