import useGlobalStore from 'store/global';
import Icon from 'components/common/Icon';
import styles from './MobileBottomBar.module.scss';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import useStore from 'store/timeline';
import { track } from '@amplitude/analytics-browser';

const MobileBottomBar = () => {
  const showLogin = useGlobalStore((state) => state.showLogin);
  const showCreatePostDialogMobile = useGlobalStore(
    (state) => state.showCreatePostDialogMobile
  );
  const currentUser = useStore((state) => state.currentUser);
  const router = useRouter();
  const { pathname, asPath } = router;
  const isHome = pathname === '/';
  const isChart = pathname === '/chart';
  const isTrendingTopicsPage = pathname === '/trending_topics';
  const isMyProfile = asPath?.split('?')?.[0] === `/${currentUser?.username}`;
  return (
    <div className={styles.mobileBottomBar}>
      <div
        className={classNames(styles.item, { [styles.active]: isHome })}
        onClick={() => {
          if (!isHome) {
            track('Click Home');
          }
          void router.push('/');
        }}
      >
        <div className={styles.icon}>
          <Icon name="mobile-bottom-bar-home" width={25} height={23} />
        </div>
        <div className={styles.title}>ホーム</div>
      </div>
      <div
        className={classNames(styles.item, { [styles.active]: isChart })}
        onClick={() => {
          if (!isChart) {
            track('Click Chart Button');
          }
          if (!currentUser) {
            showLogin({});
            return;
          }
          void router.push('/chart');
        }}
      >
        <div className={styles.icon}>
          <Icon name="mobile-bottom-bar-chart" width={28} height={28} />
        </div>
        <div className={styles.title}>マーケット情報</div>
      </div>
      <div
        className={classNames(styles.item, styles.middle)}
        onClick={() => {
          track('Start Create Post');
          if (!currentUser) {
            showLogin({});
            return;
          }
          showCreatePostDialogMobile({ open: true });
        }}
      >
        <div className={styles.roundIcon}>
          <Icon name="add" height={25} width={25} />
        </div>
      </div>
      <div
        className={classNames(styles.item, {
          [styles.active]: isTrendingTopicsPage,
        })}
        onClick={() => {
          if (!isTrendingTopicsPage) {
            track('Click Trend Button');
          }
          void router.push('/trending_topics');
        }}
      >
        <div className={styles.icon}>
          <Icon name="nav-trending" width={28} height={28} />
        </div>
        <div className={styles.title}>今の話題</div>
      </div>
      <div
        className={classNames(styles.item, { [styles.active]: isMyProfile })}
        onClick={() => {
          if (currentUser && !isMyProfile) {
            track('Click Profile Button');
          }
          if (!currentUser) {
            showLogin({});
            return;
          }
          void router.push(`/${currentUser?.username}`);
        }}
      >
        <div className={styles.icon}>
          <Icon name="mobile-bottom-bar-profile" width={22} height={25} />
        </div>
        <div className={styles.title}>プロフィール</div>
      </div>
    </div>
  );
};

export default MobileBottomBar;
