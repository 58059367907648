import create, { SetState, GetState } from 'zustand';

type PostReactionStore = {
  likedPostsInfo: {
    [key: string]: {
      isLiked: boolean;
      expiredAt: number;
    };
  };
  updateLikeInfoOfPost: (postId: number, isLiked: boolean) => void;
  sharePostsInfo: {
    [key: string]: {
      isShared: boolean;
      expiredAt: number;
    };
  };
  addShareInfoOfPost: (postId: number) => void;
  deleteExpiredReactionInfo: () => void;
};

const usePostReactionStore = create<PostReactionStore>(
  (set: SetState<PostReactionStore>, get: GetState<PostReactionStore>) => ({
    likedPostsInfo: {},
    updateLikeInfoOfPost: (postId: number, isLiked: boolean) => {
      const { likedPostsInfo } = get();
      likedPostsInfo[postId.toString()] = {
        isLiked,
        expiredAt: Date.now() + 1000 * 60 * 15,
      };
      set({ likedPostsInfo });
    },
    sharePostsInfo: {},
    addShareInfoOfPost: (postId: number) => {
      const { sharePostsInfo } = get();
      sharePostsInfo[postId.toString()] = {
        isShared: true,
        expiredAt: Date.now() + 1000 * 60 * 15,
      };
      set({ sharePostsInfo });
    },
    deleteExpiredReactionInfo: () => {
      const { likedPostsInfo, sharePostsInfo } = get();
      const currentTime = Date.now();
      for (const key in likedPostsInfo) {
        if (likedPostsInfo[key].expiredAt < currentTime) {
          delete likedPostsInfo[key];
        }
      }
      for (const key in sharePostsInfo) {
        if (sharePostsInfo[key].expiredAt < currentTime) {
          delete sharePostsInfo[key];
        }
      }
      set({ likedPostsInfo, sharePostsInfo });
    },
  })
);

export default usePostReactionStore;
