import { InvestmentLevel } from 'service/investment_level';

export const ALL_LV_ID = 'all';
export const DEFAULT_INVESTMENT_LEVELS: InvestmentLevel[] = [
  {
    id: 1,
    name: '初心者',
    description: '',
  },
  {
    id: 2,
    name: '中級者',
    description: '',
  },
  {
    id: 3,
    name: '上級者',
    description: '',
  },
];
