import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import { track } from '@amplitude/analytics-browser';
import Icon, { ExpandIcon } from 'components/common/Icon';
import axios from 'axios';
import { GetSubscribersResult } from 'common/interfaces/api';
import useGlobalStore from 'store/global';
import { HELP_CENTER_LINK, useMediaQuery } from 'common/utils';

import styles from './TimelineNav.module.scss';
import { useMemo } from 'react';
import { UserInfosLoader } from 'components/common/PreloadContentLoader';
import SideBarUserItem from 'components/molecules/SideBarUserItem';
import { getFeatureCreators } from 'service/user';

export default function TimelineNav({
  delayShowUserFeed = false,
  isPostDetail = false,
}: {
  delayShowUserFeed?: boolean;
  isPostDetail?: boolean;
}): JSX.Element {
  const router = useRouter();
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isSmallScreen = useMediaQuery('(max-width: 1346px)');
  const showLogin = useGlobalStore((state) => state.showLogin);
  const [isShowingFeedSection, setIsShowingFeedSection] = useState(false);

  useEffect(() => {
    if (isShowingFeedSection) return;
    let timeout;
    if (delayShowUserFeed) {
      timeout = setTimeout(() => {
        setIsShowingFeedSection(true);
      }, 15000);
    } else {
      setIsShowingFeedSection(true);
    }
    return () => timeout && clearTimeout(timeout);
  }, [delayShowUserFeed, isShowingFeedSection]);
  const pathWithoutQuery = router.asPath?.split('?')[0];

  const feedSection = useMemo(() => {
    if (!isPostDetail) return <UserFeedSection />;
    return isShowingFeedSection ? <UserFeedSection /> : <></>;
  }, [isPostDetail, isShowingFeedSection]);

  if (isMobileScreen) return <></>;
  return (
    <nav className={styles.timelineNavWrapper} aria-label="ページ一覧">
      <Link href="/">
        <a
          className={styles.navItemWrapper}
          onClick={() => {
            if (pathWithoutQuery !== '/') {
              track('Click Home');
            }
          }}
        >
          <div className={styles.navItem}>
            <div
              className={cn(styles.headerWrapper, {
                [styles.active]: pathWithoutQuery === '/',
              })}
            >
              <Icon name="home-nav" width={24} height={24} />
              <span className={styles.headerText}>ホーム</span>
            </div>
          </div>
        </a>
      </Link>
      <a
        onClick={() => {
          if (pathWithoutQuery !== '/chart') {
            track('Click Chart Button');
          }
          if (!currentAuth) {
            showLogin({});
            return;
          }
          void router.push(`/chart`);
        }}
        className={styles.navItemWrapper}
      >
        <div className={styles.navItem}>
          <div
            className={cn(styles.headerWrapper, {
              [styles.active]: pathWithoutQuery === `/chart`,
            })}
          >
            <Icon name="nav-chart" width={24} height={24} />
            <span className={styles.headerText}>
              {isSmallScreen ? 'マーケット' : 'マーケット情報'}
            </span>
          </div>
        </div>
      </a>
      <Link href="/courses">
        <a
          onClick={() => {
            if (pathWithoutQuery !== '/courses') {
              track('Click Course Button');
            }
          }}
          className={styles.navItemWrapper}
        >
          <div className={styles.navItem}>
            <div
              className={cn(styles.headerWrapper, {
                [styles.active]: pathWithoutQuery === `/courses`,
              })}
            >
              <Icon name="course-nav" width={24} height={24} />
              <span className={styles.headerText}>コース</span>
            </div>
          </div>
        </a>
      </Link>
      <Link href="/creators/rankings">
        <a
          onClick={() => {
            if (pathWithoutQuery !== '/creators/rankings') {
              track('Click Ranking Button');
            }
          }}
          className={styles.navItemWrapper}
        >
          <div className={styles.navItem}>
            <div
              className={cn(styles.headerWrapper, {
                [styles.active]: pathWithoutQuery === `/creators/rankings`,
              })}
            >
              <Icon name="ranking" width={24} height={24} />
              <span className={styles.headerText}>ランキング</span>
            </div>
          </div>
        </a>
      </Link>
      <a
        onClick={() => {
          if (pathWithoutQuery !== '/dashboard') {
            track('Click Dashboard Button');
          }
          if (!currentAuth) {
            showLogin({});
            return;
          }
          void router.push(`/dashboard`);
        }}
        className={styles.navItemWrapper}
      >
        <div className={styles.navItem}>
          <div
            className={cn(styles.headerWrapper, {
              [styles.active]: pathWithoutQuery === `/dashboard`,
            })}
          >
            <Icon name="dashboard" width={24} height={24} />
            <span className={styles.headerText}>ダッシュボード</span>
          </div>
        </div>
      </a>
      {isAuthInit && currentAuth && feedSection}
      {isAuthInit && <FeatureCreatorsSection />}
      <a
        href={HELP_CENTER_LINK}
        target="_blank"
        rel="noopener nofollow"
        className={styles.navItemWrapper}
        onClick={() => {
          track('Click Help Button');
        }}
      >
        <div className={styles.navItem}>
          <div className={styles.headerWrapper}>
            <Icon name="nav-help" width={24} height={24} />
            <span className={styles.headerText}>ヘルプセンター</span>
          </div>
        </div>
      </a>
    </nav>
  );
}

const UserFeedSection = () => {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const [isLimit, setIsLimit] = useState(true);
  const router = useRouter();
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const showLogin = useGlobalStore((state) => state.showLogin);

  const { data: subscribersData, isFetched: isFetched3 } = useQuery(
    ['getSubscribers'],
    async () =>
      (await axios.get('/users/settings/subscribed_users'))
        .data as GetSubscribersResult,
    {
      enabled: isAuthInit && !!currentAuth,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );
  const subscribers = subscribersData?.data?.users || [];

  if (!currentAuth) return <></>;
  if (!isFetched3) return <UserInfosLoader />;
  const showingUsers = isLimit ? subscribers.slice(0, 5) : subscribers;
  return (
    <div className={styles.navItem}>
      <a
        onClick={() => {
          track('Click Subscribed Channels Button');
          if (!currentAuth) {
            showLogin({});
            return;
          }
          void router.push(`/account/paid_users`);
        }}
        className={styles.navItemWrapper}
      >
        <div className={styles.headerWrapper}>
          <Icon name="users-nav-paid-new" width={24} height={24} />
          <div className={styles.headerText}>プライム登録</div>
        </div>
      </a>
      <div className={styles.children}>
        {subscribers.length === 0 ? (
          <span className={styles.emptyText}>
            登録中のプライムクリエイターはいません
          </span>
        ) : (
          <>
            {showingUsers.map((subscriber, index) => {
              return (
                <SideBarUserItem
                  key={`subscriber-${subscriber.id}-${index}`}
                  user={subscriber}
                />
              );
            })}
            {subscribers.length > 5 &&
              (isLimit ? (
                <div
                  className={styles.seeMore}
                  onClick={() => setIsLimit(false)}
                >
                  もっと見る
                </div>
              ) : (
                <Link href={`/account/paid_users`}>
                  <a className={styles.seeMore}>全て見る</a>
                </Link>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

const FeatureCreatorsSection = () => {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const [isChannelsDisplayLimit, setIsChannelsDisplayLimit] = useState(true);
  const [isCreatorsDisplayLimit, setIsCreatorsDisplayLimit] = useState(true);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const [isExpandedChannels, setIsExpandedChannels] = useState(
    currentAuth ? false : true
  );
  const [isExpandedCreators, setIsExpandedCreators] = useState(
    currentAuth ? false : true
  );
  const { data: featuredUsersData, isFetched: isFetched1 } = useQuery(
    ['getFeaturedUsers'],
    async () => {
      if (!isAuthInit) return Promise.reject();
      return await getFeatureCreators();
    },
    {
      enabled: isAuthInit,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  const channels = featuredUsersData?.data?.channels || [];
  const creators = featuredUsersData?.data?.creators || [];
  if (!isFetched1) return <UserInfosLoader />;
  const showingChannels = isChannelsDisplayLimit
    ? channels.slice(0, 5)
    : channels;
  const showingCreators = isCreatorsDisplayLimit
    ? creators.slice(0, 5)
    : creators;
  return (
    <>
      {channels.length > 0 && (
        <div className={styles.navItem}>
          <div className={styles.navItemWrapper}>
            <div
              className={styles.headerWrapper}
              onClick={() => {
                setIsExpandedChannels(!isExpandedChannels);
              }}
            >
              <Icon name="black-official-lion" width={24} height={24} />
              <div className={styles.headerText}>公式チャンネル</div>
              <div className={styles.expandIcon}>
                <ExpandIcon
                  expand={isExpandedChannels}
                  width={20}
                  height={20}
                />
              </div>
            </div>
          </div>
          <div
            className={styles.children}
            style={!isExpandedChannels ? { display: 'none' } : {}}
          >
            {showingChannels.map((channel, index) => {
              return (
                <SideBarUserItem
                  key={`channel-${channel.id}-${index}`}
                  user={channel}
                />
              );
            })}
            {channels.length > 5 && (
              <div
                className={styles.seeMore}
                onClick={() => setIsChannelsDisplayLimit((current) => !current)}
              >
                {isChannelsDisplayLimit ? 'もっと見る' : '一部表示'}
              </div>
            )}
          </div>
        </div>
      )}
      {!currentAuth && creators.length > 0 && (
        <div className={styles.navItem}>
          <div className={styles.navItemWrapper}>
            <div
              className={styles.headerWrapper}
              onClick={() => {
                setIsExpandedCreators(!isExpandedCreators);
              }}
            >
              <Icon name="users-nav" width={24} height={24} />
              <div className={styles.headerText}>注目クリエイター</div>
              <div className={styles.expandIcon}>
                <ExpandIcon
                  expand={isExpandedCreators}
                  width={20}
                  height={20}
                />
              </div>
            </div>
          </div>
          <div
            className={styles.children}
            style={!isExpandedCreators ? { display: 'none' } : {}}
          >
            {showingCreators.map((creator, index) => {
              return (
                <SideBarUserItem
                  key={`creator-${creator.id}-${index}`}
                  user={creator}
                />
              );
            })}
            {creators.length > 5 && (
              <div
                className={styles.seeMore}
                onClick={() => setIsCreatorsDisplayLimit((current) => !current)}
              >
                {isCreatorsDisplayLimit ? 'もっと見る' : '一部表示'}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
